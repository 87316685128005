import {
  Button,
  Checkbox, Classes,
  Dialog, FormGroup,
  HTMLTable,
  ProgressBar, RadioGroup,
} from "@blueprintjs/core"
import { SelectOption, SelectSimple } from "components/form/Select"
import { CardTable } from "components/layout/CardTable"
import { PageHeader } from "components/layout/PageHeader"
import { Field, FieldProps, Formik, FormikHelpers } from "formik"
import {
  EMPLOYEE_ROLE_CONTRACTOR,
  postHouseMetersPermissions, postIflatId,
  useGetComplexes,
  useGetComplexHouses,
  useGetHouseMetersPermissions,
} from "lib/api/data"
import { MetersPermissions } from "lib/api/types/ApiMetersPermissions"
import * as React from "react"
import { mutate } from "swr"
import {clearPhone} from "../../helpers/common";
import {InputPhoneField} from "../../components/form/InputPhone";
import {CheckPhoneRole} from "../../components/form/CheckPhoneRole";
import {InputTextField} from "../../components/form/Input";
import {ComplexHouse} from "../../lib/api/types/ApiComplexHousesResponse";

export const Houses: React.FC = () => {
  const [activeComplex, selectComplex] = React.useState<SelectOption>()
  const [loading, complexes] = useGetComplexes()

  return (
    <div className="container">
      <PageHeader>Просмотр ЖК</PageHeader>

      <div className="grid grid--col-12">
        <div className="col-4">
          <SelectSimple
            activeItem={activeComplex}
            activeLabel={
              activeComplex?.title ? `ЖК ${activeComplex?.title}` : undefined
            }
            defaultLabel="Выберите ЖК"
            items={complexes}
            loading={loading}
            onItemSelect={selectComplex}
          />
        </div>
      </div>
      <br />
      {activeComplex && <ComplexHouses complex={activeComplex.id} />}
    </div>
  )
}

const ComplexHouses: React.FC<{ complex: number }> = ({ complex }) => {
  const [showMetersPermissions, setShowMetersPermissions] = React.useState<
    false | { id: number; address: string }
  >(false)
  const [showEditIflatId, setEditIflatId] = React.useState<
      false | { house: ComplexHouse }
  >(false)

  const [loading, houses, refetch] = useGetComplexHouses(complex)

  if (loading) {
    return <ProgressBar />
  }

  return (
    <React.Fragment>
      <CardTable data-testid={"domTableComplexes"}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Дом</th>
            <th>Подъезды</th>
            <th>ID iflat</th>
            <th style={{ width: "40px", padding: "8px" }} />
          </tr>
        </thead>
        <tbody className="valign-middle">
          {houses?.map((v) => (
            <tr key={v.id}>
              <td>{v.address}</td>
              <td>
                {v.sections.map((s) => (
                  <p key={s.summary}>{s.summary}</p>
                ))}
              </td>
              <td>
                {v.i_flat_id}
                <Button
                    icon="edit"
                    minimal={true}
                    small={true}
                    type="button"
                    onClick={() =>
                        setEditIflatId({ house: v })
                    }
                />
              </td>
              <td>
                <Button
                  icon="dashboard"
                  minimal={true}
                  small={true}
                  type="button"
                  onClick={() =>
                    setShowMetersPermissions({ id: v.id, address: v.address })
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </CardTable>

      {showMetersPermissions !== false && (
        <Dialog
          isOpen={true}
          title={`Адрес: ${showMetersPermissions.address}`}
          onClose={() => setShowMetersPermissions(false)}
        >
          <MetersPersmissionsForm
            house={showMetersPermissions.id}
            onComplete={() => setShowMetersPermissions(false)}
          />
        </Dialog>
      )}
      {showEditIflatId !== false && (
          <Dialog
              isOpen={true}
              title={`Редактирование ID iflat`}
              onClose={() => setEditIflatId(false)}
          >
            <IflatIdEditForm
                house={showEditIflatId.house}
                onComplete={() => {
                  setEditIflatId(false)
                  refetch()
                }}
            />
          </Dialog>
      )}
    </React.Fragment>
  )
}

type MetersForm = {
  cold_water_telemetry: boolean
  hot_water_telemetry: boolean
  electricity_telemetry: boolean
  heating_telemetry: boolean
  heating_summer_period: boolean
}
const MetersPersmissionsForm: React.VFC<{
  house: number
  onComplete: () => void
}> = ({ house, onComplete }) => {
  const [loading, meters] = useGetHouseMetersPermissions(house)
  console.debug({ meters })

  if (loading) {
    return <ProgressBar />
  }

  const initialValues: MetersForm = {
    cold_water_telemetry:
      meters?.find((m) => m.type === "cold_water")?.telemetry ?? false,
    hot_water_telemetry:
      meters?.find((m) => m.type === "hot_water")?.telemetry ?? false,
    electricity_telemetry:
      meters?.find((m) => m.type === "electricity")?.telemetry ?? false,
    heating_telemetry:
      meters?.find((m) => m.type === "heating")?.telemetry ?? false,
    heating_summer_period:
      meters?.find((m) => m.type === "heating")?.summer_period ?? false,
  }

  const onFormikSubmit = async (
    values: MetersForm,
    helpers: FormikHelpers<MetersForm>,
  ): Promise<void> => {
    helpers.setStatus("")
    const data: MetersPermissions = [
      {
        type: "cold_water",
        telemetry: values.cold_water_telemetry,
        summer_period: false,
      },
      {
        type: "hot_water",
        telemetry: values.hot_water_telemetry,
        summer_period: false,
      },
      {
        type: "electricity",
        telemetry: values.electricity_telemetry,
        summer_period: false,
      },
      {
        type: "heating",
        telemetry: values.heating_telemetry,
        summer_period: values.heating_summer_period,
      },
    ]

    try {
      await postHouseMetersPermissions(house, data)
      mutate(`/houses/${house}/meters_permissions`)
      onComplete()
    } catch {
      helpers.setStatus("Неизвестная ошибка")
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Formik<MetersForm> initialValues={initialValues} onSubmit={onFormikSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <HTMLTable bordered={true}>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th style={{ width: "180px" }}>
                  <small>
                    Отметьте "галочками"
                    <br />
                    счётчики, показания
                    <br />
                    по которым передаются
                    <br />
                    через телеметрию
                    <br />
                    или сотрудниками УК
                  </small>
                </th>
                <th style={{ width: "160px" }}>
                  <small>Отключить передачу показаний на летний период</small>
                </th>
              </tr>
            </thead>
            <tbody className="valign-middle">
              <tr>
                <td>Отопление</td>
                <td>
                  <Field name="heating_telemetry" type="checkbox">
                    {({ field }: FieldProps<string, unknown>) => (
                      <Checkbox
                        disabled={isSubmitting}
                        large={true}
                        style={{ marginBottom: 0 }}
                        {...field}
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name="heating_summer_period" type="checkbox">
                    {({ field }: FieldProps<string, unknown>) => (
                      <Checkbox
                        disabled={isSubmitting}
                        large={true}
                        style={{ marginBottom: 0 }}
                        {...field}
                      />
                    )}
                  </Field>
                </td>
              </tr>
              <tr>
                <td>Горячая&nbsp;вода</td>
                <td>
                  <Field name="hot_water_telemetry" type="checkbox">
                    {({ field }: FieldProps<string, unknown>) => (
                      <Checkbox
                        disabled={isSubmitting}
                        large={true}
                        style={{ marginBottom: 0 }}
                        {...field}
                      />
                    )}
                  </Field>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Холодная&nbsp;вода</td>
                <td>
                  <Field name="cold_water_telemetry" type="checkbox">
                    {({ field }: FieldProps<string, unknown>) => (
                      <Checkbox
                        disabled={isSubmitting}
                        large={true}
                        style={{ marginBottom: 0 }}
                        {...field}
                      />
                    )}
                  </Field>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Электричество</td>
                <td>
                  <Field name="electricity_telemetry" type="checkbox">
                    {({ field }: FieldProps<string, unknown>) => (
                      <Checkbox
                        disabled={isSubmitting}
                        large={true}
                        style={{ marginBottom: 0 }}
                        {...field}
                      />
                    )}
                  </Field>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <br />
                  <Button intent="success" loading={isSubmitting} type="submit">
                    Сохранить
                  </Button>
                </td>
              </tr>
            </tbody>
          </HTMLTable>
        </form>
      )}
    </Formik>
  )
}

type IflatIdForm = {
  i_flat_id: number
}
const IflatIdEditForm: React.VFC<{
  house: ComplexHouse
  onComplete: () => void
}> = ({ house, onComplete }) => {

  const initialValues: IflatIdForm = {
    i_flat_id: house.i_flat_id ?? null,
  }

  const onFormikSubmit = async (
      values: IflatIdForm,
      helpers: FormikHelpers<IflatIdForm>,
  ): Promise<void> => {
    helpers.setStatus("")

    try {
      const { data } = await postIflatId(house.id, values.i_flat_id)

      if (!data.errors) {
        onComplete()
        return
      }

      helpers.setErrors(data.errors)
    } catch {
      helpers.setStatus("Неизвестная ошибка")
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
      <Formik<IflatIdForm> initialValues={initialValues} onSubmit={onFormikSubmit}>
        {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={Classes.DIALOG_BODY}>
                <InputTextField label="ID iflate" name="i_flat_id" />
              </div>

              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                      intent="success"
                      loading={isSubmitting}
                      text={"Сохранить"}
                      type="submit"
                  />
                </div>
              </div>
            </form>
        )}
      </Formik>
  )
}