import { Button, Classes, Label, ProgressBar } from "@blueprintjs/core"
import { CheckPhoneRole } from "components/form/CheckPhoneRole"
import { InputTextField } from "components/form/Input"
import { InputPhoneField } from "components/form/InputPhone"
import { SelectOption } from "components/form/Select"
import { AddressMultiSelect } from "components/form/SelectAddress"
import { Formik, FormikHelpers } from "formik"
import { clearPhone } from "helpers/common"
import { useApiUpdate } from "lib/api/api"
import {
  postTenantAssign,
  postTenantRevoke,
  useGetTenantsByPhone,
} from "lib/api/data"
import {
  ApiTenantUpdateRequest,
  ApiTenantUpdateResponse,
} from "lib/api/types/ApiTenantsResponse"
import * as React from "react"
import * as Yup from "yup"

type TenantsUpdateFormType = {
  phone: string
  first_name: string
  last_name: string
  patronymic: string | null
}

const TenantsUpdateSchema = Yup.object().shape({
  phone: Yup.string()
    .transform(clearPhone)
    .trim()
    .min(11, "Должно быть 11 цифр.")
    .required("Обязательное поле."),
})

type TenantsUpdateProps = {
  phone: number
  onSubmit: () => void
}

export const TenantsUpdate: React.FC<TenantsUpdateProps> = ({
  phone,
  onSubmit,
}) => {
  const [activeAddress, setActiveAddress] = React.useState<
    Array<{
      complex?: SelectOption
      house?: SelectOption
      apartment?: SelectOption
    }>
  >([])

  const [loading, tenant, refetch] = useGetTenantsByPhone(`${phone}`)

  React.useEffect(() => {
    if (!tenant) {
      return
    }

    setActiveAddress(
      tenant.apartments.map((a) => ({
        complex: a.house.complex,
        house: { id: a.house.id, title: a.house.address },
        apartment: { id: a.id, title: `${a.number}` },
      })),
    )
  }, [tenant])

  const [updateTenant] = useApiUpdate<
    ApiTenantUpdateRequest,
    ApiTenantUpdateResponse
  >(`/tenants/${tenant?.id}`, {} as never)

  if (loading) {
    return (
      <div className={Classes.DIALOG_BODY}>
        <ProgressBar />
      </div>
    )
  }

  if (!tenant) {
    return null
  }

  const initialValues: TenantsUpdateFormType = {
    phone: `${tenant.phone}`,
    first_name: tenant.first_name,
    last_name: tenant.last_name,
    patronymic: tenant.patronymic ?? "",
  }

  const onFormikSubmit = async (
    values: TenantsUpdateFormType,
    helpers: FormikHelpers<TenantsUpdateFormType>,
  ): Promise<void> => {
    helpers.setStatus("")

    try {
      const { data } = await updateTenant({
        phone: clearPhone(values.phone),
        first_name: values.first_name,
        last_name: values.last_name,
        patronymic: values.patronymic,
      })

      for (const address of tenant.apartments) {
        await postTenantRevoke(tenant.id, address.house.id, address.number)
      }

      for (const address of activeAddress) {
        if (address.house?.id && address.apartment?.id) {
          await postTenantAssign(
            tenant.id,
            address.house.id,
            address.apartment.title,
          )
        }
      }

      if (!data.errors) {
        refetch()
        onSubmit()
        return
      }
      console.warn("TenantsUpdate", data.errors)

      helpers.setErrors(data.errors)
      console.warn(data.errors)
    } catch {
      helpers.setStatus("Неизвестная ошибка")
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Formik<TenantsUpdateFormType>
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={TenantsUpdateSchema}
      onSubmit={onFormikSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting, values, status }) => (
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <InputPhoneField label="Номер телефона" name="phone" />
            <CheckPhoneRole
              id={tenant.id}
              phone={clearPhone(values.phone)}
              role={"tenant"}
            />

            <InputTextField label="Фамилия" name="last_name" />
            <InputTextField label="Имя" name="first_name" />
            <InputTextField label="Отчество" name="patronymic" />

            <Label style={{ marginBottom: "5px" }}>
              Укажите один или несколько адресов:
            </Label>
            <AddressMultiSelect
              value={activeAddress}
              onChange={setActiveAddress}
            />
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                disabled={!isValid || isSubmitting || !!status}
                intent="success"
                loading={isSubmitting}
                text={"Сохранить"}
                type="submit"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
