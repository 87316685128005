import {Button, Dialog} from "@blueprintjs/core";
import {arrayMoveImmutable} from "array-move";
import * as React from "react";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";

import {CardTable} from "../../components/layout/CardTable";
import {PageHeader} from "../../components/layout/PageHeader";
import {PageLoading} from "../../components/layout/PageLoading";
import {useApiCreate} from "../../lib/api/api";
import {useGetCategories} from "../../lib/api/data";
import {
    ApiPostCategoriesSortRequest,
    ApiPostCategoriesSortRespond,
    Category
} from "../../lib/api/types/ApiCategoryResponse";
import {CategoryCreate} from "./CategoryCreate";
import {CategoryUpdate} from "./CategoryUpdate";


export const Categories: React.FC = () => {
    type sort = {
        oldIndex: number,
        newIndex: number
    }

    const [loading, categories, refetch] = useGetCategories()
    const [showCreateForm, setShowCreateForm] = React.useState<boolean>(false)
    const [showUpdateForm, setShowUpdateForm] = React.useState<number>(0)
    const [sort, loadingSort, dataSort] = useApiCreate<ApiPostCategoriesSortRequest,
        ApiPostCategoriesSortRespond>(`/catalog-services/categories/sort`, {} as never)

    const DragHandle = SortableHandle(() => <Button
        icon="menu"
        minimal={true}
        small={true}
        type="button"
    />);

    const ItemSort = SortableElement(({value}: { value: Category }) => {
        return (
            <tr key={value.id}>
                <td>
                    <DragHandle/>
                </td>
                <td>
                    {value.name}
                </td>
                <td>
                    <Button
                        icon="edit"
                        minimal={true}
                        small={true}
                        type="button"
                        onClick={() => {
                            setShowUpdateForm(value.id)
                        }}
                    />
                </td>
            </tr>
        )
    })

    const SortableList = SortableContainer(({items}: { items: Category[] }) => {
        return (
            <tbody className="valign-middle">
            {items.map((value, index) => (
                <ItemSort key={`item-${value.id}`} index={index} value={value}/>
            ))}
            </tbody>
        );
    });

    const onSortEnd = ({oldIndex, newIndex}: sort) => {
        if (!categories) return

        const newArray = arrayMoveImmutable(categories, oldIndex, newIndex)
        const sortIds = newArray.map((a) => a.id)

        sort(sortIds).then((res) => {
            refetch()
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (res.data.is_ok) {
                return
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    if (loading) {
        return <PageLoading/>
    }

    return (
        <React.Fragment>
            <div className="container">
                <PageHeader>Категории услуг</PageHeader>
                <div>
                    <Button
                        icon="plus"
                        intent="success"
                        text="Добавить категорию"
                        type="button"
                        onClick={() => setShowCreateForm(true)}
                    />
                </div>
                <br/>
                <CardTable>
                    <thead>
                    <tr>
                        <th style={{width: "40px",}} />
                        <th style={{width: "auto"}}>Название</th>
                        <th style={{width: "40px", padding: "8px"}}/>
                    </tr>
                    </thead>
                    {categories && (
                        <SortableList items={categories} lockAxis={'y'} useDragHandle={true} onSortEnd={onSortEnd}/>
                    )}
                </CardTable>
            </div>

            {showCreateForm && (
                <Dialog
                    isOpen={true}
                    title="Добавить категорию"
                    onClose={() => setShowCreateForm(false)}
                >
                    <CategoryCreate
                        onSubmit={() => {
                            setShowCreateForm(false)
                            refetch()
                        }}
                    />
                </Dialog>
            )}

            {showUpdateForm !== 0 && (
                <Dialog
                    isOpen={true}
                    title="Изменить категорию"
                    onClose={() => setShowUpdateForm(0)}
                >
                    <CategoryUpdate
                        id={showUpdateForm}
                        onSubmit={() => {
                            setShowUpdateForm(0)
                            refetch()
                        }}
                    />
                </Dialog>
            )}

        </React.Fragment>
    )
}
