import {Button, Callout, Classes, FileInput} from "@blueprintjs/core"
import {InputTextField} from "components/form/Input"
import {Formik, FormikHelpers} from "formik"
import {apiFileUrl, useApiCreate} from "lib/api/api"

import * as React from "react"
import * as Yup from "yup"

import {categoryOptions, SelectCategories} from "../../components/form/SelectCategories";
import {useGetCategories, useGetServices} from "../../lib/api/data";
import {TextArea} from "../../components/form/TextArea";
import {
    ApiPostServiceUpdateRequest, ApiPostServiceUpdateRespond
} from "../../lib/api/types/ApiServiceResponse";
import {
    ApiPostCategoryImageUpdateRequest,
    ApiPostCategoryImageUpdateRespond
} from "../../lib/api/types/ApiCategoryResponse";
import {PageLoading} from "../../components/layout/PageLoading";

type CategoryCreateFormType = {
    name: string
    image: File | null
    image_logo: File | null
    slug: string
    description: string
    text: string
    category: string | Blob,
    offer: string |undefined,
    url: string,
    published: boolean
}


type ServiceUpdateProps = {
    onSubmit: () => void
    id: number
}


export const ServiceUpdate: React.FC<ServiceUpdateProps> = ({onSubmit, id}) => {

    const [loadingCategories, categories, refetchCategory] = useGetCategories()
    if (loadingCategories) {
        return <PageLoading />
    }
    if (!categories) {
        return null
    }

    const [loadingServices, services, refetch] = useGetServices()
    if (loadingServices) {
        return <PageLoading />
    }
    if (!services) {
        return null
    }

    const categoryOption = categories?.map((c) => {
        return {
            id: c.id,
            name: c.name
        }
    })
    const service = services?.find((e) => `${e.id}` === `${id}`)
    if (!service) {
        return <Callout intent="warning">
            Ошибка, услуга не найдена
        </Callout>
    }
    const category = categories.find((e) => `${e.id}` === `${service.category_id}`)
    if (!category) {
        return <Callout intent="warning">
            Ошибка, категория услуги не найдена
        </Callout>
    }

    const getPath = (path:string):string => {
        return apiFileUrl(path)
    }

    const ManagerCreateSchema = Yup.object().shape({
        name: Yup.string().trim().required("Обязательное поле."),
        slug: Yup.string().trim().max(15, "Ограничение 15 символов").required("Обязательное поле."),
        description: Yup.string().trim().required("Обязательное поле."),
        text: Yup.string().trim().required("Обязательное поле."),
        url: Yup.string().trim().required("Обязательное поле."),
        offer: Yup.string().trim().required("Обязательное поле."),
        category: Yup.string().trim().required("Обязательное поле."),
    })
    //файлы
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
    const [uploadedError, setUploadedError] = React.useState<boolean>(false)
    const [logoImage, setLogoImage] = React.useState<File | null>(null)
    const [uploadedLogoError, setUploadedLogoError] = React.useState<boolean>(false)
    const [isUploading, setUploading] = React.useState<boolean>(false)
    //ошибки
    const [formError, setFormError] = React.useState<string | null>(null)
    const [slugError, setSlugError] = React.useState<string | null>(null)

    const initialValues: CategoryCreateFormType = {
        name: service.name,
        slug: service.slug,
        description: service.description,
        image: null,
        image_logo: null,
        category: `${service.category_id}`,
        offer: service.offer ,
        text: service.text,
        url: service.url,
        published: service.published
    }
    const [update, loading, data] = useApiCreate<ApiPostServiceUpdateRequest,
        ApiPostServiceUpdateRespond >(`/catalog-services/${id}/edit`, {} as never)


    const [updateImage] = useApiCreate<
        ApiPostCategoryImageUpdateRequest,
        ApiPostCategoryImageUpdateRespond>(`/catalog-services/${id}/edit-image`, {} as never)

    const [updateImageLogo] = useApiCreate<
        ApiPostCategoryImageUpdateRequest,
        ApiPostCategoryImageUpdateRespond>(`/catalog-services/${id}/edit-image-logo`, {} as never)

    const onFormikSubmit = async (
        values: CategoryCreateFormType,
        helpers: FormikHelpers<CategoryCreateFormType>,
    ): Promise<void> => {
        //униклаьность slug
        const slugCopy = services?.find((e) => {
            return `${e.slug}` === `${values.slug}` && `${e.id}` !== `${id}`
        })
        if (slugCopy) {
            setSlugError("Slug должен быть уникальным")
            return
        }

        if (selectedFile !== null ) {
            const formFile = new FormData()
            formFile.append("image", selectedFile)
            await updateImage(formFile).then((res) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (res.data.is_ok) {
                    setSelectedFile(null)
                    setUploadedError(false)
                }
            })
                .catch(() => {
                    setSelectedFile(null)
                    setUploadedError(true)
                })
                .finally(() => {
                    setUploading(false)
                })
        }

        if (logoImage !== null) {
            const formLogo = new FormData()
            formLogo.append("image_logo", logoImage)
            await updateImageLogo(formLogo).then((res) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (res.data.is_ok) {
                    setLogoImage(null)
                    setUploadedLogoError(false)
                }
            })
                .catch(() => {
                    setLogoImage(null)
                    setUploadedLogoError(true)
                })
                .finally(() => {
                    setUploading(false)
                })
        }

        const form = new FormData()
        form.append("name", values.name)
        form.append("description", values.description)
        form.append("slug", values.slug)
        form.append("category_id", values.category)
        form.append("offer", values.offer ? values.offer : "")
        form.append("text", values.text)
        form.append("url", values.url)
        form.append("publish", values.published ? "1" : "0")

        await update(form).then((res) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (res.data.is_ok) {
                setSelectedFile(null)
                setLogoImage(null)
                setSlugError(null)
                setFormError(null)
                onSubmit()
                return
            }
        })
            .catch(() => {
                setFormError("Что-то не так повторите попытку")
                setSelectedFile(null)
                setLogoImage(null)
            })
            .finally(() => {
                setUploading(false)
            })
    }

    return (
        <Formik<CategoryCreateFormType>
            initialValues={initialValues}
            validateOnMount={true}
            validationSchema={ManagerCreateSchema}
            onSubmit={onFormikSubmit}
        >
            {({handleSubmit, isValid, isSubmitting, values, status, handleChange}) => (
                <form onSubmit={handleSubmit}>
                    <div className={Classes.DIALOG_BODY}>
                        {(formError) &&
                        <Callout intent="warning">
                            {formError}
                        </Callout>}
                        <InputTextField label="Название" name="name" />
                        {(slugError) &&
                        <Callout intent="warning">
                            {slugError}
                        </Callout>}
                        <InputTextField label="Slug (без пробелов, 15 символов, на анг, для событий. Пример: 'santehnika_7')" name="slug"/>
                        <InputTextField label="Короткое описание (20 символов)" name="description" />
                        <SelectCategories options={categoryOption} name="category" value={service.category_id}/>
                        <InputTextField label="Предложение пример: 'Скидка 8%'" name="offer"/>
                        <TextArea label={"Описание услуги"} name="text"/>

                        <InputTextField label="Ссылка на сайт 'https://google.com'" name="url" noLimit={true}/>
                        <label>
                            <input type="checkbox" style={{marginRight: "5px"}}
                                   defaultChecked={initialValues.published}
                                   onChange={() => {
                                       values.published = !values.published
                                   }}
                            />
                            <span >Публикация</span>
                        </label>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <div className="container">
                                <img width="100" height="100" src={getPath(service.image.url)} alt=""/>
                                <span>Основное изображение, формат jpg 500x280 </span>
                                <FileInput
                                    buttonText="Изменить"
                                    fill={true}
                                    inputProps={{accept: ".jpg"}}
                                    text={selectedFile?.name ?? "Основное изображение .jpg"}
                                    onInputChange={(e) => {
                                        setSelectedFile(e.currentTarget.files?.item(0) ?? null)
                                        setUploadedError(false)
                                    }}
                                />
                                {uploadedError && (
                                    <Callout intent="warning">
                                        Файл невалиден, импорт невозможен.
                                    </Callout>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <div className="container">
                                {(service.image_logo) && (
                                    <img width="100" height="100" src={getPath(service.image_logo.url)}/>
                                )}
                                <span>Логотип изображение, формат png</span>
                                <FileInput
                                    buttonText="Изменить"
                                    fill={true}
                                    inputProps={{accept: ".png"}}
                                    text={logoImage?.name ?? "Логотип компании .png"}
                                    onInputChange={(e) => {
                                        setLogoImage(e.currentTarget.files?.item(0) ?? null)
                                        setUploadedLogoError(false)
                                    }}
                                />
                                {uploadedLogoError && (
                                    <Callout intent="warning">
                                        Файл невалиден, импорт невозможен.
                                    </Callout>
                                )}

                                <br/>
                                <br/>
                                <Button
                                    disabled={false}
                                    intent="success"
                                    loading={isSubmitting}
                                    text={"Сохранить"}
                                    type="submit"
                                />
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}
