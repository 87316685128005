import { Button, InputGroup } from "@blueprintjs/core"
import React from "react"
import { useDebounce } from "react-use"

interface FilterInputProps {
  value: string
  onApply: (value: string) => void
  disabled?: boolean
  placeholder?: string
}

export const FilterInput: React.FC<FilterInputProps> = ({
  value: propValue,
  onApply: onChange,
  disabled,
  placeholder,
}) => {
  const [prevValue, setPrevValue] = React.useState<string>(propValue)
  const [thisValue, setThisValue] = React.useState<string>(propValue)

  React.useEffect(() => {
    if (propValue !== prevValue) {
      setPrevValue(propValue)
      setThisValue(propValue)
    }
  }, [propValue, prevValue])

  useDebounce(() => onChange(thisValue), 500, [thisValue])

  return (
    <InputGroup
      disabled={disabled}
      fill={true}
      placeholder={placeholder}
      rightElement={
        propValue ? (
          <Button
            disabled={!propValue}
            icon="small-cross"
            minimal={true}
            small={true}
            onClick={() => {
              onChange("")
            }}
          />
        ) : undefined
      }
      value={thisValue}
      onChange={(e) => {
        setThisValue(e.currentTarget.value)
      }}
    />
  )
}
