import {Button, Callout, Classes, FileInput, ProgressBar} from "@blueprintjs/core"
import {InputTextField} from "components/form/Input"
import {Formik, FormikHelpers} from "formik"
import {useApiCreate} from "lib/api/api"

import * as React from "react"
import * as Yup from "yup"
import {
    ApiPostCategoriesCreateRequest,
    ApiPostCategoriesCreateRespond
} from "../../lib/api/types/ApiCategoryResponse";
import {useGetCategories} from "../../lib/api/data";

type CategoryCreateFormType = {
    name: string
    image: File | null
    image_select: File | null
    slug: string
}


type CategoryCreateProps = {
    onSubmit: () => void
}

export const CategoryCreate: React.FC<CategoryCreateProps> = ({onSubmit}) => {
    const [loadingCategory, categories, refetch] = useGetCategories()

    const ManagerCreateSchema = Yup.object().shape({
        name: Yup.string().trim().required("Обязательное поле."),
        slug: Yup.string().trim().max(15, "Ограничение 15 символов").required("Обязательное поле."),
    })
    //файлы
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
    const [selectedHoverFile, setSelectedHoverFile] = React.useState<File | null>(null)
    //ошибки
    const [formError, setFormError] = React.useState<string | null>(null)
    const [slugError, setSlugError] = React.useState<string | null>(null)
    const [uploadedError, setUploadedError] = React.useState<boolean>(false)

    const [isUploading, setUploading] = React.useState<boolean>(false)
    const initialValues: CategoryCreateFormType = {
        name: "",
        image: null,
        image_select: null,
        slug: "",
    }
    const [create, loading, data] = useApiCreate<ApiPostCategoriesCreateRequest,
        ApiPostCategoriesCreateRespond >(`/catalog-services/categories`, {} as never)


    const onFormikSubmit = async (
        values: CategoryCreateFormType,
        helpers: FormikHelpers<CategoryCreateFormType>,
    ): Promise<void> => {

        if (selectedFile === null || selectedHoverFile === null) {
            return
        }
        //униклаьность slug
        const slugCopy = categories?.find((e) => {
            return `${e.slug}` === `${values.slug}`
        })
        if (slugCopy) {
            setSlugError("Slug должен быть уникальным")
            return
        }
        const form = new FormData()
        form.append("name", values.name)
        form.append("image", selectedFile)
        form.append("image_select", selectedHoverFile)
        form.append("slug", values.slug)
        form.append("color", "test")
        await create(form).then((res) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (res.data.is_ok) {
                setSelectedFile(null)
                setSelectedHoverFile(null)
                setUploadedError(true)
                setSlugError(null)
                setFormError(null)
                onSubmit()
                return
            }
        })
            .catch(() => {
                setFormError("Что-то не так повторите попытку")
                setSelectedFile(null)
                setSelectedHoverFile(null)
            })
            .finally(() => {
                setUploading(false)
            })
    }

    return (
        <Formik<CategoryCreateFormType>
            initialValues={initialValues}
            validateOnMount={true}
            validationSchema={ManagerCreateSchema}
            onSubmit={onFormikSubmit}
        >
            {({handleSubmit, isValid, isSubmitting, values, status}) => (
                <form onSubmit={handleSubmit}>

                    <div className={Classes.DIALOG_BODY}>
                        {(formError) &&
                        <Callout intent="warning">
                            {formError}
                        </Callout>}
                        <InputTextField label="Название категории" name="name" />
                        {(slugError) &&
                        <Callout intent="warning">
                            {slugError}
                        </Callout>}
                        <InputTextField label="Название slug (без пробелов, 15 символов, на анг, для событий 'santehnika')" name="slug"/>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <div className="container">
                                <span>Изображение (серое), формат png</span>
                                <FileInput
                                    buttonText="Выбрать"
                                    disabled={isUploading}
                                    fill={true}
                                    inputProps={{accept: ".png"}}
                                    text={selectedFile?.name ?? "Выберите изображение .png"}
                                    onInputChange={(e) => {
                                        setSelectedFile(e.currentTarget.files?.item(0) ?? null)
                                        setUploadedError(false)
                                    }}
                                />
                                {uploadedError && (
                                    <Callout intent="warning">
                                        Файл невалиден, импорт невозможен.
                                    </Callout>
                                )}
                                <br/>
                                <br/>
                                <span>Изображение для выделения (зеленое), формат png</span>
                                <FileInput
                                    buttonText="Выбрать"
                                    disabled={isUploading}
                                    fill={true}
                                    inputProps={{accept: ".png"}}
                                    text={selectedHoverFile?.name ?? "Выберите изображение .png"}
                                    onInputChange={(e) => {
                                        setSelectedHoverFile(e.currentTarget.files?.item(0) ?? null)
                                        setUploadedError(false)
                                    }}
                                />
                                {uploadedError && (
                                    <Callout intent="warning">
                                        Файл невалиден, импорт невозможен.
                                    </Callout>
                                )}
                                <br/>
                                <br/>
                                <Button
                                    disabled={!isValid || isSubmitting || !!status || !selectedHoverFile || !selectedFile }
                                    intent="success"
                                    loading={isSubmitting}
                                    text={"Добавить"}
                                    type="submit"
                                />
                                <br/>
                                <br/>
                                {isUploading && <ProgressBar intent="primary"/>}
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}
