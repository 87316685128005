import {
  Button,
  Callout,
  Dialog,
  H3,
  H6,
  Icon,
  ProgressBar,
} from "@blueprintjs/core"
import { CardTable } from "components/layout/CardTable"
import { writePhone } from "helpers/common"
import { useGetTenantsByLs } from "lib/api/data"
import * as React from "react"
import { LsNumberAssign } from "views/Tenants/components/LsNumberAssign"

import {getRole} from "../../../lib/api/api";
import { TenantsCreate } from "../TenantsCreate"
import { TenantsUpdate } from "../TenantsUpdate"

type SearchByLsNumberResultProps = {
  query: string
  onReset: () => void
}

export const SearchByLsNumberResult: React.FC<SearchByLsNumberResultProps> = ({
  query,
  onReset,
}) => {
  const [loading, result, refetch] = useGetTenantsByLs(query)

  const [showLsUpdateForm, setShowLsUpdateForm] = React.useState<number>(0)
  const [showTenantUpdateForm, setShowTenantUpdateForm] =
    React.useState<number>(0)
  const [showTenantCreateForm, setShowTenantCreateForm] =
    React.useState<boolean>(false)

  if (loading) {
    return <ProgressBar />
  }

  const hasResult = !!result?.id

    const role = getRole()
  const address = result?.apartment
    ? {
        complex: result?.apartment.house.complex,
        house: {
          id: result?.apartment.house.id,
          title: result?.apartment.house.address,
        },
        apartment: {
          id: result?.apartment.id,
          title: result?.apartment.number,
        },
      }
    : undefined

  return (
    <React.Fragment>
      <br />
      {!loading && (
        <div style={{ display: "none" }}>
          <Button minimal={true} outlined={true} small={true} onClick={onReset}>
            Очистить поиск
          </Button>
          <br />
          <br />
        </div>
      )}
      <H3>Вы искали: {query}</H3>

      {!hasResult && (
        <Callout icon={null} intent="warning">
          Такого лицевого счета нет в&nbsp;базе. Скорее всего, вы&nbsp;ошиблись
          при вводе, попробуйте ещё раз.
        </Callout>
      )}

      {result && (
        <React.Fragment>
          <br />
          <H6>Адрес, связанный с лицевым счетом</H6>

          {!result.apartment && (
            <React.Fragment>
              <Callout icon={null} intent="warning">
                Нет связанного адреса.
              </Callout>
              <br />
              <Button
                icon="link"
                intent="success"
                text={"Привязать ЛС к адресу"}
                type="button"
                onClick={() => setShowLsUpdateForm(result.number)}
              />
            </React.Fragment>
          )}

          {result.apartment && (
            <CardTable>
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>
                    <small>Лицевой счёт</small>
                  </th>
                  <th>
                    <small>Адрес</small>
                  </th>
                  <th>
                    <small>Услуга</small>
                  </th>
                  <th style={{ width: "15%" }}>
                    <small>Статус</small>
                  </th>
                  <th style={{ width: "15%" }}>
                    <small>Срок действия</small>
                  </th>
                    {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
                </tr>
              </thead>
              <tbody className="valign-middle">
                <tr>
                  <td>{`${result.number}`}</td>
                  <td>
                    {result.apartment.house.complex.title}, ул.{" "}
                    {result.apartment.house.address}, кв.{" "}
                    {result.apartment.number}
                  </td>
                  <td>
                    {result.services.map((service) => (
                      <p key={service.frisbi_code + service.name}>
                        {service.name}
                      </p>
                    ))}
                  </td>
                  <td>{result.is_blocked ? "Истёк" : "Активен"}</td>
                  <td>
                    {result.end_date
                      ? new Date(result.end_date).toLocaleDateString()
                      : "–"}
                  </td>
                    {role === "admin" && <td>
                    <Button
                      icon="edit"
                      minimal={true}
                      type="button"
                      onClick={() => setShowLsUpdateForm(result.number)}
                    />
                  </td>}
                </tr>
              </tbody>
            </CardTable>
          )}

          <br />

          {result.apartment && (
            <React.Fragment>
              <br />
              <H6>Жители, связанные с адресом по лицевому счету</H6>

              {result.apartment.users.length === 0 && (
                <React.Fragment>
                  <Callout icon={null} intent="warning">
                    Жителей по этому адресу не найдено.
                  </Callout>
                    {role === "admin" && <React.Fragment><br />
                  <Button
                    icon="plus"
                    intent="success"
                    text={"Добавить жителя по этому адресу"}
                    type="button"
                    onClick={() => setShowTenantCreateForm(true)}
                  /></React.Fragment>}
                </React.Fragment>
              )}

              {result.apartment.users.length > 0 && (
                <CardTable>
                  <thead>
                    <tr>
                      <th style={{ width: "33%" }}>
                        <small>ФИО</small>
                      </th>
                      <th style={{ width: "15%" }}>
                        <small>Телефон</small>
                      </th>
                      <th>&nbsp;</th>
                        {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
                    </tr>
                  </thead>

                  <tbody className="valign-middle">
                    {result.apartment.users.map((tenant) => (
                      <tr key={tenant.id}>
                        <td>
                          {tenant.last_name} {tenant.first_name}{" "}
                          {tenant.patronymic}
                          {tenant.is_owner && (
                            <Icon icon="crown" style={{ marginLeft: "1rem" }} />
                          )}
                        </td>
                        <td>{writePhone(tenant.phone)}</td>
                        <td>
                          {tenant?.apartments?.map((apartment) => (
                            <div key={apartment.id}>
                              {apartment.house.complex.title}, ул.{" "}
                              {apartment.house.address}, кв. {apartment.number}
                            </div>
                          ))}
                        </td>
                          {role === "admin" && <td>
                          <Button
                            icon="edit"
                            minimal={true}
                            type="button"
                            onClick={() =>
                              setShowTenantUpdateForm(tenant.phone)
                            }
                          />
                        </td>}
                      </tr>
                    ))}
                  </tbody>
                </CardTable>
              )}
                {role === "admin" && <React.Fragment>
                    <br />
              <Button
                icon="plus"
                intent="success"
                text={"Добавить жителя к этому адресу"}
                type="button"
                onClick={() => setShowTenantCreateForm(true)}
              /></React.Fragment>}
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {showLsUpdateForm !== 0 && (
        <Dialog
          isOpen={true}
          title={`Лицевой счёт: ${showLsUpdateForm}`}
          onClose={() => setShowLsUpdateForm(0)}
        >
          <LsNumberAssign
            address={address}
            ls={showLsUpdateForm}
            onSubmit={() => {
              setShowLsUpdateForm(0)
              refetch()
            }}
          />
        </Dialog>
      )}

      {showTenantCreateForm && (
        <Dialog
          isOpen={true}
          title="Добавить жителя"
          onClose={() => setShowTenantCreateForm(false)}
        >
          <TenantsCreate
            values={{ address }}
            onSubmit={() => {
              setShowTenantCreateForm(false)
              refetch()
            }}
          />
        </Dialog>
      )}

      {showTenantUpdateForm !== 0 && (
        <Dialog
          isOpen={true}
          title="Редактировать жителя"
          onClose={() => setShowTenantUpdateForm(0)}
        >
          <TenantsUpdate
            phone={showTenantUpdateForm}
            onSubmit={() => {
              setShowTenantUpdateForm(0)
              refetch()
            }}
          />
        </Dialog>
      )}
    </React.Fragment>
  )
}

// const TooltipContent: React.FC = () => (
//   <div>
//     Вы&nbsp;можете вручную привязать лицевой счет к&nbsp;адресу, чтобы исправить
//     ошибку, возникшую при автоматическом импорте данных из&nbsp;ЕРЦ
//     и&nbsp;объединении их&nbsp;с&nbsp;данными личного кабинета.
//     <br />
//     Ошибка могла возникнуть, если система не&nbsp;смогла распознать адрес
//     правильно или были неверно указаны ФИО.
//     <br />
//     Выполняйте привязку, только если вы&nbsp;абсолютно уверены
//     в&nbsp;правильности своих действий.
//   </div>
// )
