import { Button, Classes, Dialog } from "@blueprintjs/core"
import { InputTextField } from "components/form/Input"
import { CardTable } from "components/layout/CardTable"
import { PageHeader } from "components/layout/PageHeader"
import { PageLoading } from "components/layout/PageLoading"
import { Formik } from "formik"
import {api, getRole} from "lib/api/api"
import { useGetTicketTypes } from "lib/api/data"
import * as React from "react"
import * as Yup from "yup"

export const TicketTypes: React.FC = () => {
  const [loading, ticketTypes, refetch] = useGetTicketTypes()

  const [hasForm, showForm] = React.useState<number | null>(null)

  if (loading) {
    return <PageLoading />
  }

  const onSubmit = async (values: TicketTypeFormType): Promise<void> => {
    const id = ticketTypes?.find((t) => t.id === hasForm)?.id ?? 0
    const action =
      id === 0
        ? api.post(`/ticket_types`, { name: values.name })
        : api.put(`/ticket_types/${id}`, { name: values.name })
    await action
    showForm(null)
    refetch()
  }

  ticketTypes?.sort((t1, t2) => t1.name.localeCompare(t2.name))

  const role = getRole()
  return (
    <div className="container">
      <PageHeader>Виды заявок</PageHeader>
      {role === "admin" && <React.Fragment><Button
        icon="plus"
        intent="success"
        text="Добавить вид заявки"
        type="button"
        onClick={() => showForm(0)}
      />
      <br /> <br /></React.Fragment>}
      <CardTable data-testid={"domTableTicketTypes"}>
        <thead>
          <tr>
            <th>Название</th>
            {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
          </tr>
        </thead>
        <tbody className="valign-middle">
          {ticketTypes?.map((v) => (
            <tr key={v.id}>
              <td>{v.name}</td>
              {role === "admin" && <td>
                <Button
                  icon="edit"
                  minimal={true}
                  small={true}
                  type="button"
                  onClick={() => showForm(v.id)}
                />
              </td>}
            </tr>
          ))}
        </tbody>
      </CardTable>
      {hasForm !== null && (
        <Dialog isOpen={true} title="Вид заявки" onClose={() => showForm(null)}>
          <TicketTypeForm
            values={{
              name: ticketTypes?.find((t) => t.id === hasForm)?.name ?? "",
            }}
            onSubmit={(values) => onSubmit(values)}
          />
        </Dialog>
      )}
    </div>
  )
}

type TicketTypeFormType = {
  name: string
}

const TicketTypeFormSchema = Yup.object().shape({
  name: Yup.string().trim().required("Обязательное поле."),
})

export const TicketTypeForm: React.FC<{
  onSubmit: (values: TicketTypeFormType) => void
  values?: TicketTypeFormType
}> = ({ onSubmit, values }) => {
  const initialValues: TicketTypeFormType = {
    name: values?.name ?? "",
  }

  return (
    <Formik<TicketTypeFormType>
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={TicketTypeFormSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting, values, status }) => (
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <InputTextField label="Название" name="name" />
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                disabled={!isValid || isSubmitting || !!status}
                intent="success"
                loading={isSubmitting}
                text={"Сохранить"}
                type="submit"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
