import {Button, Callout, Classes, FileInput, ProgressBar} from "@blueprintjs/core"
import {InputTextField} from "components/form/Input"
import {Formik, FormikHelpers} from "formik"
import {apiFileUrl, useApiCreate} from "lib/api/api"
import * as React from "react"
import * as Yup from "yup"
import {
  ApiPostCategoryImageUpdateRequest, ApiPostCategoryImageUpdateRespond,
  ApiPostCategoryUpdateRequest, ApiPostCategoryUpdateRespond,
} from "../../lib/api/types/ApiCategoryResponse";
import {PageLoading} from "../../components/layout/PageLoading";
import {useGetCategories} from "../../lib/api/data";
type CategoryCreateFormType = {
  name: string
  slug: string
}


type CategoryUpdateProps = {
  onSubmit: () => void
  id: number
}

export const CategoryUpdate: React.FC<CategoryUpdateProps> = ({onSubmit, id}) => {
  const ManagerCreateSchema = Yup.object().shape({
    name: Yup.string().trim().required("Обязательное поле."),
    slug: Yup.string().trim().max(15, "Ограничение 15 символов").required("Обязательное поле."),
  })

  const [loading, categories, refetch] = useGetCategories()
  const category = categories?.find((e) => `${e.id}` === `${id}`)
  //файлы
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const [uploadedError, setUploadedError] = React.useState<boolean>(false)

  const [selectedHoverFile, setSelectedHoverFile] = React.useState<File | null>(null)
  const [uploadedHoverError, setUploadedHoverError] = React.useState<boolean>(false)
  //ошибки
  const [slugError, setSlugError] = React.useState<string | null>(null)
  const [formError, setFormError] = React.useState<string | null>(null)


  const [isUploading, setUploading] = React.useState<boolean>(false)

  if (loading) {
    return <PageLoading />
  }

  if (!category) {
    return null
  }

  const initialValues: CategoryCreateFormType = {
    name: category.name,
    slug: category.slug,
  }
//todo хорошо бы переделать с post  на put (дело в беке)
  const [updateCategory] = useApiCreate<
      ApiPostCategoryUpdateRequest,
      ApiPostCategoryUpdateRespond>(`/catalog-services/categories/${id}/edit `, {} as never)
//todo хорошо бы переделать с post  на put (дело в беке)
  const [updateImage] = useApiCreate<
      ApiPostCategoryImageUpdateRequest,
      ApiPostCategoryImageUpdateRespond>(`/catalog-services/categories/${id}/edit-image `, {} as never)
//todo хорошо бы переделать с post  на put (дело в беке)
  const [updateSelectImage] = useApiCreate<
      ApiPostCategoryImageUpdateRequest,
      ApiPostCategoryImageUpdateRespond>(`/catalog-services/categories/${id}/edit-image-select `, {} as never)

  const getPath = (path:string):string => {
    return apiFileUrl(path)
  }

  const onFormikSubmit = async (
      values: CategoryCreateFormType,
      helpers: FormikHelpers<CategoryCreateFormType>,
  ): Promise<void> => {
    if (selectedFile !== null) {
      const formFile = new FormData()
      formFile.append("image", selectedFile)
      await updateImage(formFile).then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (res.data.is_ok) {
          setSelectedFile(null)
          setUploadedError(false)
        }
      })
          .catch(() => {
            setSelectedFile(null)
            setUploadedError(true)
          })
          .finally(() => {
            setUploading(false)
          })
    }

    if (selectedHoverFile !== null) {
      const formFileSelect = new FormData()
      formFileSelect.append("image_select", selectedHoverFile)
      await updateSelectImage(formFileSelect).then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (res.data.is_ok) {
          setSelectedHoverFile(null)
          setUploadedHoverError(false)
        }
      }).catch(() => {
          setSelectedHoverFile(null)
          setUploadedHoverError(true)
        })
        .finally(() => {
          setUploading(false)
        })
    }

    const slugCopy = categories?.find((e) => {
      return `${e.slug}` === `${values.slug}` && `${e.id}` !== `${id}`
    })
    if (slugCopy) {
      setSlugError("Slug должен быть уникальным")
      return
    }

    const form = new FormData()
    form.append("name", values.name)
    form.append("slug", values.slug)
    form.append("color", "test")
    await updateCategory(form).then((res) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (res.data.is_ok) {
        setSelectedFile(null)
        setSelectedHoverFile(null)
        setSlugError(null)
        setFormError(null)
        onSubmit()
        return
      }
    }).catch((error) => {
      setFormError("Что-то не так повторите попытку")
      })
      .finally(() => {
        setUploading(false)
      })
  }

  return (
      <Formik<CategoryCreateFormType>
          initialValues={initialValues}
          validateOnMount={true}
          validationSchema={ManagerCreateSchema}
          onSubmit={onFormikSubmit}
      >
        {({handleSubmit, isValid, isSubmitting, values, status}) => (
            <form onSubmit={handleSubmit}>
              <div className={Classes.DIALOG_BODY}>
                {(formError) &&
                <Callout intent="warning">
                  {formError}
                </Callout>}

                <InputTextField label="Название категории" name="name" />
                    {(slugError) &&
                    <Callout intent="warning">
                      {slugError}
                    </Callout>}
                <InputTextField label="Название slug (без пробелов, 15 символов, на анг, для событий 'santehnika')" name="slug"/>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <div className="container">
                    <img width="100" height="100" src={getPath(category.image.url)} style={{marginBottom: "15px"}} />
                    <span>Изображение (серое), формат png</span>
                    <FileInput
                        buttonText="Выбрать"
                        disabled={isUploading}
                        fill={true}
                        inputProps={{accept: ".png"}}
                        text={selectedFile?.name ?? "Выберите изображение .png"}
                        onInputChange={(e) => {
                          setSelectedFile(e.currentTarget.files?.item(0) ?? null)
                          setUploadedError(false)
                        }}
                    />
                    {uploadedError && (
                        <Callout intent="warning">
                          Файл невалиден, импорт невозможен.
                        </Callout>
                    )}
                    <br/>
                    <br/>
                    <img width="100" height="100" src={getPath(category.image_select.url)} style={{marginBottom: "15px"}}/>
                    <span>Изображение для выделения (зеленое), формат png</span>
                    <FileInput
                        buttonText="Выбрать"
                        disabled={isUploading}
                        fill={true}
                        inputProps={{accept: ".png"}}
                        text={selectedHoverFile?.name ?? "Выберите изображение .png"}
                        onInputChange={(e) => {
                          setSelectedHoverFile(e.currentTarget.files?.item(0) ?? null)
                          setUploadedHoverError(false)
                        }}
                    />
                    {uploadedHoverError && (
                        <Callout intent="warning">
                          Файл невалиден, импорт невозможен.
                        </Callout>
                    )}
                    <br/>
                    <br/>
                    <Button
                        disabled={!isValid || isSubmitting || !!status }
                        intent="success"
                        loading={isSubmitting}
                        text={"Изменить"}
                        type="submit"
                    />
                    <br/>
                    <br/>
                    {isUploading && <ProgressBar intent="primary"/>}
                  </div>
                </div>
              </div>
            </form>
        )}
      </Formik>
  )
}
