import * as React from "react"
import { BrowserRouter as Router } from "react-router-dom"

import { App } from "./app"
import { AuthProvider } from "./auth/AuthProvider"

export const Root: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  )
}
