import { Classes } from "@blueprintjs/core"
import { PageHeader, PageHeader2 } from "components/layout/PageHeader"
import { PageLoading } from "components/layout/PageLoading"
import { format, parseISO } from "date-fns"
import { writePhone } from "helpers/common"
import { apiFileUrl } from "lib/api/api"
import {
  useGetEmployeesContractors,
  useGetEmployeesContractorsExt,
  useGetEmployeesManagers,
  useGetTicket,
} from "lib/api/data"
import { ApiTicketResponse } from "lib/api/types/ApiTicketMessagesResponse"
import React from "react"
import { useParams } from "react-router-dom"

const STATUS_MAP: Record<string, string> = {
  new: "Новая",
  done: "Выполнена",
  reopen: "Переоткрыта",
  finished: "Завершена",
  in_service: "В работе",
}

export const TicketMessages: React.FC = () => {
  const { id } = useParams()
  const [loadingTickets, ticket] = useGetTicket(id ?? "")

  const [loadingManagers, allManagers] = useGetEmployeesManagers()
  const [loadingContractors, allContractors] = useGetEmployeesContractors()
  const [loadingContractorsExt, allContractorsExt] =
    useGetEmployeesContractorsExt()

  const isLoading =
    loadingTickets ||
    loadingManagers ||
    loadingContractors ||
    loadingContractorsExt

  if (isLoading) {
    return <PageLoading />
  }

  if (!ticket) {
    return (
      <div className="container">
        <PageHeader>Заявка №{id} не найдена</PageHeader>
      </div>
    )
  }

  const listManagers = allManagers ?? []
  const listContrators = [
    ...(allContractors ?? []),
    ...(allContractorsExt ?? []),
  ]

  const manager = listManagers.find((m) => m.id === ticket.manager?.id)
  const managerTitle = manager
    ? `${manager.first_name} ${manager.last_name}`
    : "Не назначен"

  const contractorsTitle =
    ticket.contractors.length > 0
      ? ticket.contractors
          .map((c) => getEmployeeName(c.id, listContrators))
          .join(", ")
      : "Не назначены"

  const messages: ApiTicketResponse["messages"] = [
    {
      id: 0,
      created_at: ticket.created_at,
      case: null,
      case_value: null,
      files: ticket.files,
      user_id: ticket.user.id,
      message: ticket.description,
    },
    ...ticket.messages,
  ]
  // messages.sort((m1, m2) => m1.created_at.localeCompare(m2.created_at))

  return (
    <div className="container">
      <PageHeader2>Заявка №{id}</PageHeader2>
      <div style={{ fontSize: "24px" }}>
        от {format(parseISO(ticket.created_at), "dd.MM.yy, HH:mm:ss")}
      </div>

      <div
        style={{
          borderTop: "1px solid rgba(0,0,0,0.1)",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          padding: "24px 0",
          margin: "24px 0",
          display: "flex",
          flexDirection: "column",
          rowGap: "8px",
          fontSize: "18px",
        }}
      >
        <div style={{ display: "flex" }}>
          <span style={{ flexGrow: 1 }}>
            <span style={{ opacity: 0.75 }}>Адрес:</span> {ticket.address}
          </span>
          <span>
            <span style={{ opacity: 0.75 }}>Статус:</span>{" "}
            {STATUS_MAP[ticket.status]}
          </span>
          <span style={{ opacity: 0.75, margin: "0 8px" }}> | </span>
          <span>
            <span style={{ opacity: 0.75 }}>Оценка:</span>{" "}
            {[...ticket.ratings].pop()?.rating ?? "Нет"}
          </span>
        </div>
        <span>
          <span style={{ opacity: 0.75 }}>Ответственный менеджер:</span>{" "}
          {managerTitle}
        </span>
        <span>
          <span style={{ opacity: 0.75 }}>Исполнители:</span> {contractorsTitle}
        </span>
        <span>
          <span style={{ opacity: 0.75 }}>Вид заявки:</span>{" "}
          {ticket.type ?? "Не назначен"}
        </span>
        <span>
          <span style={{ opacity: 0.75 }}>Житель:</span>{" "}
          {ticket.user.first_name && ticket.user.last_name && (
            <span>
              {ticket.user.first_name} {ticket.user.last_name}
            </span>
          )}
          {!ticket.user.first_name && !ticket.user.last_name && (
            <span>
              <i>Без имени</i>
            </span>
          )}
          {", "}
          {writePhone(ticket.user.phone)}
          {", "}
          {ticket.calls_allowed ? (
            <span style={{ color: "#43BF4D" }}>звонки разрешены</span>
          ) : (
            <span style={{ color: "#F55656" }}>звонки не разрешены</span>
          )}
        </span>
      </div>

      <h3 style={{ fontSize: "24px", fontWeight: 500 }}>Переписка</h3>

      <div>
        {messages.map((message, k) => (
          <React.Fragment key={message.id}>
            {k !== 0 && <div style={{ borderTop: "1px solid #48AFF0" }} />}
            <div
              style={{
                padding: "24px",
                backgroundColor:
                  ticket.user.id === message.user_id &&
                  (!message.case || message.case === "rating")
                    ? "rgba(72, 175, 240, 0.1)"
                    : "",
              }}
            >
              <div style={{ display: "flex" }}>
                <span
                  style={{ fontSize: "18px", fontWeight: 500, flexGrow: 1 }}
                >
                  {getAuthorName(
                    ticket.user,
                    message.user_id,
                    listManagers,
                    listContrators,
                    message.case,
                  )}
                </span>
                <span
                  className={Classes.TEXT_MUTED}
                  style={{ fontSize: "18px", fontWeight: 300 }}
                >
                  {format(parseISO(message.created_at), "dd.MM.yy | HH:mm:ss")}
                </span>
              </div>
              <div
                style={{
                  marginTop: "8px",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "22px",
                }}
              >
                {message.message}
                {message.case !== null && message.case_value !== null && (
                  <span className={Classes.TEXT_MUTED}>
                    <ServiceMessage
                      contractors={listContrators}
                      kind={message.case}
                      managers={listManagers}
                      meta={message.case_value}
                    />
                  </span>
                )}
                <ImagesMessage files={message.files} />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

const ImagesMessage: React.FC<{ files: [] | Record<string, string> }> = ({
  files,
}) => {
  if (Array.isArray(files)) {
    return null
  }

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        marginTop: "12px",
        columnGap: "8px",
        rowGap: "8px",
      }}
    >
      {Object.values(files).map((file) => (
        <React.Fragment key={file}>
          <a href={apiFileUrl(file)} rel="noreferrer" target="_blank">
            <img
              alt={""}
              src={apiFileUrl(file)}
              style={{
                width: "220px",
                height: "220px",
                objectFit: "cover",
                borderRadius: "4px",
              }}
            />
          </a>
        </React.Fragment>
      ))}
    </div>
  )
}

const ServiceMessage: React.FC<{
  kind: string
  meta: string
  managers: Array<{ id: number; first_name: string; last_name: string }>
  contractors: Array<{ id: number; first_name: string; last_name: string }>
}> = ({ kind, meta, managers, contractors }) => {
  if (kind === "assign") {
    const employee = JSON.parse(meta)
    if (employee.manager) {
      const managerTitle = getEmployeeName(employee.manager, managers)
      return (
        <span style={{ fontSize: "18px", fontWeight: 500 }}>
          Назначен ответственный менеджер — {managerTitle}
        </span>
      )
    }
    if (employee.contractors) {
      const contractorsTitle = employee.contractors.map((c: number) => {
        return getEmployeeName(c, contractors)
      })
      return (
        <span style={{ fontSize: "18px", fontWeight: 500 }}>
          {employee.contractors.length === 1
            ? "Назначен исполнитель"
            : "Назначены исполнители"}
          {" — "}
          {contractorsTitle.join(", ")}
        </span>
      )
    }
  }

  if (kind === "status") {
    let color = "inherit"
    if (meta === "done" || meta === "finished") {
      color = "#43BF4D"
    }
    if (meta === "reopen") {
      color = "#F55656"
    }
    return (
      <span style={{ fontSize: "18px", fontWeight: 500, color }}>
        Изменён статус заявки — {STATUS_MAP?.[meta] ?? ""}
      </span>
    )
  }

  if (kind === "create") {
    return (
      <span style={{ fontSize: "18px", fontWeight: 500 }}>Заявка создана</span>
    )
  }

  if (kind === "rating") {
    return <div>Оценка {meta}</div>
  }

  return (
    <span>
      {kind}, {meta}
    </span>
  )
}

const getEmployeeName = (
  id: number,
  employees: Array<{ id: number; first_name: string; last_name: string }>,
): string => {
  const employee = employees?.find((m) => m.id === id)
  return employee
    ? `${employee.first_name} ${employee.last_name ?? ""}`.trim()
    : ""
}

const getAuthorName = (
  user: {
    id: number
    first_name?: string
    last_name?: string
    patronymic: string
  },
  messageUser: number,
  managers: Array<{ id: number; first_name: string; last_name: string }>,
  contractors: Array<{ id: number; first_name: string; last_name: string }>,
  messageCase?: null | string,
): string => {
  if (messageCase && ["create", "assign", "status"].includes(messageCase)) {
    return ""
  }

  if (user.id === messageUser) {
    return `Житель ${user.first_name ?? ""} ${user.last_name ?? ""}`
  }

  const manager = managers.find((m) => m.id === messageUser)
  if (manager) {
    return `Менеджер ${getEmployeeName(messageUser, managers)}`
  }

  const contractor = contractors.find((m) => m.id === messageUser)
  if (contractor) {
    return `Исполнитель ${getEmployeeName(messageUser, contractors)}`
  }

  return "Сотрудник"
}
