import { Button, Classes, FormGroup, RadioGroup } from "@blueprintjs/core"
import { CheckPhoneRole } from "components/form/CheckPhoneRole"
import { InputTextField } from "components/form/Input"
import { InputPhoneField } from "components/form/InputPhone"
import { PageLoading } from "components/layout/PageLoading"
import { Field, FieldProps, Formik, FormikHelpers } from "formik"
import { clearPhone } from "helpers/common"
import { useApiCreate } from "lib/api/api"
import { EMPLOYEE_ROLE_CONTRACTOR, useGetComplexes } from "lib/api/data"
import {
  ApiEmployeesCreateRequest,
  ApiEmployeesCreateResponse,
} from "lib/api/types/ApiEmployeesResponse"
import * as React from "react"
import * as Yup from "yup"

type ContractorCreateFormType = {
  phone: string
  first_name: string
  last_name: string
  patronymic: string
  responsibility_zone: string
}

const ContractorCreateSchema = Yup.object().shape({
  phone: Yup.string()
    .transform(clearPhone)
    .trim()
    .min(11, "Должно быть 11 цифр.")
    .required("Обязательное поле."),
  first_name: Yup.string().trim().required("Обязательное поле."),
  last_name: Yup.string().trim().required("Обязательное поле."),
  responsibility_zone: Yup.string().trim().required("Обязательное поле."),
})

type ContractorCreateProps = {
  onSubmit: () => void
}

export const ContractorCreate: React.FC<ContractorCreateProps> = ({
  onSubmit,
}) => {
  const [loading, complexes] = useGetComplexes()

  const [createEmployee] = useApiCreate<
    ApiEmployeesCreateRequest,
    ApiEmployeesCreateResponse
  >(`/employees`, {} as never)

  if (loading) {
    return <PageLoading />
  }

  const initialValues: ContractorCreateFormType = {
    phone: "",
    first_name: "",
    last_name: "",
    patronymic: "",
    responsibility_zone: "",
  }

  const onFormikSubmit = async (
    values: ContractorCreateFormType,
    helpers: FormikHelpers<ContractorCreateFormType>,
  ): Promise<void> => {
    helpers.setStatus("")

    try {
      const { data } = await createEmployee({
        role: EMPLOYEE_ROLE_CONTRACTOR,
        phone: clearPhone(values.phone),
        first_name: values.first_name,
        last_name: values.last_name,
        patronymic: values.patronymic,
        responsibility_zone: values.responsibility_zone,
        is_blocked: false,
      })

      if (!data.errors) {
        onSubmit()
        return
      }

      helpers.setErrors(data.errors)
      console.warn(data.errors)
    } catch {
      helpers.setStatus("Неизвестная ошибка")
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Formik<ContractorCreateFormType>
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={ContractorCreateSchema}
      onSubmit={onFormikSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting, values, status }) => (
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <InputPhoneField label="Номер телефона" name="phone" />
            <CheckPhoneRole
              phone={clearPhone(values.phone)}
              role={"employee"}
            />

            <InputTextField label="Фамилия" name="last_name" />
            <InputTextField label="Имя" name="first_name" />
            <InputTextField label="Отчество" name="patronymic" />

            <Field name="responsibility_zone">
              {({
                field,
                meta,
              }: FieldProps<
                ContractorCreateFormType["responsibility_zone"]
              >) => (
                <FormGroup
                  helperText={meta.touched && meta.error}
                  intent={meta.touched && meta.error ? "warning" : "none"}
                  label="Закрепленная территория"
                >
                  <RadioGroup
                    name={field.name}
                    options={complexes?.map((complex) => ({
                      value: `${complex.id}`,
                      label: complex.title,
                    }))}
                    selectedValue={field.value}
                    onChange={field.onChange}
                  />
                </FormGroup>
              )}
            </Field>
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                disabled={!isValid || isSubmitting || !!status}
                intent="success"
                loading={isSubmitting}
                text={"Добавить"}
                type="submit"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
