import { FormGroup, InputGroup } from "@blueprintjs/core"
import { Field, FieldProps } from "formik"
import * as React from "react"

type InputTextFieldProps = {
  name: string
  label: string
  maxLength?: number
  noLimit?: boolean
}

export const InputTextField: React.FC<InputTextFieldProps> = ({
  name,
  label,
  maxLength = 64,
  noLimit = false,
}) => {
  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps<string, unknown>) => (
        <FormGroup
          helperText={meta.touched && meta.error}
          intent={meta.touched && meta.error ? "warning" : "none"}
          label={label}
        >
          {noLimit ?
              <InputGroup
                  autoComplete="off"
                  disabled={form.isSubmitting}
                  fill={true}
                  intent={meta.touched && meta.error ? "warning" : "none"}
                  placeholder={label}
                  {...field}
              /> :
              <InputGroup
                  autoComplete="off"
                  disabled={form.isSubmitting}
                  fill={true}
                  intent={meta.touched && meta.error ? "warning" : "none"}
                  maxLength={maxLength}
                  placeholder={label}
                  {...field}
              />}
        </FormGroup>
      )}
    </Field>
  )
}
