import {
  Button,
  Callout,
  FormGroup,
  InputGroup,
  Navbar,
} from "@blueprintjs/core"
import imgLogo from "assets/images/logo.svg"
import { PageHeader } from "components/layout/PageHeader"
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
} from "formik"
import { apiLogin } from "lib/api/api"
import * as React from "react"

type FormType = {
  username: string
  password: string
}

const validate = async (values: FormType): Promise<FormikErrors<FormType>> => {
  const errors: FormikErrors<FormType> = {}

  if (!values.username.trim()) {
    errors.username = "!"
  }

  if (!values.password.trim()) {
    errors.password = "!"
  }

  return errors
}

export const AuthView: React.FC = () => {
  const initialValues: FormType = {
    username: "",
    password: "",
  }

  const onFormikSubmit = async (
    values: FormType,
    helpers: FormikHelpers<FormType>,
  ): Promise<void> => {
    helpers.setStatus("")

    try {
      await apiLogin(values.username, values.password)
    } catch {
      helpers.setStatus("error")
    }

    helpers.setSubmitting(false)
  }

  return (
    <React.Fragment>
      <Navbar data-testid="domNavigation">
        <div className="container">
          <Navbar.Group>
            <Navbar.Heading style={{ flexShrink: 0 }}>
              <img alt="" src={imgLogo} style={{ width: "5rem" }} />
            </Navbar.Heading>
          </Navbar.Group>
        </div>
      </Navbar>
      <div className={"container"}>
        <PageHeader>Вход</PageHeader>
        <div className={"grid grid--col-12"}>
          <div className={"col-4"}>
            <Formik<FormType>
              initialValues={initialValues}
              validate={validate}
              validateOnMount={true}
              onSubmit={onFormikSubmit}
            >
              {({ isValid, isSubmitting, status }) => (
                <Form>
                  <Field name="username">
                    {({ field, meta }: FieldProps<string, FormType>) => (
                      <FormGroup label="Логин" labelFor="username">
                        <InputGroup
                          autoComplete="off"
                          data-testid={"valUsername"}
                          fill={true}
                          id="username"
                          intent={
                            meta.touched && meta.error ? "warning" : "none"
                          }
                          placeholder="Логин"
                          type="text"
                          {...field}
                        />
                      </FormGroup>
                    )}
                  </Field>
                  <Field name="password">
                    {({ field, meta }: FieldProps<string, FormType>) => (
                      <FormGroup label="Пароль" labelFor="password">
                        <InputGroup
                          autoComplete="off"
                          data-testid={"valPassword"}
                          fill={true}
                          id="password"
                          intent={
                            meta.touched && meta.error ? "warning" : "none"
                          }
                          placeholder="Пароль"
                          type="password"
                          {...field}
                        />
                      </FormGroup>
                    )}
                  </Field>
                  <Button
                    data-testid={"btnSubmit"}
                    disabled={!isValid || isSubmitting}
                    intent="primary"
                    loading={isSubmitting}
                    text={"Войти"}
                    type="submit"
                  />
                  {status === "error" && (
                    <Callout
                      data-testid={"txtError"}
                      icon={null}
                      intent="warning"
                      style={{ marginTop: "1rem" }}
                    >
                      Неправильный логин или пароль.
                    </Callout>
                  )}
                  {status === "error" && (
                    <Callout
                      data-testid={"txtErrorDescription"}
                      icon={null}
                      intent="primary"
                      style={{ marginTop: "1rem" }}
                    >
                      Если у вас возникли трудности со входом, обратитесь к
                      руководителю.
                    </Callout>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
