import { Button, Dialog } from "@blueprintjs/core"
import { CardTable } from "components/layout/CardTable"
import { PageHeader } from "components/layout/PageHeader"
import { PageLoading } from "components/layout/PageLoading"
import { writePhone } from "helpers/common"
import { useGetComplexes, useGetEmployeesContractors } from "lib/api/data"
import * as React from "react"

import {getRole} from "../../lib/api/api";
import { ContractorCreate } from "./ContractorsCreate"
import { ContractorUpdate } from "./ContractorsUpdate"

export const Contractors: React.FC = () => {
  const [loading, contractors, refetch] = useGetEmployeesContractors()
  const [, complexes] = useGetComplexes()

  const [showCreateForm, setShowCreateForm] = React.useState<boolean>(false)
  const [showUpdateForm, setShowUpdateForm] = React.useState<number>(0)

  if (loading) {
    return <PageLoading />
  }

  const role = getRole();

  return (
    <React.Fragment>
      <div className="container">
        <PageHeader>Управление исполнителями</PageHeader>

        <CardTable>
          <thead>
            <tr>
              <th style={{ width: "40%" }}>ФИО</th>
              <th style={{ width: "15%" }}>Телефон</th>
              <th>Закреплённая территория</th>
              <th style={{ width: "40px", padding: "8px" }} />
              {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
            </tr>
          </thead>
          <tbody className="valign-middle">
            {contractors?.map((contractor) => (
              <tr key={contractor.id}>
                <td>
                  {contractor.last_name} {contractor.first_name}{" "}
                  {contractor.patronymic}
                </td>
                <td>{writePhone(contractor.phone)}</td>
                <td>
                  {complexes?.find(
                    (c) => `${c.id}` === `${contractor.responsibility_zone}`,
                  )?.title ?? <span>&hellip;</span>}
                </td>
                <td>
                  <Button
                    disabled={true}
                    icon={contractor.is_blocked ? "blank" : "tick"}
                    intent={"success"}
                    minimal={true}
                    small={true}
                    style={{ cursor: "inherit" }}
                  />
                </td>
                {role === "admin" && <td>
                  <Button
                    icon="edit"
                    minimal={true}
                    small={true}
                    type="button"
                    onClick={() => setShowUpdateForm(contractor.id)}
                  />
                </td>}
              </tr>
            ))}
          </tbody>
        </CardTable>
        {role === "admin" && <React.Fragment>
            <br />
        <Button
          icon="plus"
          intent="success"
          text="Добавить исполнителя"
          type="button"
          onClick={() => setShowCreateForm(true)}
        /></React.Fragment>}
      </div>

      {showCreateForm && (
        <Dialog
          isOpen={true}
          title="Добавить исполнителя"
          onClose={() => setShowCreateForm(false)}
        >
          <ContractorCreate
            onSubmit={() => {
              setShowCreateForm(false)
              refetch()
            }}
          />
        </Dialog>
      )}
      {showUpdateForm !== 0 && (
        <Dialog
          isOpen={true}
          title="Редактировать исполнителя"
          onClose={() => setShowUpdateForm(0)}
        >
          <ContractorUpdate
            id={showUpdateForm}
            onSubmit={() => {
              setShowUpdateForm(0)
              refetch()
            }}
          />
        </Dialog>
      )}
    </React.Fragment>
  )
}
