import { Button } from "@blueprintjs/core"
import { InputPhone } from "components/form/InputPhone"
import { clearPhone } from "helpers/common"
import { useInputState } from "helpers/useInputState"
import * as React from "react"

import { SearchByPhoneResult } from "./SearchByPhoneResult"

type SearchByPhoneProps = {
  onResult: (result: boolean) => void
}

export const SearchByPhone: React.FC<SearchByPhoneProps> = ({ onResult }) => {
  const [phone, setPhone, resetPhone] = useInputState()
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined,
  )
  const isSearchDisabled = clearPhone(phone).length < 11

  const onApplySearch = (): void => {
    setSearchQuery(clearPhone(phone))
    onResult(true)
  }

  const onReset = (): void => {
    setSearchQuery("")
    resetPhone()
  }

  return (
    <React.Fragment>
      <div className="grid grid--col-12 grid--form">
        <div className="col-3">
          <InputPhone
            fill={true}
            value={clearPhone(phone)}
            onChange={setPhone}
            onKeyPress={(e) => {
              console.info(e)
              if (e.key === "Enter") onApplySearch()
            }}
          />
        </div>
        <div className="col-2">
          <Button
            disabled={isSearchDisabled}
            fill={true}
            icon="search-template"
            intent="primary"
            onClick={onApplySearch}
          >
            Найти
          </Button>
        </div>
      </div>
      <div>
        <br />
        {searchQuery && (
          <SearchByPhoneResult query={searchQuery} onReset={onReset} />
        )}
      </div>
    </React.Fragment>
  )
}
