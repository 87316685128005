import {
  Button,
  Callout,
  Dialog,
  H3,
  H6,
  Icon,
  ProgressBar,
} from "@blueprintjs/core"
import { SelectOption } from "components/form/Select"
import { CardTable } from "components/layout/CardTable"
import { writePhone } from "helpers/common"
import { useGetTenantsByAddress } from "lib/api/data"
import * as React from "react"

import {getRole} from "../../../lib/api/api";
import { TenantsCreate } from "../TenantsCreate"
import { TenantsUpdate } from "../TenantsUpdate"
import { LsNumberAssign } from "./LsNumberAssign"

type SearchTypeAddressResultsProps = {
  address: {
    complex?: SelectOption
    house?: SelectOption
    apartment?: SelectOption
  }
  onReset: () => void
}

export const SearchByAddressResults: React.FC<SearchTypeAddressResultsProps> =
  ({ address, onReset }) => {
    const [loading, results, refetch] = useGetTenantsByAddress(
      address.house?.id ?? null,
      address.apartment?.title ?? null,
    )

    const [showLsUpdateForm, setShowLsUpdateForm] = React.useState<number>(0)
    const [showTenantCreateForm, setShowTenantCreateForm] =
      React.useState<boolean>(false)
    const [showTenantUpdateForm, setShowTenantUpdateForm] =
      React.useState<number>(0)

    if (loading) {
      return <ProgressBar />
    }

    const role = getRole()
    
    const hasResultUsers = !!results?.users?.[0]?.id
    const hasResultLsNumbers = !!results?.lsErcList?.[0]?.id

    return (
      <div>
        <br />
        {!loading && (
          <div style={{ display: "none" }}>
            <Button
              minimal={true}
              outlined={true}
              small={true}
              onClick={onReset}
            >
              Очистить поиск
            </Button>
            <br />
            <br />
          </div>
        )}

        <H3>
          Вы искали: {address.house?.title} кв. {address.apartment?.title}
        </H3>

        {/*
      {!hasResultUsers && (
        <Callout icon={null} intent="warning">
          Жителей по этому адресу не найдено.
        </Callout>
      )} */}

        <React.Fragment>
          <br />
          <H6>Лицевые счета, связанные с адресом</H6>
          {!hasResultLsNumbers && (
            <React.Fragment>
              <Callout icon={null} intent="warning">
                В базе мобильного приложения нет лицевых счетов, связанных с
                этим адресом.
                <br />
                <br />
                Чтобы связать правильный лицевой счет с этим адресом, вам нужно:
                <br />
                - узнать в ЕРЦ номер лицевого счета для этого адреса,
                <br />
                - найти лицевой счет по номеру и связать его с адресом.
                <br />
                <br />
                Добавить номер лицевого счета в базу мобильного приложения
                напрямую нельзя. Все лицевые счета добавляются в базу мобильного
                приложения только с помощью автоматического импорта из ЕРЦ.
              </Callout>
              <br />
            </React.Fragment>
          )}

          {hasResultLsNumbers && (
            <CardTable>
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>
                    <small>Лицевой счёт</small>
                  </th>
                  <th>
                    <small>Услуга</small>
                  </th>
                  <th style={{ width: "15%" }}>
                    <small>Статус</small>
                  </th>
                  <th style={{ width: "15%" }}>
                    <small>Срок действия</small>
                  </th>
                    {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
                </tr>
              </thead>
              <tbody className="valign-middle">
                {results?.lsErcList.map((ls) => (
                  <tr key={ls.id}>
                    <td>{ls.number}</td>
                    <td>
                      {ls.services.length > 0
                        ? ls.services.map((service) => (
                            <p key={service.frisbi_code + service.name}>
                              {service.name}
                            </p>
                          ))
                        : "-"}
                    </td>
                    <td>{ls.is_blocked ? "Истёк" : "Активен"}</td>
                    <td>
                      {ls.end_date
                        ? new Date(ls.end_date).toLocaleDateString()
                        : "–"}
                    </td>
                    {role === "admin" && <td>
                      <Button
                        fill={true}
                        icon="edit"
                        minimal={true}
                        small={true}
                        type="button"
                        onClick={() => setShowLsUpdateForm(ls.number)}
                      />
                    </td>}
                  </tr>
                ))}
              </tbody>
            </CardTable>
          )}
        </React.Fragment>

        <br />
        <React.Fragment>
          <br />
          <H6>Жители, связанные с адресом</H6>

          {hasResultUsers && (
            <CardTable>
              <thead>
                <tr>
                  <th style={{ width: "33%" }}>
                    <small>ФИО</small>
                  </th>
                  <th style={{ width: "15%" }}>
                    <small>Телефон</small>
                  </th>
                  <th />
                    {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
                </tr>
              </thead>
              <tbody className="valign-middle">
                {results?.users.map((tenant) => (
                  <tr key={tenant.id}>
                    <td>
                      {tenant.last_name} {tenant.first_name} {tenant.patronymic}
                      {tenant.is_owner && (
                        <Icon icon="crown" style={{ marginLeft: "1rem" }} />
                      )}
                    </td>
                    <td>{writePhone(tenant.phone)}</td>
                    <td />
                      {role === "admin" && <td>
                      <Button
                        fill={true}
                        icon="edit"
                        minimal={true}
                        small={true}
                        type="button"
                        onClick={() => setShowTenantUpdateForm(tenant.phone)}
                      />
                    </td>}
                  </tr>
                ))}
              </tbody>
            </CardTable>
          )}
        </React.Fragment>

          {role === "admin" && <React.Fragment>
        <br />
        <Button
          icon="plus"
          intent="success"
          text={"Добавить жителя по этому адресу"}
          type="button"
          onClick={() => setShowTenantCreateForm(true)}
        /></React.Fragment>}

        {showLsUpdateForm !== 0 && (
          <Dialog
            isOpen={true}
            title={`Лицевой счёт: ${showLsUpdateForm}`}
            onClose={() => setShowLsUpdateForm(0)}
          >
            <LsNumberAssign
              address={address}
              ls={showLsUpdateForm}
              onSubmit={() => {
                setShowLsUpdateForm(0)
                refetch()
              }}
            />
          </Dialog>
        )}

        {showTenantCreateForm && (
          <Dialog
            isOpen={true}
            title="Добавить жителя"
            onClose={() => setShowTenantCreateForm(false)}
          >
            <TenantsCreate
              values={{ address }}
              onSubmit={() => {
                setShowTenantCreateForm(false)
                refetch()
              }}
            />
          </Dialog>
        )}

        {showTenantUpdateForm !== 0 && (
          <Dialog
            isOpen={true}
            title="Редактировать жителя"
            onClose={() => setShowTenantUpdateForm(0)}
          >
            <TenantsUpdate
              phone={showTenantUpdateForm}
              onSubmit={() => {
                setShowTenantUpdateForm(0)
                refetch()
              }}
            />
          </Dialog>
        )}
      </div>
    )
  }
