import {Field, FieldProps} from "formik";
import * as React from "react";
import {FormGroup} from "@blueprintjs/core";

type TextArea = {
    label:string
    name:string,
}

export const TextArea: React.FC<TextArea> = ({label, name}) => {
    return (
        <Field name ={name}>
            {({ field, meta, form }: FieldProps<string, unknown>) => (
                <FormGroup
                    helperText={meta.touched && meta.error}
                    intent={meta.touched && meta.error ? "warning" : "none"}
                    label={label}
                >
                    <textarea style={{
                        border: "none",
                        resize: "none",
                        width: "100%",
                        height: "100px",
                        padding: "5px",
                        boxShadow: "0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%)",
                        borderRadius: "3px",
                    }}  {...field}/>
                </FormGroup>
            )}
        </Field>
    );
}

