import { Card, HTMLDivProps, HTMLTable } from "@blueprintjs/core"
import * as React from "react"

export const CardTable: React.FC<HTMLDivProps> = ({ children, ...props }) => {
  return (
    <Card style={{ padding: 0 }} {...props}>
      <HTMLTable bordered={true} style={{ width: "100%" }}>
        {children}
      </HTMLTable>
    </Card>
  )
}
