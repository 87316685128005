import { Button, Dialog } from "@blueprintjs/core"
import { CardTable } from "components/layout/CardTable"
import { PageHeader } from "components/layout/PageHeader"
import { PageLoading } from "components/layout/PageLoading"
import { writePhone } from "helpers/common"
import { useGetEmployeesManagers } from "lib/api/data"
import * as React from "react"

import { ManagerCreate } from "./ManagersCreate"
import { ManagerUpdate } from "./ManagersUpdate"
import {getRole} from "../../lib/api/api";

export const Managers: React.FC = () => {
  const [loading, managers, refetch] = useGetEmployeesManagers()

  const [showCreateForm, setShowCreateForm] = React.useState<boolean>(false)
  const [showUpdateForm, setShowUpdateForm] = React.useState<number>(0)

  if (loading) {
    return <PageLoading />
  }

  const role = getRole()
  return (
    <React.Fragment>
      <div className="container">
        <PageHeader>Управление менеджерами</PageHeader>

        <CardTable>
          <thead>
            <tr>
              <th style={{ width: "40%" }}>ФИО</th>
              <th style={{ width: "15%" }}>Телефон</th>
              <th />
              <th style={{ width: "40px", padding: "8px" }} />
              {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
            </tr>
          </thead>
          <tbody className="valign-middle">
            {managers?.map((manager) => (
              <tr key={manager.id}>
                <td>
                  {manager.last_name} {manager.first_name} {manager.patronymic}
                </td>
                <td>{writePhone(manager.phone)}</td>
                <td />
                <td>
                  <Button
                    disabled={true}
                    icon={manager.is_blocked ? "blank" : "tick"}
                    intent={"success"}
                    minimal={true}
                    small={true}
                    style={{ cursor: "inherit" }}
                  />
                </td>
                {role === "admin" && <td>
                  <Button
                    icon="edit"
                    minimal={true}
                    small={true}
                    type="button"
                    onClick={() => setShowUpdateForm(manager.id)}
                  />
                </td>}
              </tr>
            ))}
          </tbody>
        </CardTable>

        {role === "admin" && <React.Fragment><br />
        <Button
          icon="plus"
          intent="success"
          text="Добавить менеджера"
          type="button"
          onClick={() => setShowCreateForm(true)}
        /></React.Fragment>}
      </div>

      {showCreateForm && (
        <Dialog
          isOpen={true}
          title="Добавить менеджера"
          onClose={() => setShowCreateForm(false)}
        >
          <ManagerCreate
            onSubmit={() => {
              setShowCreateForm(false)
              refetch()
            }}
          />
        </Dialog>
      )}
      {showUpdateForm !== 0 && (
        <Dialog
          isOpen={true}
          title="Редактировать менеджера"
          onClose={() => setShowUpdateForm(0)}
        >
          <ManagerUpdate
            id={showUpdateForm}
            onSubmit={() => {
              setShowUpdateForm(0)
              refetch()
            }}
          />
        </Dialog>
      )}
    </React.Fragment>
  )
}
