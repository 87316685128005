import { Button } from "@blueprintjs/core"
import { DateRangePicker } from "@blueprintjs/datetime"
import { Popover2 } from "@blueprintjs/popover2"
import { format } from "date-fns"
import { ru } from "date-fns/locale"
import React from "react"

type DateRange = [Date | null, Date | null]

interface FilterDateRangeProps {
  value: DateRange
  onApply: (value: DateRange) => void
  disabled?: boolean
  placeholder?: string
}

export const FilterDateRange: React.FC<FilterDateRangeProps> = ({
  value: propValue,
  onApply,
  disabled,
  placeholder,
}) => {
  const [prevValue, setPrevValue] = React.useState<DateRange>(propValue)
  const [thisValue, setThisValue] = React.useState<DateRange>(propValue)

  React.useEffect(() => {
    if (propValue !== prevValue) {
      setPrevValue(propValue)
      setThisValue(propValue)
    }
  }, [propValue, prevValue])

  const [isOpen, setOpen] = React.useState<boolean>(false)

  return (
    <Popover2
      content={
        <div>
          <DateRangePicker
            allowSingleDayRange={true}
            dayPickerProps={{
              firstDayOfWeek: 1,
              months: DATEPICKER_MONTHS,
              weekdaysShort: DATEPICKER_WEEKDAYS_SHORT,
            }}
            reverseMonthAndYearMenus={true}
            shortcuts={false}
            value={thisValue}
            onChange={setThisValue}
          />
          <div style={{ padding: "8px", textAlign: "center" }}>
            <Button
              disabled={disabled}
              intent="primary"
              minimal={true}
              text="Применить"
              onClick={() => {
                onApply(thisValue)
                setOpen(false)
              }}
            />{" "}
            &nbsp;{" "}
            <Button
              disabled={disabled}
              minimal={true}
              text="Сбросить"
              onClick={() => {
                onApply([null, null])
                setOpen(false)
              }}
            />
          </div>
        </div>
      }
      enforceFocus={false}
      isOpen={isOpen}
      position="bottom"
      onInteraction={setOpen}
    >
      <Button
        disabled={disabled}
        fill={true}
        onClick={() => {
          setOpen((open) => {
            return !open
          })
        }}
      >
        {formateDateRage(thisValue[0], thisValue[1], placeholder)}
      </Button>
    </Popover2>
  )
}

const formateDateRage = (
  from: Date | null,
  till: Date | null,
  placeholder = "",
) => {
  if (from === null && till === null) {
    return placeholder
  }

  const isSame = from?.toISOString() === till?.toISOString()

  return [from, !isSame ? till : null]
    .map((date) => (date ? format(date, "dd.MM.yy", { locale: ru }) : null))
    .filter(Boolean)
    .join(" – ")
}

const DATEPICKER_MONTHS = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
]
const DATEPICKER_WEEKDAYS_SHORT = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]
