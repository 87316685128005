import axios, { AxiosResponse } from "axios"
import * as React from "react"
import useSWR, { SWRConfiguration } from "swr"

const createAuthHeader = (): string => {
  return `Bearer ${readToken()}`
}

export const readToken = (): string => {
  return localStorage.getItem("Authorization") ?? ""
}

export const getRole = (): string => {
  return localStorage.getItem("Role") ?? ""
}

export const wipeToken = (): void => {
  localStorage.removeItem("Authorization")
  delete api.defaults.headers.common["Authorization"]
}

const saveToken = (token: string): void => {
  localStorage.setItem("Authorization", token)
  api.defaults.headers.common["Authorization"] = createAuthHeader()
}

const saveRole = (role: string): void => {
  localStorage.setItem("Role", role)
}

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  headers: {
    Accept: "application/json",
    Authorization: createAuthHeader(),
  },
})

export const apiFileUrl = (file: string): string => {
  const host = api.defaults.baseURL?.replace("/api/v1/superadmin", "")
  return `${host}${file}`
}

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config.url !== "/login" && error.response.status === 401) {
      apiLogout()
    }
    return Promise.reject(error)
  },
)

export const apiLogin = async (
  login: string,
  password: string,
): Promise<void> => {
  const { data } = await api.post(`/login`, { login, password })
  const token = `${data.token}`
  saveToken(token)
  saveRole(login)
  window.location.assign("/")
}

export const apiLogout = (): void => {
  wipeToken()
  window.location.assign("/")
}

const fetcher = (url: string) => {
  return api.get(url).then((res) => res.data.data)
}

export const useApi = <TData>(
  url: null | string,
  options: SWRConfiguration<TData> = {},
): [loading: boolean, data: TData | undefined, refetch: () => void] => {
  const { data, error, mutate } = useSWR<TData>(url, fetcher, { ...options })

  return [!data && !error, data, mutate]
}

export type ApiGetReturn<TData> = [
  loading: boolean,
  data: TData | undefined,
  refetch: () => void,
]

export const useApiCreate = <
  TArgs,
  TData,
  TErrors = Record<keyof TData, string>,
>(
  url: string,
  defaultValue: TData,
): [
  create: (
    args: TArgs,
  ) => Promise<AxiosResponse<{ data: TData; errors: TErrors }>>,
  loading: boolean,
  data: TData,
] => {
  const [data, setData] = React.useState<TData>(defaultValue)
  const [loading, setLoading] = React.useState<boolean>(true)

  const create = React.useCallback(
    (args: TArgs) => {
      setLoading(true)
      return api
        .post<{ data: TData; errors: TErrors }>(url, args)
        .then((response) => {
          setData(response.data.data)
          return response
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [url],
  )

  return [create, loading, data]
}

export const useApiUpdate = <
  TArgs,
  TData,
  TErrors = Record<keyof TData, string>,
>(
  url: string,
  defaultValue: TData,
): [
  update: (
    args: TArgs,
  ) => Promise<AxiosResponse<{ data: TData; errors: TErrors }>>,
  loading: boolean,
  data: TData,
] => {
  const [data, setData] = React.useState<TData>(defaultValue)
  const [loading, setLoading] = React.useState<boolean>(true)

  const update = React.useCallback(
    (args: TArgs) => {
      setLoading(true)
      return api
        .put<{ data: TData; errors: TErrors }>(url, args)
        .then((response) => {
          setData(response.data.data)
          return response
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [url],
  )

  return [update, loading, data]
}
