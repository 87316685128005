import { Button, Classes, Icon, Menu, MenuItem } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import React from "react"

interface FilterSelectProps {
  value: string
  values: { value: string; label: string }[]
  onChange: (value: string) => void
  disabled?: boolean
  placeholder: string
}

export const FilterSelect2: React.FC<FilterSelectProps> = ({
  value,
  values,
  onChange,
  disabled,
  placeholder,
}) => {
  const text = values.find((v) => v.value === value)?.label ?? placeholder

  const items = [{ value: "", label: placeholder }, ...values]

  return (
    <Select<FilterSelectProps["values"][0]>
      disabled={disabled}
      filterable={false}
      itemListRenderer={({ items, itemsParentRef, renderItem }) => {
        const renderedItems = items
          .map((v, i) => renderItem(v, i))
          .filter((item) => item != null)
        return (
          <Menu
            style={{
              maxHeight: "220px",
              width: "380px",
              overflowY: "auto",
              padding: "12px",
            }}
            ulRef={itemsParentRef}
          >
            {renderedItems}
          </Menu>
        )
      }}
      itemRenderer={(item, { handleClick }) => {
        return (
          <MenuItem
            key={item.label}
            className={Classes.TEXT_MUTED}
            style={{
              fontWeight: 100,
              fontSize: "18px",
              margin: "4px 0",
            }}
            text={item.label}
            onClick={handleClick}
          />
        )
      }}
      items={items}
      popoverProps={{
        fill: true,
        position: "bottom",
        onInteraction: () => null,
      }}
      onItemSelect={(item) => {
        onChange(item.value)
      }}
    >
      <Button
        disabled={disabled}
        fill={true}
        rightIcon={
          <svg
            fill="none"
            height="24"
            style={{ marginLeft: "auto" }}
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 9L12 16L5 9"
              stroke="#3AC4FF"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        }
        style={{
          fontWeight: 100,
          fontSize: "18px",
          padding: "12px",
          boxShadow: "none",
          background: "none",
          border: "1px solid #C4C4C4",
          borderRadius: "4px",
          marginBottom: "8px",
        }}
        text={text}
      />
    </Select>
  )
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
  value,
  values,
  onChange,
  disabled,
  placeholder,
}) => {
  const text = values.find((v) => v.value === value)?.label ?? placeholder

  const items = [{ value: "", label: placeholder }, ...values]

  return (
    <Select<FilterSelectProps["values"][0]>
      disabled={disabled}
      filterable={false}
      itemListRenderer={({ items, itemsParentRef, renderItem }) => {
        const renderedItems = items
          .map((v, i) => renderItem(v, i))
          .filter((item) => item != null)
        return (
          <Menu
            style={{
              maxHeight: "220px",
              overflowY: "auto",
            }}
            ulRef={itemsParentRef}
          >
            {renderedItems}
          </Menu>
        )
      }}
      itemRenderer={(item, { handleClick }) => {
        return (
          <MenuItem
            key={item.label}
            className={Classes.TEXT_MUTED}
            text={item.label}
            onClick={handleClick}
          />
        )
      }}
      items={items}
      popoverProps={{
        fill: true,
        position: "bottom",
        onInteraction: () => null,
      }}
      onItemSelect={(item) => {
        onChange(item.value)
      }}
    >
      <Button
        disabled={disabled}
        fill={true}
        rightIcon={<Icon icon="caret-down" style={{ marginLeft: "auto" }} />}
        text={text}
      />
    </Select>
  )
}
