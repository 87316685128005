import { H2 } from "@blueprintjs/core"
import * as React from "react"

export const PageHeader: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <H2 data-testid="domPageHeader" style={{ margin: "2rem 0" }}>
        {children}
      </H2>
    </React.Fragment>
  )
}

export const PageHeader2: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <H2
        data-testid="domPageHeader"
        style={{
          margin: "56px 0 16px",
          fontSize: "48px",
          fontWeight: "normal",
        }}
      >
        {children}
      </H2>
    </React.Fragment>
  )
}
