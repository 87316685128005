import { readToken } from "lib/api/api"
import * as React from "react"

type AuthState = {
  isAuth: boolean
}

const AuthContext = React.createContext<AuthState>({} as never)

export const useAuth = (): AuthState => {
  return React.useContext(AuthContext)
}

export const AuthProvider: React.FC = ({ children }) => {
  return (
    <AuthContext.Provider value={{ isAuth: readToken() !== "" }}>
      {children}
    </AuthContext.Provider>
  )
}
