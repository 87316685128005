import {
  Button,
  Classes,
  IPopoverProps,
  Menu,
  MenuItem,
} from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import * as React from "react"

type SelectSimpleProps = {
  items?: SelectOption[]
  onItemSelect: (item: SelectOption) => void
  activeItem?: SelectOption
  loading?: boolean
  disabled?: boolean
  activeLabel?: string
  defaultLabel?: React.ReactNode | string
  popoverProps?: Partial<IPopoverProps>
  children?: never
}

export type SelectOption = {
  id: number
  title: string
}

export const SelectSimple: React.FC<SelectSimpleProps> = ({
  items = [],
  activeItem,
  onItemSelect,
  loading,
  disabled,
  defaultLabel = "Выберите значение",
  activeLabel = defaultLabel,
  popoverProps,
}) => {
  if (disabled) {
    return (
      <Button
        className={Classes.ALIGN_LEFT}
        disabled={true}
        fill={true}
        rightIcon="double-caret-vertical"
      >
        {activeLabel ?? defaultLabel}
      </Button>
    )
  }

  return (
    <Select<SelectOption>
      activeItem={activeItem}
      disabled={loading}
      filterable={false}
      itemListRenderer={({ items, itemsParentRef, renderItem }) => {
        const renderedItems = items
          .map((item, k) => renderItem(item, k))
          .filter((item) => item != null)
        return (
          <Menu
            style={{ maxHeight: "25rem", overflow: "auto" }}
            ulRef={itemsParentRef}
          >
            {renderedItems}
          </Menu>
        )
      }}
      itemRenderer={(item, { handleClick, modifiers }) => (
        <MenuItem
          key={item.id}
          active={modifiers.active}
          disabled={modifiers.disabled}
          text={item.title}
          onClick={handleClick}
        />
      )}
      items={items}
      popoverProps={{
        minimal: true,
        fill: true,
        position: "bottom-left",
        ...popoverProps,
      }}
      onItemSelect={onItemSelect}
    >
      <Button
        className={Classes.ALIGN_LEFT}
        fill={true}
        loading={loading}
        rightIcon="double-caret-vertical"
      >
        {activeLabel}
      </Button>
    </Select>
  )
}
