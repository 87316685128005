import {
  Button,
  Callout,
  Card,
  Classes,
  FileInput,
  HTMLTable,
  ProgressBar,
} from "@blueprintjs/core"
import { PageHeader } from "components/layout/PageHeader"
import { useApiCreate } from "lib/api/api"
import { useGetImportReports } from "lib/api/data"
import {
  ApiImportUploadRequest,
  ApiImportUploadResponse,
} from "lib/api/types/ApiImport"
import * as React from "react"

export const Import: React.FC = () => {
  const [step, setStep] = React.useState<"upload" | "review" | "result">(
    "upload",
  )

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const [uploadedData, setUploadedData] = React.useState<ApiImportUploadResponse | null>(null)
  const [uploadedError, setUploadedError] = React.useState<boolean>(false)
  const [isUploading, setUploading] = React.useState<boolean>(false)
  const [isImporting, setImporting] = React.useState<boolean>(false)
  const [isReportsLoading, reportsData] = useGetImportReports()
  const [importUpload] = useApiCreate<
    ApiImportUploadRequest,
    ApiImportUploadResponse
  >(`/import/upload`, {} as never)

  const [importConfirm] = useApiCreate<undefined, unknown>(
    `/import/upload/${uploadedData?.hash}`,
    {} as never,
  )

  const upload = () => {
    if (selectedFile === null) {
      return
    }

    const form = new FormData()
    form.append("file", selectedFile)

    setUploading(true)
    importUpload(form)
      .then((res) => {
        if (res.data.data.is_valid) {
          setUploadedData(res.data.data)
          setStep("review")
          return
        }
        setSelectedFile(null)
        setUploadedError(true)
      })
      .catch(() => {
        setSelectedFile(null)
        setUploadedError(true)
      })
      .finally(() => {
        setUploading(false)
      })
  }

  const reviewConfirm = () => {
    setImporting(true)

    importConfirm(undefined)
      .then(() => {
        setStep("result")
      })
      .finally(() => {
        setImporting(false)
      })
  }

  const reviewReject = () => {
    window.location.reload()
  }

  const uploadDisabled = selectedFile === null || isUploading
  const reviewDisabled = isImporting || uploadedData?.rows_valid === 0

  return (
    <React.Fragment>
      <div className="container">
        <PageHeader>Импорт адресов и жителей</PageHeader>
      </div>
      {step === "upload" && (
        <div className="container">
          <div className="grid grid--col-12">
            <div className="col-6">
              <FileInput
                buttonText="Выбрать"
                disabled={isUploading}
                fill={true}
                inputProps={{ accept: ".xlsx" }}
                text={selectedFile?.name ?? "Выберите XLSX файл"}
                onInputChange={(e) => {
                  setSelectedFile(e.currentTarget.files?.item(0) ?? null)
                  setUploadedError(false)
                }}
              />
            </div>
          </div>
          {uploadedError && (
            <div className="grid grid--col-12">
              <div className="col-6">
                <br />
                <Callout intent="warning">
                  Файл невалиден, импорт невозможен.
                </Callout>
              </div>
            </div>
          )}
          <br />
          <Button
            disabled={uploadDisabled}
            icon="upload"
            intent="primary"
            onClick={upload}
          >
            Загрузить файл
          </Button>
          <br />
          <br />
          {isUploading && <ProgressBar intent="primary" />}
        </div>
      )}

      {step === "review" && (
        <React.Fragment>
          <div className="container">
            <Callout intent="warning">
              Внимательно проверьте данные в таблице перед загрузкой!
              <br />
              Строки, в которых не заполнены обязательные данные, не
              импортируются.
              <br />
              Строки с неверным номером телефона не импортируются. Телефон
              должен быть указан с 8 и содержать 11 цифр без пробелов.
            </Callout>
            <br />
            <Callout intent="success">
              В файле найдено строк: <b>{uploadedData?.rows_total}</b>, из них
              будут успешно импортированы: <b>{uploadedData?.rows_valid}</b>.
            </Callout>
            <br />
          </div>
          <div className="container-fluid">
            <Card
              style={{ maxHeight: "360px", overflowY: "scroll", padding: 0 }}
            >
              <HTMLTable
                bordered={true}
                condensed={true}
                style={{ width: "100%" }}
              >
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                  }}
                >
                  <tr>
                    <th style={{ width: "auto" }}>
                      <small>ЛС</small>
                    </th>
                    <th style={{ width: "auto", color: "red" }}>
                      <small>ЖК*</small>
                    </th>
                    <th style={{ width: "auto", color: "red" }}>
                      <small>Улица*</small>
                    </th>
                    <th style={{ width: "auto", color: "red" }}>
                      <small>Дом*</small>
                    </th>
                    <th style={{ width: "auto", color: "red" }}>
                      <small>Секция</small>
                    </th>
                    <th style={{ width: "auto", color: "red" }}>
                      <small>Квартира*</small>
                    </th>
                    <th style={{ width: "auto" }}>
                      <small>Телефон*</small>
                    </th>
                    <th style={{ width: "auto" }}>
                      <small>Фамилия*</small>
                    </th>
                    <th style={{ width: "auto" }}>
                      <small>Имя*</small>
                    </th>
                    <th style={{ width: "auto" }}>
                      <small>Отчество</small>
                    </th>
                  </tr>
                </thead>
                <thead
                  style={{
                    position: "sticky",
                    top: "30px",
                    backgroundColor: "white",
                    boxShadow: "rgb(0 0 0 / 25%) 0 2px 4px 0",
                  }}
                >
                  {EXAMPLE.map((row, i) => (
                    <tr key={i}>
                      <th>{row.ls}</th>
                      <th>{row.complex}</th>
                      <th>{row.street}</th>
                      <th>{row.house}</th>
                      <th>{row.section}</th>
                      <th>{row.apartment}</th>
                      <th>{row.phone}</th>
                      <th>{row.last_name}</th>
                      <th>{row.first_name}</th>
                      <th>{row.patronymic}</th>
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {uploadedData?.preview.map((row, i) => (
                    <tr
                      key={i}
                      className={
                        row.is_valid
                          ? Classes.INTENT_SUCCESS
                          : Classes.INTENT_WARNING
                      }
                    >
                      <td>{row.ls}</td>
                      <td>{row.complex}</td>
                      <td>{row.street}</td>
                      <td>{row.house}</td>
                      <td>{row.section}</td>
                      <td>{row.apartment}</td>
                      <td>{row.phone}</td>
                      <td>{row.last_name}</td>
                      <td>{row.first_name}</td>
                      <td>{row.patronymic}</td>
                    </tr>
                  ))}
                </tbody>
              </HTMLTable>
            </Card>
          </div>
          <br />
          <div className="container">
            <Button
              disabled={reviewDisabled}
              icon="tick-circle"
              intent="success"
              onClick={reviewConfirm}
            >
              Импортировать корректные данные
            </Button>
            <Button
              disabled={reviewDisabled}
              minimal={true}
              style={{ marginLeft: "1rem" }}
              onClick={reviewReject}
            >
              Отмена
            </Button>
            <br />
            <br />
            {isImporting && <ProgressBar intent="success" />}
          </div>
        </React.Fragment>
      )}

      {step === "result" && (
        <div className="container">
          <Callout intent="success">
            Успешно загружено строк: <b>{uploadedData?.rows_valid}</b>.
            <br />
            Данные появятся в приложении в течение часа.
          </Callout>

          <br />

          <Button intent="success" onClick={reviewReject}>
            Ок, вернуться в начало
          </Button>
        </div>
      )}

      {step === "upload" && (
        <div className="container">
          <PageHeader>Отчёты по синхронизации данных с ЕРЦ</PageHeader>
          {isReportsLoading && <ProgressBar />}
          {reportsData?.length === 0 && (
            <Callout>Пока нет ни одного отчёта.</Callout>
          )}
          {reportsData?.map((report) => (
            <p key={report.url}>
              <a href={report.url} rel="noreferrer" target="_blank">
                {report.name}
              </a>
            </p>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

const EXAMPLE: ApiImportUploadResponse["preview"] = [
  {
    ls: "5000000000",
    complex: "Образец",
    street: "Малышева",
    house: "83",
    section: "1",
    apartment: "1",
    phone: "89901000001",
    last_name: "Басов",
    first_name: "Максим",
    patronymic: "Михайлович",
    email: "",
    is_valid: true,
  },
]
