import { Callout, Card, Classes, H3 } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { intervalToDuration } from "date-fns"
import { ApiTicketsReportResponse } from "lib/api/types/ApiTicketsReportResponse"
import React from "react"

type TicketReportSummaryProps = {
  report?: ApiTicketsReportResponse["report"]
}

export const TicketReportSummary: React.FC<TicketReportSummaryProps> = ({
  report,
}) => {
  if (report && report.total === 0) {
    return null
  }

  return (
    <React.Fragment>
      <CardContainer title="Информация по всем заявкам">
        <div className="grid grid--col-12 grid--card">
          <div className="col-6">
            <StatCard
              height={130}
              ready={!!report}
              tooltip={
                <p>
                  Эта цифра показывает общее количество всех завершенных заявок
                  за выбранный период. Учитываются все заявки с оценкой и без
                  оценки.
                </p>
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <span
                  style={{ flexShrink: 0, fontWeight: 100, width: "235px" }}
                >
                  Заявок завершено
                  <br />
                  за выбранный период:
                </span>
                <StatValue size={48}>
                  <IconDone />
                  <span>{report?.total}</span>
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-6">
            <StatCard
              height={130}
              ready={!!report}
              tooltip={
                <p>
                  Эта цифра показывает количество всех заявок, которые были
                  завершены за выбранный период и получили оценки от жителей.
                </p>
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <span
                  style={{ flexShrink: 0, fontWeight: 100, width: "235px" }}
                >
                  Из всех завершенных
                  <br />
                  заявок получили оценку
                  <br />
                  <b>
                    {report?.rated_percent}% от {report?.total}
                  </b>
                </span>
                <StatValue size={48}>
                  <IconStar />
                  <span>{report?.rated}</span>
                </StatValue>
              </div>
            </StatCard>
          </div>
        </div>
      </CardContainer>
      <CardContainer title="Информация по завершенным заявкам, получившим оценку">
        <div className="grid grid--col-12 grid--card">
          <div className="col-3" style={{ marginBottom: "0.5rem" }}>
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Этот процент показывает сколько заявок получили оценку 5 звезд
                  от общего количества заявок, получивших оценки от жителей.
                </p>
              }
            >
              <div>
                <div style={{ height: "96px" }}>
                  <b>
                    {report?.rated_best} из {report?.rated}
                  </b>
                  <br />
                  завершённых заявок
                  <br />
                  получили 5 звезд
                </div>
                <StatValue size={48}>
                  <span>{report?.rated_best_percent}%</span>
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Эта цифра показывает среднюю оценку по всем завершенным
                  заявкам за выбранный период, получивших оценки от жителей.
                </p>
              }
            >
              <div>
                <div style={{ height: "96px" }}>
                  Средняя оценка
                  <br />
                  по заявкам
                </div>

                <StatValue size={48}>
                  <span>{report?.rated_average}</span>
                  <IconStar />
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Это среднее время назначения менеджера на заявку за выбранный
                  период. Т.е. цифра показывает сколько времени в среднем
                  проходит с момента создания заявки до назначения
                  ответственного менеджера.
                </p>
              }
            >
              <div>
                <div style={{ height: "108px" }}>
                  Время через которое
                  <br />
                  менеджер взял <br />
                  заявку в работу
                </div>
                <StatValue size={40}>
                  <span>{humanDuration(report?.manager_assigned_average)}</span>
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Это среднее время выполнения по заявкам. Эта цифра показывает
                  сколько времени прошло с момента создания заявки до отметки
                  исполнителем,что работы выполнены.{" "}
                </p>
              }
            >
              <div>
                <div style={{ height: "108px" }}>
                  Среднее время
                  <br />
                  выполнения работ
                </div>
                <StatValue size={40}>
                  <span>
                    {humanDuration(report?.finished_duration_average)}
                  </span>
                </StatValue>
              </div>
            </StatCard>
          </div>
        </div>
      </CardContainer>
      <CardContainer title="Информация по заявкам закрытым с первого раза, получившим оценку">
        <div className="grid grid--col-12 grid--card">
          <div className="col-3" style={{ marginBottom: "0.5rem" }}>
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Этот процент показывает сколько заявок было закрыто с первого
                  раза от общего количества всех закрытых заявок за выбранный
                  период. При этом у закрытых заявок были оценки от жителей.{" "}
                </p>
              }
            >
              <div>
                <div style={{ height: "96px" }}>
                  <b>
                    {report?.finished_without_reopen} из {report?.rated}
                  </b>{" "}
                  заявок
                  <br />
                  закрыты с первого
                  <br />
                  раза ({report?.finished_without_reopen_rated_best} из них
                  <br />
                  получили 5 звезд)
                </div>
                <StatValue size={48}>
                  <span>{report?.finished_without_reopen_percent}%</span>
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Эта цифра показывает среднюю оценку по всем завершенным
                  заявкам за выбранный период. При этом завершенные заявки были
                  закрыты исполнителем с первого раза, а житель большее по этой
                  заявке не обращался.
                </p>
              }
            >
              <div>
                <div style={{ height: "96px" }}>
                  Средняя оценка
                  <br />
                  по заявкам
                </div>
                <StatValue size={48}>
                  <span>{report?.finished_without_reopen_rated_average}</span>
                  <IconStar />
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Это среднее время назначения менеджера на заявку за выбранный
                  период. Т.е. цифра показывает сколько времени в среднем
                  проходит с момента создания заявки до назначения
                  ответственного менеджера. При этом завершенные заявки были
                  закрыты исполнителем с первого раза, а житель большее по этой
                  заявке не обращался.
                </p>
              }
            >
              <div>
                <div style={{ height: "108px" }}>
                  Время через которое
                  <br />
                  менеджер взял <br />
                  заявку в работу
                </div>
                <StatValue size={40}>
                  <span>
                    {humanDuration(
                      report?.finished_without_reopen_manager_assigned_average,
                    )}
                  </span>
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Это среднее время выполнения по заявкам, которые были закрыты
                  с первого раза. Эта цифра показывает сколько времени прошло с
                  момента создания заявки до нажатия исполнителем кнопки работы
                  выполнены.
                </p>
              }
            >
              <div>
                <div style={{ height: "108px" }}>
                  Среднее время
                  <br />
                  выполнения работ
                </div>
                <StatValue size={40}>
                  <span>
                    {humanDuration(
                      report?.finished_without_reopen_duration_average,
                    )}
                  </span>
                </StatValue>
              </div>
            </StatCard>
          </div>
        </div>
      </CardContainer>
      <CardContainer title="Информация по заявкам, которые были открыты повторно и получили оценку">
        <div className="grid grid--col-12 grid--card">
          <div className="col-3" style={{ marginBottom: "0.5rem" }}>
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Этот процент показывает сколько заявок были открыты повторно
                  от общего количества всех закрытых заявок за выбранный период.
                  При этом у повторно закрытых заявок были оценки от жителей.
                </p>
              }
            >
              <div>
                <div style={{ height: "96px" }}>
                  <b>
                    {report?.finished_with_reopen} из {report?.rated}
                  </b>{" "}
                  заявок
                  <br />
                  открыты повторно
                  <br />({report?.finished_with_reopen_rated_best} из них
                  получили 5 звезд)
                </div>
                <StatValue size={48}>
                  <span>{report?.finished_with_reopen_percent}%</span>
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Эта цифра показывает среднюю оценку по всем повторно открытым
                  заявкам за выбранный период. При этом исполнитель отметил
                  работы выполненными два или более раз.
                </p>
              }
            >
              <div>
                <div style={{ height: "96px" }}>
                  Средняя оценка
                  <br />
                  по заявкам
                </div>
                <StatValue size={48}>
                  <span>{report?.finished_with_reopen_rated_average}</span>
                  <IconStar />
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Это среднее время назначения менеджера на заявку за выбранный
                  период. Т.е. цифра показывает сколько времени в среднем
                  проходит с момента создания заявки до назначения
                  ответственного менеджера. При этом работы по заявке были
                  закрыты после повторного обращения жителя.
                </p>
              }
            >
              <div>
                <div style={{ height: "108px" }}>
                  Время через которое
                  <br />
                  менеджер взял <br />
                  заявку в работу
                </div>
                <StatValue size={40}>
                  <span>
                    {humanDuration(
                      report?.finished_with_reopen_manager_assigned_average,
                    )}
                  </span>
                </StatValue>
              </div>
            </StatCard>
          </div>
          <div className="col-3">
            <StatCard
              height={200}
              ready={!!report}
              tooltip={
                <p>
                  Это среднее время выполнения по заявкам, которые были закрыты
                  как минимум со второго раза. Это значит, что заявка
                  возвращалась в работу один или несколько раз, потому что
                  житель не принимал результат. Считается время с момента
                  создания заявки до последнего нажатия кнопки работы выполнены.
                </p>
              }
            >
              <div>
                <div style={{ height: "108px" }}>
                  Среднее время
                  <br />
                  выполнения работ
                </div>
                <StatValue size={40}>
                  <span>
                    {humanDuration(
                      report?.finished_with_reopen_duration_average,
                    )}
                  </span>
                </StatValue>
              </div>
            </StatCard>
          </div>
        </div>
      </CardContainer>
    </React.Fragment>
  )
}

type CardContainerProps = {
  title: string
}

const CardContainer: React.FC<CardContainerProps> = ({ title, children }) => {
  return (
    <Callout
      icon={null}
      intent="primary"
      style={{
        padding: "24px",
        borderRadius: "8px",
        marginBottom: "24px",
        background: "rgba(58, 196, 255, 0.1)",
      }}
    >
      <H3
        style={{
          margin: "0 0 24px",
          color: "black",
          fontWeight: 500,
          fontSize: "24px",
        }}
      >
        {title}
      </H3>
      {children}
    </Callout>
  )
}

type StatCardProps = {
  height: 130 | 200
  ready: boolean
  tooltip: React.ReactNode
}
const StatCard: React.FC<StatCardProps> = ({
  children,
  height,
  ready,
  tooltip,
}) => {
  return (
    <Card
      className={!ready ? Classes.SKELETON : undefined}
      style={{
        display: "flex",
        height: `${height}px`,
        boxShadow: "none",
        fontWeight: 100,
        fontSize: "16px",
        padding: "8px",
      }}
    >
      <div style={{ display: "flex", flexGrow: 1, padding: "12px" }}>
        {children}
      </div>
      <small style={{ marginLeft: "auto" }}>
        <Tooltip2
          content={<div style={{ maxWidth: "360px" }}>{tooltip}</div>}
          position="top"
        >
          <span>
            <svg
              fill="none"
              height="14"
              viewBox="0 0 14 14"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="7" cy="7" fill="#626262" opacity="0.2" r="7" />
              <path
                d="M5.43544 4.81298L5 4.17176C5.6481 3.72392 6.34008 3.5 7.07595 3.5C7.7038 3.5 8.17975 3.66624 8.5038 3.99873C8.8346 4.32443 9 4.72816 9 5.20992C9 5.46098 8.96624 5.6883 8.89873 5.89186C8.83797 6.08863 8.74008 6.26506 8.60506 6.42112C8.47679 6.5704 8.35527 6.69254 8.24051 6.78753C8.13249 6.87574 7.99409 6.97413 7.82532 7.0827C7.64979 7.19126 7.51477 7.27947 7.42025 7.34733C7.33249 7.41518 7.22447 7.51696 7.0962 7.65267C6.97468 7.78159 6.88017 7.93427 6.81266 8.11069C6.81266 8.7112 6.71477 8.48049 6.70127 8.7112H5.93165C5.90464 8.35157 5.93165 8.03605 6.01266 7.76463C6.10042 7.48643 6.21519 7.27269 6.35696 7.12341C6.49873 6.96735 6.65738 6.81807 6.83291 6.67557C7.00844 6.53308 7.17046 6.41094 7.31899 6.30916C7.47426 6.20059 7.60253 6.0581 7.7038 5.88168C7.81181 5.70526 7.86582 5.50848 7.86582 5.29135C7.86582 4.97243 7.76793 4.73495 7.57215 4.57888C7.37637 4.41603 7.12658 4.33461 6.82278 4.33461C6.33671 4.33461 5.87426 4.49406 5.43544 4.81298ZM5.85063 10.3677C5.76287 10.2795 5.71899 10.1098 5.71899 9.85878C5.71899 9.60772 5.76287 9.43808 5.85063 9.34987C5.9384 9.26166 6.10717 9.21756 6.35696 9.21756C6.6135 9.21756 6.78228 9.26166 6.86329 9.34987C6.95105 9.4313 6.99494 9.60093 6.99494 9.85878C6.99494 10.1098 6.95105 10.2795 6.86329 10.3677C6.70127 10.5 6.60675 10.5 6.35696 10.5C6.10717 10.5 5.9384 10.4559 5.85063 10.3677Z"
                fill="black"
                opacity="0.5"
              />
            </svg>
          </span>
        </Tooltip2>
      </small>
    </Card>
  )
}

type StatValueProps = {
  size: 40 | 48
}
const StatValue: React.FC<StatValueProps> = ({ size, children }) => {
  return (
    <div
      style={{
        fontSize: size,
        display: "flex",
        alignItems: "center",
        columnGap: "12px",
        fontWeight: 100,
      }}
    >
      {children}
    </div>
  )
}

const IconDone: React.FC = () => {
  return (
    <svg
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="16.5" stroke="#3AC4FF" strokeWidth="3" />
      <path
        d="M10 17L17 24L28 13"
        stroke="#3AC4FF"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  )
}

const IconStar: React.FC = () => {
  return (
    <svg
      fill="none"
      height="34"
      viewBox="0 0 35 34"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5486 11.9377L17.5 2.8541L20.4514 11.9377C20.7861 12.9678 21.746 13.6652 22.8291 13.6652H32.3801L24.6532 19.2791C23.7769 19.9157 23.4103 21.0442 23.745 22.0742L26.6964 31.1578L18.9695 25.5438C18.0932 24.9072 16.9068 24.9072 16.0305 25.5438L8.30357 31.1578L11.255 22.0742C11.5897 21.0441 11.223 19.9157 10.3468 19.2791L9.46515 20.4926L10.3468 19.2791L2.61987 13.6652H12.1709C13.254 13.6652 14.2139 12.9678 14.5486 11.9377ZM27.172 32.6213C27.1718 32.621 27.1717 32.6206 27.1716 32.6202L27.172 32.6213Z"
        stroke="#3AC4FF"
        strokeWidth="3"
      />
    </svg>
  )
}

const humanDuration = (seconds?: number): React.ReactNode => {
  if (!seconds) {
    return null
  }

  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  })

  const string = Object.entries(duration)
    .filter(([, v]) => v !== 0)
    .map(([k, v]) => `${v} ${k}`)
    .slice(0, 2)
    .join(" ")
    .replace(/\s+/g, "")
    .replace("months", "<span style='font-size: 18px'> мес </span>")
    .replace("days", "<span style='font-size: 18px'> дн </span>")
    .replace("hours", "<span style='font-size: 18px'> ч </span>")
    .replace("minutes", "<span style='font-size: 18px'> мин </span>")
    .replace("seconds", "<span style='font-size: 18px'> сек </span>")

  return <span dangerouslySetInnerHTML={{ __html: string }} />
}
