import * as React from "react";
import {PageHeader} from "../../components/layout/PageHeader";
import {Button, Dialog} from "@blueprintjs/core";
import {CardTable} from "../../components/layout/CardTable";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import {useGetCategories, useGetServices} from "../../lib/api/data";
import {ServiceCreate} from "./ServiceCreate";
import {ApiPostServicesSortRequest, ApiPostServicesSortRespond, Service} from "../../lib/api/types/ApiServiceResponse";
import {PageLoading} from "../../components/layout/PageLoading";
import {useApiCreate} from "../../lib/api/api";
import {ServiceUpdate} from "./ServiceUpdate"

export const Services: React.FC = () => {
    type sort = {
        oldIndex:number,
        newIndex:number
    }

    const [showCreateForm, setShowCreateForm] = React.useState<boolean>(false)
    const [showUpdateForm, setShowUpdateForm] = React.useState<number>(0)
    const [loading, servicesLoad, refetch] = useGetServices()
    const [sort, loadingSort, dataSort] = useApiCreate<ApiPostServicesSortRequest,
        ApiPostServicesSortRespond >(`/catalog-services/sort`, {} as never)

    const [loadingCategories, categories, refetchCategory] = useGetCategories()

    const DragHandle = SortableHandle(() =>  <Button
        icon="menu"
        minimal={true}
        small={true}
        type="button"
    /> );

    const ItemSort = SortableElement(({value}:{value:Service}) => {
      return (
          <tr key={value.id} >
              <td>
                  <DragHandle/>
              </td>
              <td>
                  {value.name}
              </td>
              <td>
                  {value.published_at}
              </td>
              <td>
                  {value.published && (
                      <span style={{
                          width: "100%",
                          textAlign: "center",
                          display: "block",
                          color: "green"
                      }}>✔</span>
                  )}
              </td>
              <td>
                  <Button
                      icon="edit"
                      minimal={true}
                      small={true}
                      type="button"
                      onClick={() => {
                          setShowUpdateForm(value.id)
                      }}
                  />
              </td>
          </tr>
      )
    })

    const SortableList = SortableContainer(({items}: {items: Service[]}) => {
        return (
            <tbody className="valign-middle">
                {items.map((value, index) => (
                    <ItemSort  key={`item-${value.id}`} index={index} value={value} />
                ))}
            </tbody>
        );
    });

    const  onSortEnd = ({oldIndex, newIndex}:sort) => {
        if (!servicesLoad) return

        const newArray = arrayMoveImmutable(servicesLoad, oldIndex, newIndex)
        const sortIds = newArray.map((a) => a.id)

        sort(sortIds).then((res) => {
            refetch()
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (res.data.is_ok) {
                return
            }
        }).catch((error) => {
                console.log(error)
            })
    };

    if (loading ) {
        return <PageLoading/>
    }

    return (
        <>
            <div className="container">
                <PageHeader>Услуги</PageHeader>
                <Button
                    icon="plus"
                    intent="success"
                    text="Добавить услугу"
                    type="button"
                    onClick={() => setShowCreateForm(true)}
                />
                <br/>
                <br/>
                <div>
                    <CardTable>
                        <thead>
                        <tr style={{verticalAlign:"middle"}}>
                            <th style={{width: "40px",}}></th>
                            <th style={{width: "auto",verticalAlign:"middle"}}>Название</th>
                            <th style={{width: "150px",verticalAlign:"middle"}}>Дата публикации</th>
                            <th style={{width: "80px",verticalAlign:"middle"}}>Опубликован</th>
                            <th style={{width: "40px", padding: "8px"}}/>
                        </tr>
                        </thead>
                        {servicesLoad && (
                            <SortableList items={servicesLoad}  lockAxis={'y'} onSortEnd={onSortEnd} useDragHandle={true}/>
                        )}
                    </CardTable>
                </div>
            </div>
            {showCreateForm && (
                <Dialog
                    isOpen={true}
                    title="Добавить услугу"
                    onClose={() => setShowCreateForm(false)}
                >
                    <ServiceCreate
                        onSubmit={() => {
                            setShowCreateForm(false)
                            refetch()
                        }}
                    />
                </Dialog>
            )}

            {showUpdateForm !==0  && (
                <Dialog
                    isOpen={true}
                    title="Изменение услуги"
                    onClose={() => setShowUpdateForm(0)}
                >
                    <ServiceUpdate
                        onSubmit={() => {
                            setShowUpdateForm(0)
                            refetch()
                        }}
                        id={showUpdateForm}
                    />
                </Dialog>
            )}
        </>
    )
}
