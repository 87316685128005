import React from "react";
import {Field} from "formik";
import {apiLogin} from "../../lib/api/api";

export type categoryOptions = {
    id:number,
    name:string
}

type SelectCategories = {
    name: string,
    options: categoryOptions[]
    value?: number | null
}


export const SelectCategories: React.FC<SelectCategories> = (
    {name,
        options,
        value,
}) => {
    return(
        <>
            <span>Выберите категорию</span>
            <Field as="select" name={name} style={style} >
                {!value && (
                    <option>Выберите категорию</option>
                )}

                {options.map((o) => {
                    const select = value === o.id ? "selected" : ""
                    return <option
                        key={o.id}
                        value={o.id}
                        {... select}
                    >{o.name}</option>
                })}
            </Field>
        </>
    )
}

const style = {
    margin: "0 0 15px 0",
    width: "100%",
    height:"30px",
    borderRadius: "3px",
    border: "none",
    padding: "5px",
    boxShadow: "0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%)"
}
