import { Button } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { AddressSelect, AddressValue } from "components/form/SelectAddress"
import React from "react"

interface FilterAddressProps {
  value?: AddressValue
  onApply: (value?: AddressValue) => void
  disabled?: boolean
  placeholder?: string
}

export const FilterAddress: React.FC<FilterAddressProps> = ({
  value: propValue,
  onApply,
  disabled,
  placeholder,
}) => {
  const [prevValue, setPrevValue] = React.useState<AddressValue | undefined>(
    propValue,
  )
  const [thisValue, setThisValue] = React.useState<AddressValue | undefined>(
    propValue,
  )

  React.useEffect(() => {
    if (propValue !== prevValue) {
      setPrevValue(propValue)
      setThisValue(propValue)
    }
  }, [propValue, prevValue])

  const [isOpen, setOpen] = React.useState<boolean>(false)

  return (
    <Popover2
      content={
        <div style={{ padding: "8px", minWidth: "25rem" }}>
          <AddressSelect value={thisValue} onChange={setThisValue} />
          <div style={{ marginTop: "8px", textAlign: "center" }}>
            <Button
              disabled={disabled}
              intent="primary"
              minimal={true}
              text="Применить"
              onClick={() => {
                onApply(thisValue)
                setOpen(false)
              }}
            />{" "}
            &nbsp;{" "}
            <Button
              disabled={disabled}
              minimal={true}
              text="Сбросить"
              onClick={() => {
                onApply(undefined)
                setThisValue(undefined)
                setOpen(false)
              }}
            />
          </div>
        </div>
      }
      enforceFocus={false}
      isOpen={isOpen}
      position="bottom"
      onInteraction={(state, event) => {
        if (!event?.currentTarget.tagName) {
          setOpen(state)
          if (state === false) {
            setThisValue(propValue)
          }
        }
      }}
    >
      <Button
        disabled={disabled}
        fill={true}
        onClick={() => {
          setOpen((open) => {
            return !open
          })
        }}
      >
        {formatAddress(thisValue, placeholder)}
      </Button>
    </Popover2>
  )
}

const formatAddress = (address?: AddressValue, placeholder = ""): string => {
  if (!address) {
    return placeholder
  }

  let formatted = ""
  if (address.complex?.id) {
    formatted += address.complex.title
  }
  if (address.house) {
    formatted += ", " + address.house.title
  }
  if (address.apartment) {
    formatted += ", " + address.apartment.title
  }
  return formatted
}
