import { Button, NumericInput } from "@blueprintjs/core"
import * as React from "react"

import { SearchByLsNumberResult } from "./SearchByLsNumberResult"

type SearchByLsNumberProps = {
  onResult: (result: boolean) => void
}

export const SearchByLsNumber: React.FC<SearchByLsNumberProps> = ({
  onResult,
}) => {
  const [ls, setLs] = React.useState("")
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined,
  )
  const isSearchDisabled = ls.length !== 10

  const onApplySearch = (): void => {
    setSearchQuery(ls)
    onResult(true)
  }

  const onReset = (): void => {
    setSearchQuery("")
    setLs("")
  }

  return (
    <React.Fragment>
      <div className="grid grid--col-12 grid--form">
        <div className="col-3">
          <NumericInput
            autoComplete="off"
            buttonPosition="none"
            fill={true}
            maxLength={10}
            placeholder="XXXXXXXXXX"
            value={ls}
            onKeyPress={(e) => {
              if (e.key === "Enter") onApplySearch()
            }}
            onValueChange={(_, value) => setLs(value)}
          />
        </div>
        <div className="col-2">
          <Button
            disabled={isSearchDisabled}
            fill={true}
            icon="search-template"
            intent="primary"
            onClick={onApplySearch}
          >
            Найти
          </Button>
        </div>
      </div>
      <div>
        <br />
        {searchQuery && (
          <SearchByLsNumberResult query={searchQuery} onReset={onReset} />
        )}
      </div>
    </React.Fragment>
  )
}
