import { Button } from "@blueprintjs/core"
import { DateRangePicker } from "@blueprintjs/datetime"
import { Popover2 } from "@blueprintjs/popover2"
import { format, set, sub } from "date-fns"
import { ru } from "date-fns/locale"
import React from "react"

type DateRange = [Date | null, Date | null]

interface SelectDateRangeProps {
  value: DateRange
  onApply: (value: DateRange) => void
  onReset: () => void
  disabled?: boolean
}

export const SelectDateRange: React.FC<SelectDateRangeProps> = ({
  value: propValue,
  onApply,
  onReset,
  disabled,
}) => {
  const [prevValue, setPrevValue] = React.useState<DateRange>(propValue)
  const [thisValue, setThisValue] = React.useState<DateRange>(propValue)

  React.useEffect(() => {
    if (propValue !== prevValue) {
      setPrevValue(propValue)
      setThisValue(propValue)
    }
  }, [propValue, prevValue])

  const [isOpen, setOpen] = React.useState<boolean>(false)

  return (
    <Popover2
      content={
        <div>
          <DateRangePicker
            allowSingleDayRange={true}
            dayPickerProps={{
              firstDayOfWeek: 1,
              months: DATEPICKER_MONTHS,
              weekdaysShort: DATEPICKER_WEEKDAYS_SHORT,
            }}
            reverseMonthAndYearMenus={true}
            shortcuts={[
              { label: "Этот месяц", dateRange: rangeThisMonth() },
              { label: "Прошлый месяц", dateRange: rangePrevMonth() },
              { label: "Этот год", dateRange: rangeThisYear() },
              { label: "Прошлый год", dateRange: rangePrevYear() },
            ]}
            value={thisValue}
            onChange={setThisValue}
          />
          <div style={{ padding: "8px", textAlign: "center" }}>
            <Button
              disabled={disabled}
              intent="primary"
              minimal={true}
              text="Применить"
              onClick={() => {
                onApply(thisValue)
                setOpen(false)
              }}
            />{" "}
            &nbsp;{" "}
            <Button
              disabled={disabled}
              minimal={true}
              text="Сбросить"
              onClick={() => {
                onReset()
                setThisValue(propValue)
                setOpen(false)
              }}
            />
          </div>
        </div>
      }
      fill={true}
      isOpen={isOpen}
      position="bottom"
    >
      <Button
        disabled={disabled}
        fill={true}
        rightIcon={
          <svg
            fill="none"
            height="24"
            style={{ marginLeft: "auto" }}
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 4.5H18.6667V2.83328C18.6667 2.37338 18.2933 2 17.8333 2H17C16.5399 2 16.1667 2.37338 16.1667 2.83328V4.5H7.83328V2.83328C7.83328 2.37338 7.46005 2 7 2H6.16672C5.70667 2 5.33328 2.37338 5.33328 2.83328V4.5H4.5C3.12167 4.5 2 5.62167 2 7V19.5C2 20.8783 3.12167 22 4.5 22H19.5C20.8783 22 22 20.8783 22 19.5V7C22 5.62167 20.8783 4.5 19.5 4.5ZM20.3333 19.5C20.3333 19.9591 19.9591 20.3333 19.5 20.3333H4.5C4.04086 20.3333 3.66672 19.9591 3.66672 19.5V10.3667H20.3333V19.5Z"
              fill="#3AC4FF"
            />
          </svg>
        }
        style={{
          fontWeight: 100,
          fontSize: "18px",
          padding: "12px",
          boxShadow: "none",
          background: "none",
          border: "1px solid #C4C4C4",
          borderRadius: "4px",
          marginBottom: "8px",
        }}
        onClick={() => {
          setOpen((open) => {
            return !open
          })
        }}
      >
        {formateDateRange(thisValue[0], thisValue[1])}
      </Button>
    </Popover2>
  )
}

export const formateDateRange = (
  from: Date | null,
  till: Date | null,
  placeholder = "",
) => {
  if (from === null && till === null) {
    return placeholder
  }

  const isSame = from?.toISOString() === till?.toISOString()

  return [from, !isSame ? till : null]
    .map((date) => (date ? format(date, "d MMMM yyyy", { locale: ru }) : null))
    .filter(Boolean)
    .join(" – ")
}

const DATEPICKER_MONTHS = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
]
const DATEPICKER_WEEKDAYS_SHORT = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]

const rangeThisMonth = (): DateRange => {
  const from = set(new Date(), { date: 1 })
  const till = new Date()
  return [from, till]
}

const rangePrevMonth = (): DateRange => {
  const till = sub(set(new Date(), { date: 1 }), { days: 1 })
  const from = set(till, { date: 1 })
  return [from, till]
}

const rangeThisYear = (): DateRange => {
  const from = set(new Date(), { date: 1, month: 0 })
  const till = new Date()
  return [from, till]
}

const rangePrevYear = (): DateRange => {
  const from = sub(set(new Date(), { date: 1, month: 0 }), { years: 1 })
  const till = sub(set(new Date(), { date: 1, month: 0 }), { days: 1 })
  return [from, till]
}
