import { FormGroup, InputGroup, Intent } from "@blueprintjs/core"
import { Field, FieldProps, useFormikContext } from "formik"
import * as React from "react"
import InputMask, { Props as InputMaskProps } from "react-input-mask"

type InputPhoneFieldProps = {
  name: string
  label: string
  checkPhone?: number
}

export const InputPhoneField: React.FC<InputPhoneFieldProps> = ({
  name,
  label,
}) => {
  const { status } = useFormikContext()
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps<string, unknown>) => {
        const hasError = meta.touched && (meta.error || status !== undefined)
        const txtError = status ?? meta.error

        return (
          <FormGroup
            helperText={hasError && txtError}
            intent={hasError ? "warning" : "none"}
            label={label}
          >
            <InputPhone
              fill={true}
              intent={hasError ? "warning" : "none"}
              {...field}
            />
          </FormGroup>
        )
      }}
    </Field>
  )
}

type InputPhoneProps = {
  name?: string
  fill?: boolean
  intent?: Intent
} & Omit<InputMaskProps, "mask">

export const InputPhone: React.FC<InputPhoneProps> = ({
  name,
  fill,
  intent,
  ...props
}) => {
  return (
    <InputMask alwaysShowMask={true} mask="8 999 999-99-99" {...props}>
      {() => (
        <InputGroup
          autoComplete="off"
          fill={fill}
          intent={intent}
          name={name}
          type="tel"
          onKeyPress={props.onKeyPress}
        />
      )}
    </InputMask>
  )
}
