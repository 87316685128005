import { Button, Classes } from "@blueprintjs/core"
import { CheckPhoneRole } from "components/form/CheckPhoneRole"
import { InputTextField } from "components/form/Input"
import { InputPhoneField } from "components/form/InputPhone"
import { Formik, FormikHelpers } from "formik"
import { clearPhone } from "helpers/common"
import { useApiCreate } from "lib/api/api"
import { EMPLOYEE_ROLE_MANAGER } from "lib/api/data"
import {
  ApiEmployeesCreateRequest,
  ApiEmployeesCreateResponse,
} from "lib/api/types/ApiEmployeesResponse"
import * as React from "react"
import * as Yup from "yup"

type ManagerCreateFormType = {
  phone: string
  first_name: string
  last_name: string
  patronymic: string
}

const ManagerCreateSchema = Yup.object().shape({
  phone: Yup.string()
    .transform(clearPhone)
    .trim()
    .min(11, "Должно быть 11 цифр.")
    .required("Обязательное поле."),
  first_name: Yup.string().trim().required("Обязательное поле."),
  last_name: Yup.string().trim().required("Обязательное поле."),
})

type ManagerCreateProps = {
  onSubmit: () => void
}

export const ManagerCreate: React.FC<ManagerCreateProps> = ({ onSubmit }) => {
  const [createManager] = useApiCreate<
    ApiEmployeesCreateRequest,
    ApiEmployeesCreateResponse
  >(`/employees`, {} as never)

  const initialValues: ManagerCreateFormType = {
    phone: "",
    first_name: "",
    last_name: "",
    patronymic: "",
  }

  const onFormikSubmit = async (
    values: ManagerCreateFormType,
    helpers: FormikHelpers<ManagerCreateFormType>,
  ): Promise<void> => {
    helpers.setStatus("")

    try {
      const { data } = await createManager({
        role: EMPLOYEE_ROLE_MANAGER,
        phone: clearPhone(values.phone),
        first_name: values.first_name,
        last_name: values.last_name,
        patronymic: values.patronymic,
        responsibility_zone: null,
        is_blocked: false,
      })
      if (!data.errors) {
        onSubmit()
        return
      }

      helpers.setErrors(data.errors)
      console.warn(data.errors)
    } catch {
      helpers.setStatus("Неизвестная ошибка")
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Formik<ManagerCreateFormType>
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={ManagerCreateSchema}
      onSubmit={onFormikSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting, values, status }) => (
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <InputPhoneField label="Номер телефона" name="phone" />
            <CheckPhoneRole
              phone={clearPhone(values.phone)}
              role={"employee"}
            />

            <InputTextField label="Фамилия" name="last_name" />
            <InputTextField label="Имя" name="first_name" />
            <InputTextField label="Отчество" name="patronymic" />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                disabled={!isValid || isSubmitting || !!status}
                intent="success"
                loading={isSubmitting}
                text={"Добавить"}
                type="submit"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
