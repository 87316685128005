import {api, ApiGetReturn, apiLogin, useApi} from "./api"
import {
  ApiComplexesResponse,
  ApiComplexHousesResponse,
  ApiEmployeesResponse,
  ApiTenantByLsResponse,
  ApiTenantsByAddressResponse,
  ApiTenantsByFullNameResponse,
  ApiTenantsByPhoneResponse,
  ApiUsersByPhoneResponse,
} from "./types"
import { ApiComplexHousesApartmentsResponse } from "./types/ApiComplexHouseApartmentsResponse"
import { ApiImportReportsResponse } from "./types/ApiImport"
import {
  ApiMetersPermissions,
  MetersPermissions,
} from "./types/ApiMetersPermissions"
import { ApiTicketResponse } from "./types/ApiTicketMessagesResponse"
import {
  ApiTicketsReportRequest,
  ApiTicketsReportResponse,
} from "./types/ApiTicketsReportResponse"
import {
  ApiTicketsRequest,
  ApiTicketsResponse,
} from "./types/ApiTicketsResponse"
import { ApiTicketStatusesResponse } from "./types/ApiTicketStatusesResponse"
import { ApiTicketTypesResponse } from "./types/ApiTicketTypesResponse"
import {ApiGetCategoriesResponse, Category} from "./types/ApiCategoryResponse";
import {ApiGetServiceResponse, Service} from "./types/ApiServiceResponse";

export const EMPLOYEE_ROLE_MANAGER = "manager"
export const EMPLOYEE_ROLE_CONTRACTOR = "contractor"
export const EMPLOYEE_ROLE_CONTRACTOR_EXT = "ext_contractor"

export const useGetComplexes = (): ApiGetReturn<ApiComplexesResponse> => {
  const [loading, data, refetch] = useApi<ApiComplexesResponse>(`/complexes`)

  data?.sort((s1, s2) => s1.title.localeCompare(s2.title))

  return [loading, data, refetch]
}

export const useGetComplexHouses = (
  complex: null | number,
): ApiGetReturn<ApiComplexHousesResponse> => {
  const [loading, data, refetch] = useApi<ApiComplexHousesResponse>(
    Number.isInteger(complex) ? `/complexes/houses/${complex}` : null,
  )

  data?.sort((s1, s2) => s1.address.localeCompare(s2.address))

  return [loading, data, refetch]
}

export const useGetComplexHouseApartments = (
  house: null | number,
): ApiGetReturn<ApiComplexHousesApartmentsResponse> => {
  const [loading, data, refetch] = useApi<ApiComplexHousesApartmentsResponse>(
    Number.isInteger(house) ? `/complexes/apartments/${house}` : null,
  )

  data?.sort(
    (s1, s2) => Number.parseInt(s1.number, 10) - Number.parseInt(s2.number, 10),
  )

  return [loading, data, refetch]
}

export const useGetEmployeesContractors =
  (): ApiGetReturn<ApiEmployeesResponse> => {
    const [loading, data, refetch] = useApi<ApiEmployeesResponse>(
      `/employees/role/${EMPLOYEE_ROLE_CONTRACTOR}`,
    )

    data?.sort((s1, s2) => {
      const isBlocked = s1.is_blocked ? +1 : -1
      const byBlocked = s1.is_blocked === s2.is_blocked ? 0 : isBlocked

      return byBlocked || s1.last_name?.localeCompare(s2.last_name)
    })

    return [loading, data, refetch]
  }

export const useGetEmployeesContractorsExt =
  (): ApiGetReturn<ApiEmployeesResponse> => {
    const [loading, data, refetch] = useApi<ApiEmployeesResponse>(
      `/employees/role/${EMPLOYEE_ROLE_CONTRACTOR_EXT}`,
    )

    data?.sort((s1, s2) => {
      const isBlocked = s1.is_blocked ? +1 : -1
      const byBlocked = s1.is_blocked === s2.is_blocked ? 0 : isBlocked

      return byBlocked || s1.last_name?.localeCompare(s2.last_name)
    })

    return [loading, data, refetch]
  }

export const useGetCategories =
    (): ApiGetReturn<Category[]> => {
        const [loading, data, refetch] = useApi<ApiGetCategoriesResponse>(
            `/catalog-services/categories`,
        )

        return [
            loading,
            data?.service_categories && data?.service_categories.length > 0 ? data.service_categories : [] ,
            refetch
        ]
    }

export const useGetServices =
    (): ApiGetReturn<Service[] | []> => {
        const [loading, data, refetch] = useApi<ApiGetServiceResponse>(
            `/catalog-services`,
        )
        return [
            loading,
            data?.services && data?.services.length > 0 ? data.services : [] ,
            refetch
        ]
    }

export const useGetEmployeesManagers =
  (): ApiGetReturn<ApiEmployeesResponse> => {
    const [loading, data, refetch] = useApi<ApiEmployeesResponse>(
      `/employees/role/${EMPLOYEE_ROLE_MANAGER}`,
    )

    data?.sort((s1, s2) => {
      const isBlocked = s1.is_blocked ? +1 : -1
      const byBlocked = s1.is_blocked === s2.is_blocked ? 0 : isBlocked

      return byBlocked || s1.last_name?.localeCompare(s2.last_name)
    })
    return [loading, data, refetch]
  }

export const useGetTenantsByAddress = (
  house: null | number,
  apartment: null | number | string,
): ApiGetReturn<ApiTenantsByAddressResponse> => {
  return useApi(
    house && apartment ? `/tenants/address/${house}/${apartment}` : null,
  )
}

export const useGetTenantsByFullName = (
  fullName: string,
): ApiGetReturn<ApiTenantsByFullNameResponse> => {
  return useApi(fullName ? `/tenants/full-name/${fullName}` : null)
}

export const useGetTenantsByPhone = (
  phone: null | string,
): ApiGetReturn<ApiTenantsByPhoneResponse> => {
  return useApi(phone ? `/tenants/phone/${phone}` : null)
}

export const useGetTenantsByLs = (
  ls: null | string,
): ApiGetReturn<ApiTenantByLsResponse> => {
  return useApi(ls ? `/tenants/ls/${ls}` : null)
}

export const useGetUsersByPhone = (
  phone: null | string,
): ApiGetReturn<ApiUsersByPhoneResponse> => {
  return useApi(phone ? `/users/phone/${phone}` : null)
}

export const postTenantAssign = async (
  id: number,
  house: number,
  apartment: string,
) => {
  return api.post(`/tenants/${id}/assign/${house}/${apartment}`)
}

export const postTenantRevoke = async (
  id: number,
  house: number,
  apartment: string,
) => {
  return api.post(`/tenants/${id}/revoke/${house}/${apartment}`)
}

export const useGetTicketTypes = (): ApiGetReturn<ApiTicketTypesResponse> => {
  return useApi(`/ticket_types`)
}

export const useGetTicketStatuses =
  (): ApiGetReturn<ApiTicketStatusesResponse> => {
    return [
      false,
      [
        { value: "new", title: "Новая" },
        { value: "in_service", title: "В работе" },
        { value: "done", title: "Выполнена" },
        { value: "finished", title: "Завершена" },
        { value: "reopen", title: "Повторная" },
      ],
      () => null,
    ]

    // return useApi(`/ticket/types`)
  }

export const useGetTickets = (
  request: ApiTicketsRequest,
): ApiGetReturn<ApiTicketsResponse> => {
  const params = new URLSearchParams(request as never)
  return useApi(`/tickets?${params}`)
}

export const useGetTicketsReport = (
  request: ApiTicketsReportRequest,
): ApiGetReturn<ApiTicketsReportResponse> => {
  const params = new URLSearchParams(request as never)
  return useApi(`/tickets/finished?${params}`)
}

export const useGetImportReports =
  (): ApiGetReturn<ApiImportReportsResponse> => {
    return useApi(`/imports/reports`)
  }

export const useGetTicket = (id: string): ApiGetReturn<ApiTicketResponse> => {
  return useApi(`/tickets/${id}`)
}

export const useGetHouseMetersPermissions = (
  house: number,
): ApiGetReturn<ApiMetersPermissions> => {
  return useApi(`/houses/${house}/meters_permissions`)
}

export const postHouseMetersPermissions = async (
  house: number,
  meters_permissions: MetersPermissions,
) => {
  return api.patch(`/houses/${house}/meters_permissions`, {
    meters_permissions,
  })
}

export const postIflatId = async (
    house: number,
    iflatId: number
) => {
    return api.put(`/houses/${house}`, {
        i_flat_id: iflatId,
    })
}
