import { Button, Classes, Label, Popover } from "@blueprintjs/core"
import { SelectOption } from "components/form/Select"
import { AddressSelect, AddressValue } from "components/form/SelectAddress"
import { useApiCreate } from "lib/api/api"
import * as React from "react"

type LsNumberUpdateProps = {
  ls: number
  address?: {
    complex?: SelectOption
    house?: SelectOption
    apartment?: SelectOption
  }
  onSubmit: () => void
}

export const LsNumberAssign: React.FC<LsNumberUpdateProps> = ({
  ls,
  address,
  onSubmit,
}) => {
  const [activeAddress, setActiveAddress] = React.useState<
    AddressValue | undefined
  >(address)

  const [isSubmitting, setSubmitting] = React.useState<boolean>(false)

  const isAddressAssigned = !!address?.complex?.id

  const [lsNumberAssign] = useApiCreate(
    `/ls/${ls}/assign/${activeAddress?.house?.id}/${activeAddress?.apartment?.title}`,
    {} as never,
  )

  const [lsNumberRevoke] = useApiCreate(
    `/ls/${ls}/revoke/${activeAddress?.house?.id}/${activeAddress?.apartment?.title}`,
    {} as never,
  )

  const doAssign = async () => {
    setSubmitting(true)
    await lsNumberAssign({})
    onSubmit()
    setSubmitting(false)
  }

  const doRevoke = async () => {
    setSubmitting(true)
    await lsNumberRevoke({})
    onSubmit()
    setSubmitting(false)
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {!isAddressAssigned && (
          <Label>Укажите адрес, с которым нужно связать лицевой счёт:</Label>
        )}
        <AddressSelect value={activeAddress} onChange={setActiveAddress} />
        <br />
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {isAddressAssigned && (
            <React.Fragment>
              <Popover
                content={
                  <div style={{ padding: "1rem" }}>
                    <Button
                      disabled={isSubmitting}
                      intent="danger"
                      loading={isSubmitting}
                      text={"Подтвердить"}
                      type="button"
                      onClick={doRevoke}
                    />
                  </div>
                }
              >
                <Button
                  disabled={isSubmitting}
                  intent="warning"
                  loading={isSubmitting}
                  outlined={true}
                  text={"Отвязать адрес"}
                  type="button"
                />
              </Popover>
            </React.Fragment>
          )}

          {!isAddressAssigned && (
            <React.Fragment>
              <Button
                disabled={isSubmitting}
                intent="success"
                loading={isSubmitting}
                text={"Привязать адрес"}
                type="submit"
                onClick={doAssign}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
