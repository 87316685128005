import { Button } from "@blueprintjs/core"
import { SelectOption, SelectSimple } from "components/form/Select"
import {
  useGetComplexes,
  useGetComplexHouseApartments,
  useGetComplexHouses,
} from "lib/api/data"
import * as React from "react"

import { SearchByAddressResults } from "./SearchByAddressResult"

type SearchByAddressProps = {
  onResult: (result: boolean) => void
}

export const SearchByAddress: React.FC<SearchByAddressProps> = ({
  onResult,
}) => {
  const [activeComplex, setActiveComplex] = React.useState<SelectOption>()
  const [activeHouse, setActiveHouse] = React.useState<SelectOption>()
  const [activeApartment, setActiveApartment] = React.useState<SelectOption>()

  const [selectedComplex, setSelectedComplex] = React.useState<SelectOption>()
  const [selectedHouse, setSelectedHouse] = React.useState<SelectOption>()
  const [selectedApartment, setSelectedApartment] =
    React.useState<SelectOption>()

  const [loadingComplexes, complexes] = useGetComplexes()
  const [loadingHouses, houses] = useGetComplexHouses(activeComplex?.id ?? null)
  const [loadingApartments, apartments] = useGetComplexHouseApartments(
    activeHouse?.id ?? null,
  )

  const itemsComplexes = complexes?.map((c) => ({ id: c.id, title: c.title }))
  const itemsHouses = houses?.map((h) => ({ id: h.id, title: h.address }))
  const itemsApartments = apartments?.map((a) => ({
    id: a.id,
    title: a.number,
  }))

  itemsApartments?.sort((a1, a2) => {
    return a1.title.localeCompare(a2.title, "ru", { numeric: true })
  })

  const onComplexSelect = (complex: SelectOption): void => {
    if (complex.id === activeComplex?.id) {
      return
    }

    setActiveComplex(complex)
    setActiveHouse(undefined)
    setActiveApartment(undefined)
  }

  const onHouseSelect = (house: SelectOption): void => {
    if (house.id === activeHouse?.id) {
      return
    }

    setActiveHouse(house)
    setActiveApartment(undefined)
  }

  const onApartmentSelect = (apartment: SelectOption): void => {
    if (apartment.id === activeApartment?.id) {
      return
    }

    setActiveApartment(apartment)
  }

  const onApplySearch = (): void => {
    setSelectedComplex(activeComplex)
    setSelectedHouse(activeHouse)
    setSelectedApartment(activeApartment)

    onResult(true)
  }

  const onReset = (): void => {
    setSelectedApartment(undefined)
    setSelectedHouse(undefined)
    setSelectedComplex(undefined)
  }

  const isSearchDisabled =
    (activeComplex?.id === selectedComplex?.id &&
      activeHouse?.id === selectedHouse?.id &&
      activeApartment?.id === selectedApartment?.id) ||
    !activeHouse?.id ||
    !activeApartment?.id

  return (
    <React.Fragment>
      <div className="grid grid--col-12 grid--form">
        <div className="col-3">
          <SelectSimple
            activeItem={activeComplex}
            activeLabel={
              complexes?.find((c) => c.id === activeComplex?.id)?.title
            }
            defaultLabel="ЖК"
            items={itemsComplexes}
            loading={loadingComplexes}
            onItemSelect={onComplexSelect}
          />
        </div>
        <div className="col-4">
          <SelectSimple
            activeItem={activeHouse}
            activeLabel={houses?.find((h) => h.id === activeHouse?.id)?.address}
            defaultLabel="Дом"
            disabled={!activeComplex?.id}
            items={itemsHouses}
            loading={loadingHouses}
            onItemSelect={onHouseSelect}
          />
        </div>
        <div className="col-2">
          <SelectSimple
            activeItem={activeApartment}
            activeLabel={
              apartments?.find((a) => a.id === activeApartment?.id)?.number
            }
            defaultLabel="Квартира"
            disabled={!activeHouse?.id}
            items={itemsApartments}
            loading={loadingApartments}
            onItemSelect={onApartmentSelect}
          />
        </div>
        <div className="col-2">
          <Button
            disabled={isSearchDisabled}
            fill={true}
            icon="search-template"
            intent="primary"
            onClick={onApplySearch}
          >
            Найти
          </Button>
        </div>
      </div>

      <div>
        <br />
        {selectedComplex?.id && selectedHouse?.id && selectedApartment?.id && (
          <SearchByAddressResults
            address={{
              complex: selectedComplex,
              house: selectedHouse,
              apartment: selectedApartment,
            }}
            onReset={onReset}
          />
        )}
      </div>
    </React.Fragment>
  )
}
