import {
  Alignment,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Navbar,
} from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import imgLogo from "assets/images/logo.svg"
import {apiLogout, getRole, readToken} from "lib/api/api"
import * as React from "react"
import { Link, useNavigate } from "react-router-dom"

export const AppHeader: React.FC = () => {
  const navigate = useNavigate()

  const role = getRole();

  return (
    <Navbar data-testid="domNavigation">
      <div className="">
        <Navbar.Group>
          <Navbar.Heading style={{ flexShrink: 0 }}>
            <Link to="/">
              <img alt="" src={imgLogo} style={{ width: "5rem" }} />
            </Link>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <ButtonGroup minimal={true}>
            <Popover2
                content={
                  <Menu>
                    <MenuItem
                        data-testid="btnNavigationManagers"
                        text="Категории"
                        onClick={() => navigate(`/services-categories`)}
                    />
                    <MenuItem
                        data-testid="btnNavigationContractors"
                        text="Услуги"
                        onClick={() => navigate(`/services`)}
                    />
                  </Menu>
                }
                position="bottom-right"
            >
              {role === "admin" && <Button
                  data-testid="btnNavigationEmployeesMenu"
                  rightIcon="chevron-down"
                  text="Каталог услуг"
              />}
            </Popover2>

            <Button
              data-testid="domNavigationBtnTenants"
              onClick={() => navigate(`/tenants`)}
            >
              Жители
            </Button>
            &nbsp;{" "}
            <Popover2
              content={
                <Menu>
                  <MenuItem
                    data-testid="btnNavigationManagers"
                    text="Менеджеры"
                    onClick={() => navigate(`/managers`)}
                  />
                  <MenuItem
                    data-testid="btnNavigationContractors"
                    text="Исполнители"
                    onClick={() => navigate(`/contractors`)}
                  />
                  <MenuItem
                    data-testid="btnNavigationContractorsExt"
                    text="Подрядчики"
                    onClick={() => navigate(`/contractors-ext`)}
                  />
                </Menu>
              }
              position="bottom-right"
            >
              <Button
                data-testid="btnNavigationEmployeesMenu"
                rightIcon="chevron-down"
                text="Сотрудники"
              />
            </Popover2>
            &nbsp;{" "}
            <Popover2
              content={
                <Menu>
                  <MenuItem
                    data-testid="btnNavigationTickets"
                    text="Поиск заявок"
                    onClick={() => navigate(`/tickets`)}
                  />
                  <MenuItem
                    data-testid="btnNavigationTicketsReport"
                    text="Отчёт по работе УК"
                    onClick={() => navigate(`/tickets-report`)}
                  />
                  <MenuItem
                    data-testid="btnNavigationTicketTypes"
                    text="Виды заявок"
                    onClick={() => navigate(`/ticket-types`)}
                  />
                </Menu>
              }
              position="bottom-right"
            >
              <Button
                data-testid="btnNavigationTicketsMenu"
                rightIcon="chevron-down"
                text="Заявки"
              />
            </Popover2>
            &nbsp;{" "}
            {role === "admin" && <React.Fragment><Button
              data-testid="btnNavigationHouses"
              onClick={() => navigate(`/houses`)}
            >
              Просмотр ЖК
            </Button>
            &nbsp;{" "}</React.Fragment>}
            {role === "admin" && <React.Fragment><Button
              data-testid="btnNavigationImport"
              onClick={() => navigate(`/import`)}
            >
              Импорт
            </Button>
            &nbsp;{" "}</React.Fragment>}
            <Popover2
              content={
                <Menu>
                  <MenuItem
                    data-testid="btnNavigationLogout"
                    icon="log-out"
                    text="Выйти"
                    onClick={apiLogout}
                  />
                </Menu>
              }
              position="bottom-right"
            >
              <Button data-testid="btnNavigationMoreMenu" icon="more" />
            </Popover2>
          </ButtonGroup>
        </Navbar.Group>
      </div>
    </Navbar>
  )
}
