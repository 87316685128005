import { FocusStyleManager } from "@blueprintjs/core"
import { AppHeader } from "components/layout/AppHeader"
import * as React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { AuthView } from "views/Auth/AuthView"
import { Houses } from "views/ComplexHouses/Houses"
import { Contractors } from "views/EmployeeContractors/Contractors"
import { ContractorsExt } from "views/EmployeeContractorsExt/ContractorsExt"
import { Managers } from "views/EmployeeManagers/Managers"
import { Import } from "views/Import/Import"
import { Tenants } from "views/Tenants/Tenants"
import { TicketMessages } from "views/Tickets/TicketMessages"
import { Tickets } from "views/Tickets/Tickets"
import { TicketsReport } from "views/Tickets/TicketsReport"
import { TicketTypes } from "views/Tickets/TicketTypes"

import { useAuth } from "./auth/AuthProvider"
import {Categories} from "../views/Services/Categories";
import {Services} from "../views/Services/Services";

export const App: React.FC = () => {
  FocusStyleManager.onlyShowFocusOnTabs()

  const { isAuth } = useAuth()

  if (!isAuth) {
    return <AuthView />
  }

  return (
    <React.Fragment>
      <AppHeader />
      <Routes>
        <Route element={<Tenants />} path={"/tenants"} />
        <Route element={<Managers />} path={"/managers"} />
        <Route element={<Contractors />} path={"/contractors"} />
        <Route element={<ContractorsExt />} path={"/contractors-ext"} />
        <Route element={<Tickets />} path={"/tickets"} />
        <Route element={<TicketMessages />} path={"/tickets/:id"} />
        <Route element={<TicketsReport />} path={"/tickets-report"} />
        <Route element={<TicketTypes />} path={"/ticket-types"} />
        <Route element={<Houses />} path={"/houses"} />
        <Route element={<Import />} path={"/import"} />
        <Route element={<Navigate to={"/tenants"} />} path={"/"} />
        <Route element={<Categories />} path={"/services-categories"} />
        <Route element={<Services />} path={"/services"} />
      </Routes>
    </React.Fragment>
  )
}
