import * as React from "react"

type StateValue = string
type StateOnChange = (v: React.ChangeEvent<HTMLInputElement>) => void
type StateReset = () => void

type useInputStateType = (
  initialValue?: StateValue,
) => [value: StateValue, onChange: StateOnChange, reset: StateReset]

export const useInputState: useInputStateType = (initialValue = "") => {
  const [value, setValue] = React.useState<StateValue>(initialValue)

  const onChange = React.useCallback<StateOnChange>((v) => {
    setValue(v.currentTarget.value)
  }, [])

  const reset = React.useCallback(() => {
    setValue("")
  }, [])

  return [value, onChange, reset]
}
