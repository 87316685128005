import { Button, ButtonGroup, Icon } from "@blueprintjs/core"

type PaginationProps = {
  total: number
  pageSize: number
  current: number
  onClick: (page: number) => void
}
export const Pagination: React.FC<PaginationProps> = ({
  total,
  pageSize,
  current,
  onClick,
}) => {
  if (total === 0) {
    return null
  }

  const pages = Array.from({ length: Math.ceil(total / pageSize) })
    .fill(null)
    .map((_, k) => k)

  if (pages.length < 2) {
    return null
  }

  return (
    <ButtonGroup
      style={{
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button disabled={current === 0} onClick={() => onClick(current - 1)}>
        <Icon icon="chevron-left" />
      </Button>
      {pages.map((page) => (
        <Button
          key={page}
          intent={current === page ? "primary" : "none"}
          style={{ width: "30px" }}
          onClick={() => onClick(page)}
        >
          {page + 1}
        </Button>
      ))}
      <Button
        disabled={current === pages.length - 1}
        onClick={() => onClick(current + 1)}
      >
        <Icon icon="chevron-right" />
      </Button>
    </ButtonGroup>
  )
}
