import { Button, Card, Classes, HTMLTable } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { PageHeader2 } from "components/layout/PageHeader"
import { format, parseISO } from "date-fns"
import {useGetComplexes, useGetEmployeesManagers, useGetTicketsReport, useGetTicketTypes} from "lib/api/data"
import * as React from "react"

import { FilterInput } from "./components/FilterInput"
import { FilterSelect, FilterSelect2 } from "./components/FilterSelect"
import { Pagination } from "./components/Pagination"
import { formateDateRange, SelectDateRange } from "./components/SelectDateRange"
import { TicketReportSummary } from "./components/TicketReportSummary"

const DATE_NOW = new Date()
const DEFAULT_RANGE: [Date, Date] = [
  new Date(DATE_NOW.getFullYear(), DATE_NOW.getMonth(), 1),
  DATE_NOW,
]

type Filter = {
  rating: string
  manager: string
  contractor: string
  sort: "id_asc" | "id_desc"
}

const FILTER_DEFAULT: Filter = {
  rating: "",
  manager: "",
  contractor: "",
  sort: "id_desc",
}

const PAGE_SIZE = 20

export const TicketsReport: React.FC = () => {
  const [filter, setFilter] = React.useState<Filter>(FILTER_DEFAULT)
  const setFilterValue = <T extends keyof Filter>(
    key: T,
    value: Filter[T],
  ): void => {
    setFilter((prev) => ({ ...prev, [key]: value }))
    setPage(0)
  }

  const [selectedDateRange, setSelectedDateRange] =
    React.useState<[Date | null, Date | null]>(DEFAULT_RANGE)
  const [selectedType, setSelectedType] = React.useState<string>("")
  const [selectedManager, setSelectedManager] = React.useState<string>("")
  const [selectedComplex, setSelectedComplex] = React.useState<string>("")
  const selectedDateRangeLabel = formateDateRange(...selectedDateRange)

  const [page, setPage] = React.useState(0)

  const paramsReport = {
    finished_at_from: selectedDateRange[0]
      ? format(selectedDateRange[0], "Y-MM-dd")
      : "",
    finished_at_till: selectedDateRange[1]
      ? format(selectedDateRange[1], "Y-MM-dd")
      : "",
    type: selectedType,
    take: 10_000,
    from: 0,
    manager_id: selectedManager,
    address_complex: selectedComplex,
  }
  const paramsTickets = {
    finished_at_from: selectedDateRange[0]
      ? format(selectedDateRange[0], "Y-MM-dd")
      : "",
    finished_at_till: selectedDateRange[1]
      ? format(selectedDateRange[1], "Y-MM-dd")
      : "",
    type: selectedType,
    manager_id: selectedManager,
    address_complex: selectedComplex,
    take: 10_000,
    from: 0,
    sort: filter.sort,
    rating: filter.rating,
    manager: filter.manager,
    contractor: filter.contractor,
  }

  const [loadingReport, responseReport] = useGetTicketsReport(paramsReport)
  const [loadingTickets, responseAllTickets] = useGetTicketsReport(paramsTickets)
  const [, allManagers] = useGetEmployeesManagers()
  const [, allComplexes] = useGetComplexes()

  const [, ticketTypes] = useGetTicketTypes()

  const selectedTypeLabel =
    ticketTypes?.find((type) => `${type.id}` === selectedType)?.name ?? "Все"
  const sortById = ["id_asc", "id_desc"].includes(filter.sort)

  let sortIconById: "sort-asc" | "sort-desc" = "sort-asc"
  if (filter.sort === "id_asc") {
    sortIconById = "sort-asc"
  }
  if (filter.sort === "id_desc") {
    sortIconById = "sort-desc"
  }

  const sortBy = (by: "id"): void => {
    let sort: typeof filter.sort = filter.sort

    if (by === "id") {
      sort = filter.sort === "id_asc" ? "id_desc" : "id_asc"
    }

    setFilterValue("sort", sort)
    setPage(0)
  }

  const hasTickets =
    responseAllTickets?.tickets && responseAllTickets.tickets.length > 0
  const pagedTickets = responseAllTickets?.tickets.slice(
    page * PAGE_SIZE,
    (page + 1) * PAGE_SIZE,
  )

  return (
    <React.Fragment>
      <div className="container">
        <PageHeader2>Отчёт по работе УК в МП</PageHeader2>

        <p style={{ fontSize: "16px", lineHeight: "24px", marginTop: "32px" }}>
          В отчёт попадают заявки со статусом "Завершена" и при условии, что
          житель
          <br />
          поставил заявке оценку. Другие заявки в отчет не попадают и данные
          <br />
          по ним в расчёт не принимаются.
        </p>
        <br />

        <div
          style={{
            borderTop: "1px solid rgba(0,0,0,0.1)",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            padding: "32px 0 24px",
            marginBottom: "8px",
          }}
        >
          <HTMLTable condensed={true}>
            <tbody className="valign-middle">
              <tr>
                <td
                  style={{
                    paddingLeft: 0,
                    boxShadow: "none",
                    fontSize: "18px",
                    fontWeight: 500,
                    width: "232px",
                    paddingTop: "0",
                  }}
                >
                  <div>Выберите период</div>
                  <SelectDateRangeHint />
                </td>
                <td style={{ width: "400px", boxShadow: "none" }}>
                  <SelectDateRange
                    value={selectedDateRange}
                    onApply={([from, till]) => {
                      setSelectedDateRange([from, till])
                      if (from === null && till !== null) {
                        setSelectedDateRange([till, till])
                      }
                      if (till === null && from !== null) {
                        setSelectedDateRange([from, from])
                      }
                      setPage(0)
                    }}
                    onReset={() => {
                      setSelectedDateRange(DEFAULT_RANGE)
                      setPage(0)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingLeft: 0,
                    paddingTop: 0,
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Выберите вид заявки
                </td>
                <td>
                  <FilterSelect2
                    placeholder="Все"
                    value={selectedType}
                    values={(ticketTypes ?? []).map((ticketType) => ({
                      value: `${ticketType.id}`,
                      label: ticketType.name,
                    }))}
                    onChange={(value) => {
                      setSelectedType(value)
                      setFilter(FILTER_DEFAULT)
                      setPage(0)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td
                    style={{
                      paddingLeft: 0,
                      paddingTop: 0,
                      fontSize: "18px",
                      fontWeight: 500,
                    }}
                >
                  Выберите менеджера
                </td>
                <td>
                  <FilterSelect2
                      placeholder="Все"
                      value={selectedManager}
                      values={(allManagers ?? []).map((manager) => ({
                        value: `${manager.id}`,
                        label: manager.first_name +" "+ manager.last_name,
                      }))}
                      onChange={(value) => {
                        setSelectedManager(value)
                        setFilter(FILTER_DEFAULT)
                        setPage(0)
                      }}
                  />
                </td>
              </tr>
              <tr>
                <td
                    style={{
                      paddingLeft: 0,
                      paddingTop: 0,
                      fontSize: "18px",
                      fontWeight: 500,
                    }}
                >
                  Выберите ЖК
                </td>
                <td>
                  <FilterSelect2
                      placeholder="Все"
                      value={selectedComplex}
                      values={(allComplexes ?? []).map((complex) => ({
                        value: `${complex.id}`,
                        label: complex.title,
                      }))}
                      onChange={(value) => {
                        setSelectedComplex(value)
                        setFilter(FILTER_DEFAULT)
                        setPage(0)
                      }}
                  />
                </td>
              </tr>
            </tbody>
          </HTMLTable>
        </div>
        <br />

        {responseReport?.report.total === 0 && (
          <div style={{ fontSize: "18px", textAlign: "center" }}>
            Заявок не найдено. Попробуйте изменить параметры поиска
          </div>
        )}
      </div>

      <div className="container">
        <TicketReportSummary report={responseReport?.report} />
      </div>

      <div className="container-fluid">
        <Card
          style={{
            padding: 0,
            visibility:
              responseReport?.report.total === 0 ? "hidden" : "initial",
          }}
        >
          <div
            style={{
              display: "flex",
              columnGap: "30px",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              padding: "16px 20px",
              backgroundColor: "#EBF9FF",
            }}
          >
            <span>
              <span style={{ opacity: 0.75 }}>Найдено:</span>{" "}
              <span className={!responseAllTickets ? Classes.SKELETON : ""}>
                {responseAllTickets?.tickets.length} заявок
              </span>
            </span>
            <span>
              <span style={{ opacity: 0.75 }}>За период:</span>{" "}
              {selectedDateRangeLabel}
            </span>
            <span>
              <span style={{ opacity: 0.75 }}>Вид заявки:</span>{" "}
              {selectedTypeLabel}
            </span>
          </div>
          <HTMLTable
            bordered={true}
            condensed={true}
            style={{ width: "100%", minWidth: "1600px" }}
          >
            <thead
              className="valign-middle"
              style={{
                backgroundColor: "white",
              }}
            >
              <tr>
                <th style={{ width: "100px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <small>
                      Номер
                      <br />
                      заявки
                    </small>
                    <div style={{ marginLeft: "auto" }}>
                      <Button
                        active={sortById}
                        disabled={loadingTickets}
                        icon={sortIconById}
                        minimal={true}
                        small={true}
                        onClick={() => sortBy("id")}
                      />
                    </div>
                  </div>
                </th>
                <th style={{ width: "100px" }}>
                  <small>Дата завершения</small>
                </th>
                <th style={{ width: "75px" }}>
                  <small>Оценка</small>
                </th>
                <th style={{ width: "175px" }}>
                  <small>Менеджер</small>
                </th>
                <th style={{ width: "175px" }}>
                  <small>Исполнитель</small>
                </th>
                <th style={{ width: "225px" }}>
                  <small>Адрес</small>
                </th>
                <th style={{ width: "175px" }}>
                  <small>ФИО жителя</small>
                </th>
                <th style={{ width: "auto" }}>
                  <small>Текст заявки</small>
                </th>
                <th style={{ width: "95px" }}>
                  <small>&nbsp;</small>
                </th>
              </tr>
            </thead>
            <thead
              className="valign-middle"
              style={{
                backgroundColor: "white",
                boxShadow: "rgb(0 0 0 / 25%) 0 2px 4px 0",
              }}
            >
              <tr className="toolbar">
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>
                  <FilterSelect
                    disabled={loadingTickets}
                    placeholder={"Все"}
                    value={filter.rating}
                    values={Array.from({ length: 5 })
                      .fill(null)
                      .map((_, k) => ({
                        value: `${k + 1}`,
                        label: `${k + 1}`,
                      }))}
                    onChange={(value) => {
                      setFilterValue("rating", value)
                    }}
                  />
                </th>
                <th>
                  <FilterInput
                    disabled={loadingTickets}
                    placeholder="Искать по менеджеру"
                    value={filter.manager}
                    onApply={(value) => {
                      setFilterValue("manager", value)
                    }}
                  />
                </th>
                <th>
                  <FilterInput
                    disabled={loadingTickets}
                    placeholder="Искать по исполнителю"
                    value={filter.contractor}
                    onApply={(value) => {
                      setFilterValue("contractor", value)
                    }}
                  />
                </th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="valign-middle">
              {pagedTickets?.map((ticket) => (
                <tr key={ticket.id}>
                  <td>{ticket.id}</td>
                  <td>
                    {ticket.finished_at
                      ? format(parseISO(ticket.finished_at), "dd.MM.yyyy")
                      : ""}
                  </td>
                  <td>{ticket.rating}</td>
                  <td>{ticket.manager}</td>
                  <td>{ticket.contractors?.join(",")}</td>
                  <td>{ticket.address}</td>
                  <td>{ticket.tenant}</td>
                  <td>{ticket.text}</td>
                  <td>
                    <a
                      href={`/tickets/${ticket.id}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Переписка
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {loadingTickets && (
              <tbody className="valign-middle" style={{ height: "48px" }}>
                <tr>
                  <td colSpan={8} style={{ textAlign: "center", opacity: 0.5 }}>
                    Загрузка...
                  </td>
                </tr>
              </tbody>
            )}
            {!loadingTickets &&
              responseAllTickets?.tickets &&
              responseAllTickets.tickets.length === 0 && (
                <tbody className="valign-middle" style={{ height: "48px" }}>
                  <tr>
                    <td
                      colSpan={8}
                      style={{ textAlign: "center", opacity: 0.5 }}
                    >
                      Нет данных
                    </td>
                  </tr>
                </tbody>
              )}
          </HTMLTable>
        </Card>
        {hasTickets && (
          <Pagination
            current={page}
            pageSize={PAGE_SIZE}
            total={responseAllTickets?.tickets?.length ?? 0}
            onClick={setPage}
          />
        )}
      </div>
    </React.Fragment>
  )
}

const SelectDateRangeHint: React.FC = () => {
  return (
    <Tooltip2
      content={
        <React.Fragment>
          <p>
            Заявки фильтруются по дате присвоения статуса "Завершена".
            <br />
            <br />
            Пример:
            <br />1 мая заявку создали
            <br />
            15 мая присвоили "Работы завершены"
            <br />
            16 мая пользователь поставил оценку
            <br />
            18 мая присвоился статус "Завершена"
            <br />
            <br />
            Если вы выберете период отчета:
            <br />
            2-15 мая — заявка не попадет в отчёт
            <br />
            2-17 мая — заявка не попадет в отчёт
            <br />
            2-18 мая — заявка попадет в отчёт
            <br />
            <br />
            Все средние значения считаются по показателям заявок,
            <br />
            попавших в выбранный период. Если заявка не попала
            <br />в отчёт — её данные в расчёте не учитываются.
          </p>
        </React.Fragment>
      }
      position={"bottom-left"}
    >
      <a
        href="#"
        style={{ color: "#3AC4FF", fontWeight: 500, fontSize: "14px" }}
        onClick={() => false}
      >
        Как работает выбор периода?
      </a>
    </Tooltip2>
  )
}
