import { Button, Checkbox, Classes } from "@blueprintjs/core"
import { CheckPhoneRole } from "components/form/CheckPhoneRole"
import { InputTextField } from "components/form/Input"
import { InputPhoneField } from "components/form/InputPhone"
import { PageLoading } from "components/layout/PageLoading"
import { Field, FieldProps, Formik, FormikHelpers } from "formik"
import { clearPhone } from "helpers/common"
import { useApiUpdate } from "lib/api/api"
import { useGetEmployeesManagers } from "lib/api/data"
import {
  ApiEmployeesUpdateRequest,
  ApiEmployeesUpdateResponse,
} from "lib/api/types/ApiEmployeesResponse"
import * as React from "react"
import * as Yup from "yup"

type ManagerUpdateFormType = {
  phone: string
  first_name: string
  last_name: string
  patronymic: string
  is_blocked: boolean
}

const ManagerUpdateSchema = Yup.object().shape({
  phone: Yup.string()
    .transform(clearPhone)
    .trim()
    .min(11, "Должно быть 11 цифр.")
    .required("Обязательное поле."),
  first_name: Yup.string().trim().required("Обязательное поле."),
  last_name: Yup.string().trim().required("Обязательное поле."),
})

type ManageUpdateProps = {
  id: number
  onSubmit: () => void
}

export const ManagerUpdate: React.FC<ManageUpdateProps> = ({
  id,
  onSubmit,
}) => {
  const [loading, managers] = useGetEmployeesManagers()

  const manager = managers?.find((e) => `${e.id}` === `${id}`)

  const [updateManager] = useApiUpdate<
    ApiEmployeesUpdateRequest,
    ApiEmployeesUpdateResponse
  >(`/employees/${manager?.id}`, {} as never)

  if (loading) {
    return <PageLoading />
  }

  if (!manager) {
    return null
  }

  const initialValues: ManagerUpdateFormType = {
    phone: `${manager.phone}`,
    first_name: manager.first_name,
    last_name: manager.last_name,
    patronymic: manager.patronymic ?? "",
    is_blocked: manager.is_blocked,
  }

  const onFormikSubmit = async (
    values: ManagerUpdateFormType,
    helpers: FormikHelpers<ManagerUpdateFormType>,
  ): Promise<void> => {
    helpers.setStatus("")

    try {
      const { data } = await updateManager({
        id,
        phone: clearPhone(values.phone),
        first_name: values.first_name,
        last_name: values.last_name,
        patronymic: values.patronymic,
        responsibility_zone: null,
        is_blocked: values.is_blocked,
      })
      if (!data.errors) {
        onSubmit()
        return
      }

      helpers.setErrors(data.errors)
      console.warn(data.errors)
    } catch {
      helpers.setStatus("Неизвестная ошибка")
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Formik<ManagerUpdateFormType>
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={ManagerUpdateSchema}
      onSubmit={onFormikSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting, values, status }) => (
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <InputPhoneField label="Номер телефона" name="phone" />
            <CheckPhoneRole
              id={manager.id}
              phone={clearPhone(values.phone)}
              role={"employee"}
            />

            <InputTextField label="Фамилия" name="last_name" />
            <InputTextField label="Имя" name="first_name" />
            <InputTextField label="Отчество" name="patronymic" />

            <Field name="is_blocked">
              {({ field }: FieldProps<ManagerUpdateFormType["is_blocked"]>) => (
                <Checkbox
                  checked={field.value}
                  label="Заблокировать"
                  name={field.name}
                  onChange={field.onChange}
                />
              )}
            </Field>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                disabled={!isValid || isSubmitting || !!status}
                intent="success"
                loading={isSubmitting}
                text={"Сохранить"}
                type="submit"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
