import {Button, Callout, Classes, FileInput, ProgressBar} from "@blueprintjs/core"
import {InputTextField} from "components/form/Input"
import {Formik, FormikHelpers, Field} from "formik"
import {useApiCreate} from "lib/api/api"

import * as React from "react"
import * as Yup from "yup"

import {categoryOptions, SelectCategories} from "../../components/form/SelectCategories";
import {useGetCategories, useGetServices} from "../../lib/api/data";
import {TextArea} from "../../components/form/TextArea";
import {ApiPostServiceCreateRequest, ApiPostServiceCreateRespond} from "../../lib/api/types/ApiServiceResponse";

type CategoryCreateFormType = {
    name: string
    image: File | null
    image_logo: File | null
    slug: string
    description: string
    text: string
    category: string | Blob,
    offer: string,
    url: string
}


type CategoryCreateProps = {
    onSubmit: () => void
}

export const ServiceCreate: React.FC<CategoryCreateProps> = ({onSubmit}) => {
    const [loadingCategories, categories, refetch] = useGetCategories()
    const [servicesLoading, servicesLoad, serviceRefetch] = useGetServices()
    if (categories === undefined) {
        return (
            <>
                Сначала создайте категорию
            </>
        )
    }
    const categoryOption = categories.map((c) => {
        return {
            id: c.id,
            name: c.name
        }
    })


    const ManagerCreateSchema = Yup.object().shape({
        name: Yup.string().trim().required("Обязательное поле."),
        slug: Yup.string().trim().max(15, "Ограничение 15 символов").required("Обязательное поле."),
        description: Yup.string().trim().required("Обязательное поле."),
        text: Yup.string().trim().required("Обязательное поле."),
        url: Yup.string().trim().required("Обязательное поле."),
        offer: Yup.string().trim().required("Обязательное поле."),
        category: Yup.string().trim().required("Обязательное поле."),
    })
    //файлы
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
    const [uploadedError, setUploadedError] = React.useState<boolean>(false)
    const [logoImage, setLogoImage] = React.useState<File | null>(null)
    const [uploadedLogoError, setUploadedLogoError] = React.useState<boolean>(false)
    const [checked, setChecked] = React.useState(true);
    const [isUploading, setUploading] = React.useState<boolean>(false)

    //ошибки
    const [slugError, setSlugError] = React.useState<string | null>(null)
    const [formError, setFormError] = React.useState<string | null>(null)


    const initialValues: CategoryCreateFormType = {
        name: "",
        slug: "",
        description: "",
        image: null,
        image_logo: null,
        category: "",
        offer: "",
        text: "",
        url: "",
    }
    const [create, loading, data] = useApiCreate<ApiPostServiceCreateRequest,
        ApiPostServiceCreateRespond >(`/catalog-services`, {} as never)

    const onFormikSubmit = async (
        values: CategoryCreateFormType,
        helpers: FormikHelpers<CategoryCreateFormType>,
    ): Promise<void> => {
        if (selectedFile === null) {
            setUploadedError(false)
            return
        }

        const slugCopy = servicesLoad?.find((e) => {
            return `${e.slug}` === `${values.slug}`
        })
        if (slugCopy) {
            setSlugError("Slug должен быть уникальным")
            return
        }

        const form = new FormData()
        form.append("name", values.name)
        form.append("image", selectedFile)
        form.append("image_logo", logoImage ? logoImage : '')
        form.append("description", values.description)
        form.append("slug", values.slug)
        form.append("category_id", values.category)
        form.append("offer", values.offer)
        form.append("text", values.text)
        form.append("url", values.url)
        form.append("publish", checked ? "1" : "0")

        await create(form).then((res) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (res.data.is_ok) {
                setSelectedFile(null)
                setLogoImage(null)
                setSlugError(null)
                setFormError(null)
                onSubmit()
                return
            }
        })
            .catch((error) => {
                setFormError("Что-то не так повторите попытку")
                setSelectedFile(null)
                setLogoImage(null)
                //setUploadedError(true)
            })
            .finally(() => {
                setUploading(false)
            })
    }

    return (
        <Formik<CategoryCreateFormType>
            initialValues={initialValues}
            validateOnMount={true}
            validationSchema={ManagerCreateSchema}
            onSubmit={onFormikSubmit}
        >
            {({handleSubmit, isValid, isSubmitting, values, status, handleChange}) => (
                <form onSubmit={handleSubmit}>
                    <div className={Classes.DIALOG_BODY}>
                        {(formError) &&
                        <Callout intent="warning">
                            {formError}
                        </Callout>}
                        <InputTextField label="Название" name="name" />
                        {(slugError) &&
                        <Callout intent="warning">
                            {slugError}
                        </Callout>}
                        <InputTextField label="Slug (без пробелов, 15 символов, на анг, для событий. Пример: 'santehnika_7')" name="slug"/>
                        <InputTextField label="Короткое описание (20 символов)" name="description" />
                        <SelectCategories options={categoryOption} name="category" />
                        <InputTextField label="Предложение пример: 'Скидка 8%'" name="offer"/>
                        <TextArea label={"Описание услуги"} name="text"/>

                        <InputTextField label="Ссылка на сайт 'https://google.com'" name="url" noLimit={true}/>
                        <label>
                            <input type="checkbox" style={{marginRight: "5px"}}
                                   defaultChecked={checked}
                                   onChange={() => setChecked(!checked)}
                            />
                            <span >Публикация</span>
                        </label>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <div className="container">
                                <span>Основное изображение, формат jpg 500x280 </span>
                                <FileInput
                                    buttonText="Выбрать"
                                    fill={true}
                                    inputProps={{accept: ".jpg"}}
                                    text={selectedFile?.name ?? "Основное изображение .jpg"}
                                    onInputChange={(e) => {
                                        setSelectedFile(e.currentTarget.files?.item(0) ?? null)
                                        setUploadedError(false)
                                    }}
                                />
                                {uploadedError && (
                                    <Callout intent="warning">
                                        Файл невалиден, импорт невозможен.
                                    </Callout>
                                )}

                            </div>
                        </div>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <div className="container">
                                <span>Логотип изображение, формат png</span>
                                <FileInput
                                    buttonText="Выбрать"
                                    fill={true}
                                    inputProps={{accept: ".png"}}
                                    text={logoImage?.name ?? "Логотип компании .png"}
                                    onInputChange={(e) => {
                                        setLogoImage(e.currentTarget.files?.item(0) ?? null)
                                        setUploadedLogoError(false)
                                    }}
                                />
                                {uploadedLogoError && (
                                    <Callout intent="warning">
                                        Файл невалиден, импорт невозможен.
                                    </Callout>
                                )}

                                <br/>
                                <br/>
                                <Button
                                    disabled={!isValid || isSubmitting || !!status  || !selectedFile }
                                    intent="success"
                                    loading={isSubmitting}
                                    text={"Добавить"}
                                    type="submit"
                                />
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}
