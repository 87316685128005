import { Button, Classes, Label } from "@blueprintjs/core"
import { CheckPhoneRole } from "components/form/CheckPhoneRole"
import { InputTextField } from "components/form/Input"
import { InputPhoneField } from "components/form/InputPhone"
import { SelectOption } from "components/form/Select"
import { AddressMultiSelect, AddressValue } from "components/form/SelectAddress"
import { Formik, FormikHelpers } from "formik"
import { clearPhone } from "helpers/common"
import { useApiCreate } from "lib/api/api"
import { postTenantAssign } from "lib/api/data"
import {
  ApiTenantCreateRequest,
  ApiTenantCreateResponse,
} from "lib/api/types/ApiTenantsResponse"
import * as React from "react"
import * as Yup from "yup"

type TenantsCreateFormType = {
  phone: string
  first_name: string
  last_name: string
  patronymic: string
}

const TenantsCreateSchema = Yup.object().shape({
  phone: Yup.string()
    .transform(clearPhone)
    .trim()
    .min(11, "Должно быть 11 цифр.")
    .required("Обязательное поле."),
})

type TenantsCreateProps = {
  values?: {
    phone?: string
    address?: {
      complex?: SelectOption
      house?: SelectOption
      apartment?: SelectOption
    }
  }
  onSubmit: () => void
}

export const TenantsCreate: React.FC<TenantsCreateProps> = ({
  onSubmit,
  values,
}) => {
  const [activeAddress, setActiveAddress] = React.useState<Array<AddressValue>>(
    values?.address ? [values.address] : [],
  )

  const [createTenant] = useApiCreate<
    ApiTenantCreateRequest,
    ApiTenantCreateResponse
  >(`/tenants`, {} as never)

  const initialValues: TenantsCreateFormType = {
    phone: values?.phone ?? "",
    first_name: "",
    last_name: "",
    patronymic: "",
  }

  const onFormikSubmit = async (
    values: TenantsCreateFormType,
    helpers: FormikHelpers<TenantsCreateFormType>,
  ): Promise<void> => {
    helpers.setStatus("")

    try {
      const { data } = await createTenant({
        phone: clearPhone(values.phone),
        first_name: values.first_name,
        last_name: values.last_name,
        patronymic: values.patronymic,
      })

      for (const address of activeAddress) {
        if (address?.house?.id && address?.apartment?.id) {
          await postTenantAssign(
            data.data.id,
            address.house.id,
            address.apartment.title,
          )
        }
      }

      if (!data.errors) {
        onSubmit()
        return
      }

      helpers.setErrors(data.errors)
      console.warn(data.errors)
    } catch {
      helpers.setStatus("Неизвестная ошибка")
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Formik<TenantsCreateFormType>
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={TenantsCreateSchema}
      onSubmit={onFormikSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting, values, status }) => (
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <InputPhoneField label="Номер телефона" name="phone" />
            <CheckPhoneRole phone={clearPhone(values.phone)} role={"tenant"} />

            <InputTextField label="Фамилия" name="last_name" />
            <InputTextField label="Имя" name="first_name" />
            <InputTextField label="Отчество" name="patronymic" />

            <Label style={{ marginBottom: "5px" }}>
              Укажите один или несколько адресов:
            </Label>
            <AddressMultiSelect
              value={activeAddress}
              onChange={setActiveAddress}
            />
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                disabled={!isValid || isSubmitting || !!status}
                intent="success"
                loading={isSubmitting}
                text={"Сохранить"}
                type="submit"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
