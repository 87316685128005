import { Button, InputGroup } from "@blueprintjs/core"
import { useInputState } from "helpers/useInputState"
import * as React from "react"

import { SearchByFullNameResult } from "./SearchByFullNameResult"

type SearchByFullNameProps = {
  onResult: (result: boolean) => void
}

export const SearchByFullName: React.FC<SearchByFullNameProps> = ({
  onResult,
}) => {
  const [fullName, setFullName, resetFullName] = useInputState()
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined,
  )
  const isSearchDisabled = fullName.trim().length < 2

  const onApplySearch = (): void => {
    setSearchQuery(fullName)

    onResult(true)
  }

  const onReset = (): void => {
    setSearchQuery(undefined)
    resetFullName()
  }

  return (
    <React.Fragment>
      <div className="grid grid--col-12 grid--form">
        <div className="col-3">
          <InputGroup
            autoFocus={true}
            fill={false}
            min={1}
            placeholder="ФИО"
            type="text"
            value={fullName}
            onChange={setFullName}
            onKeyPress={(e) => {
              if (e.key === "Enter") onApplySearch()
            }}
          />
        </div>
        <div className="col-2">
          <Button
            disabled={isSearchDisabled}
            fill={true}
            icon="search-template"
            intent="primary"
            onClick={onApplySearch}
          >
            Найти
          </Button>
        </div>
      </div>
      <div>
        <br />
        {searchQuery && (
          <SearchByFullNameResult query={searchQuery} onReset={onReset} />
        )}
      </div>
    </React.Fragment>
  )
}
