import { Classes, H2, ProgressBar } from "@blueprintjs/core"
import * as React from "react"

export const PageLoading: React.FC = ({ children }) => {
  return (
    <div className="container">
      <br />
      <H2 className={Classes.SKELETON}>Загрузка...</H2>
      <br />
      <ProgressBar />
      {children}
    </div>
  )
}
