import { Button } from "@blueprintjs/core"
import { CardTable } from "components/layout/CardTable"
import { PageHeader } from "components/layout/PageHeader"
import { PageLoading } from "components/layout/PageLoading"
import { useGetEmployeesContractorsExt } from "lib/api/data"
import * as React from "react"
import {getRole} from "../../lib/api/api";

export const ContractorsExt: React.FC = () => {
  const [loading, contractors] = useGetEmployeesContractorsExt()

  if (loading) {
    return <PageLoading />
  }

  const role = getRole()
  return (
    <React.Fragment>
      <div className="container">
        <PageHeader>Управление подрядчиками</PageHeader>

        <CardTable>
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Наименование</th>
              <th />
              <th style={{ width: "40px", padding: "8px" }} />
              {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
            </tr>
          </thead>
          <tbody className="valign-middle">
            {contractors?.map((contractor) => (
              <tr key={contractor.id}>
                <td>
                  {contractor.last_name} {contractor.first_name}{" "}
                  {contractor.patronymic}
                </td>
                <td />
                <td>
                  <Button
                    disabled={true}
                    icon={contractor.is_blocked ? "blank" : "tick"}
                    intent={"success"}
                    minimal={true}
                    small={true}
                    style={{ cursor: "inherit" }}
                  />
                </td>
                {role === "admin" && <td>
                  <Button
                    disabled={true}
                    icon="edit"
                    minimal={true}
                    small={true}
                    type="button"
                  />
                </td>}
              </tr>
            ))}
          </tbody>
        </CardTable>
      </div>
    </React.Fragment>
  )
}
