import { Button } from "@blueprintjs/core"
import { SelectOption, SelectSimple } from "components/form/Select"
import {
  useGetComplexes,
  useGetComplexHouseApartments,
  useGetComplexHouses,
} from "lib/api/data"
import * as React from "react"

export type AddressValue = {
  complex?: SelectOption
  house?: SelectOption
  apartment?: SelectOption
}

type AddressSelectProps = {
  value?: AddressValue
  onChange: (value: AddressValue) => void
}

export const AddressSelect: React.FC<AddressSelectProps> = ({
  value,
  onChange,
}) => {
  const [loadingComplexes, complexes] = useGetComplexes()
  const [loadingHouses, houses] = useGetComplexHouses(
    value?.complex?.id ?? null,
  )
  const [loadingApartments, apartments] = useGetComplexHouseApartments(
    value?.house?.id ?? null,
  )

  const itemsComplexes = complexes?.map((c) => ({
    id: c.id,
    title: c.title,
  }))

  const itemsHouses = houses?.map((h) => ({
    id: h.id,
    title: h.address,
  }))

  const itemsApartments = apartments?.map((a) => ({
    id: a.id,
    title: a.number,
  }))
  itemsApartments?.sort((a1, a2) => {
    return a1.title.localeCompare(a2.title, "ru", { numeric: true })
  })

  const onComplexSelect = (complex: SelectOption): void => {
    if (complex.id === value?.complex?.id) {
      return
    }
    onChange({ ...value, complex, house: undefined, apartment: undefined })
  }

  const onHouseSelect = (house: SelectOption): void => {
    if (house.id === value?.house?.id) {
      return
    }

    onChange({ ...value, house, apartment: undefined })
  }

  const onApartmentSelect = (apartment: SelectOption): void => {
    if (apartment.id === value?.apartment?.id) {
      return
    }

    onChange({ ...value, apartment })
  }

  return (
    <React.Fragment>
      <SelectSimple
        activeItem={value?.complex}
        activeLabel={value?.complex?.title}
        defaultLabel="Выберите ЖК"
        disabled={loadingComplexes}
        items={itemsComplexes}
        loading={loadingComplexes}
        onItemSelect={onComplexSelect}
      />

      <SelectSimple
        activeItem={value?.house}
        activeLabel={value?.house?.title}
        defaultLabel="Выберите дом"
        disabled={loadingHouses}
        items={itemsHouses}
        loading={loadingHouses}
        onItemSelect={onHouseSelect}
      />

      <SelectSimple
        activeItem={value?.apartment}
        activeLabel={value?.apartment?.title}
        defaultLabel="Выберите квартиру"
        disabled={loadingApartments}
        items={itemsApartments}
        loading={loadingApartments}
        onItemSelect={onApartmentSelect}
      />
    </React.Fragment>
  )
}

type AddressMultiSelectProps = {
  value: AddressValue[]
  onChange: (value: AddressValue[]) => void
}

export const AddressMultiSelect: React.FC<AddressMultiSelectProps> = ({
  value,
  onChange,
}) => {
  const onAddressChange = (k: number) => (address: AddressValue) => {
    onChange(value.map((v, i) => (i !== k ? v : address)))
  }

  const onAddressCreate = () => {
    onChange([...value, {}])
  }

  const onAddressRemove = (k: number) => () => {
    onChange(value.filter((_, i) => i !== k))
  }

  return (
    <React.Fragment>
      {value.map((address, k) => (
        <div
          key={k}
          className="grid grid--col-12 grid--form"
          style={{ marginBottom: "10px" }}
        >
          <div className="col-11">
            <AddressSelect value={address} onChange={onAddressChange(k)} />
          </div>
          <div className="col-1">
            <Button
              icon="trash"
              intent="warning"
              minimal={true}
              onClick={onAddressRemove(k)}
            />
          </div>
        </div>
      ))}
      <Button
        icon="plus"
        intent="primary"
        minimal={true}
        onClick={onAddressCreate}
      />
    </React.Fragment>
  )
}
