import { Button, Callout, Dialog, H3, ProgressBar } from "@blueprintjs/core"
import { CardTable } from "components/layout/CardTable"
import { writePhone } from "helpers/common"
import { useGetTenantsByPhone } from "lib/api/data"
import * as React from "react"

import { TenantsCreate } from "../TenantsCreate"
import { TenantsUpdate } from "../TenantsUpdate"
import {getRole} from "../../../lib/api/api";

type SearchByPhoneResultProps = {
  query: string
  onReset: () => void
}

export const SearchByPhoneResult: React.FC<SearchByPhoneResultProps> = ({
  query,
  onReset,
}) => {
  const [loading, tenant, refetch] = useGetTenantsByPhone(query)

  const [showCreateForm, setShowCreateForm] = React.useState<boolean>(false)
  const [showUpdateForm, setShowUpdateForm] = React.useState<number>(0)

  if (loading) {
    return <ProgressBar />
  }

  const role = getRole()
  const hasResult = !!tenant?.id

  return (
    <React.Fragment>
      <br />
      {!loading && (
        <div style={{ display: "none" }}>
          <Button minimal={true} outlined={true} small={true} onClick={onReset}>
            Очистить поиск
          </Button>
          <br />
          <br />
        </div>
      )}

      <H3>Вы искали: {writePhone(query)}</H3>

      {!hasResult && (
        <Callout icon={null} intent="warning">
          жителя с таким номером телефона не найдено.
        </Callout>
      )}

      {hasResult && tenant && (
        <React.Fragment>
          <CardTable>
            <thead>
              <tr>
                <th style={{ width: "33%" }}>
                  <small>ФИО</small>
                </th>
                <th style={{ width: "15%" }}>
                  <small>Телефон</small>
                </th>
                <th>
                  <small>Адрес</small>
                </th>
                <th style={{ width: "10%" }}>
                  <small>Лицевой счёт</small>
                </th>
                {role === "admin" && <th style={{ width: "40px", padding: "8px" }} />}
              </tr>
            </thead>

            <tbody className="valign-middle">
              <tr>
                <td>
                  {tenant.last_name} {tenant.first_name} {tenant.patronymic}
                </td>
                <td>{writePhone(tenant.phone)}</td>
                <td>
                  {tenant.apartments.map(({ id, number, house }) => (
                    <div key={id}>
                      {house.complex.title}, ул. {house.address}, кв. {number}
                    </div>
                  ))}
                </td>
                <td>
                  {tenant.apartments.map(({ id, lsErcList }) => (
                    <div key={id}>{lsErcList.map((ls) => ls.number)}</div>
                  ))}
                </td>
                {role === "admin" && <td>
                  <Button
                    icon="edit"
                    minimal={true}
                    type="button"
                    onClick={() => setShowUpdateForm(tenant.phone)}
                  />
                </td>}
              </tr>
            </tbody>
          </CardTable>
        </React.Fragment>
      )}

      <br />
      {!hasResult && role === "admin" &&  (
        <Button
          icon="plus"
          intent="success"
          text={"Добавить жителя с таким номером телефона"}
          type="button"
          onClick={() => setShowCreateForm(true)}
        />
      )}

      {showCreateForm && (
        <Dialog
          isOpen={true}
          title="Добавить жителя"
          onClose={() => setShowCreateForm(false)}
        >
          <TenantsCreate
            values={{ phone: query }}
            onSubmit={() => {
              setShowCreateForm(false)
              refetch()
            }}
          />
        </Dialog>
      )}

      {showUpdateForm !== 0 && (
        <Dialog
          isOpen={true}
          title="Редактировать жителя"
          onClose={() => setShowUpdateForm(0)}
        >
          <TenantsUpdate
            phone={showUpdateForm}
            onSubmit={() => {
              setShowUpdateForm(0)
              console.info("TenantsUpdate", "done")
              refetch()
            }}
          />
        </Dialog>
      )}
    </React.Fragment>
  )
}
