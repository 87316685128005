import { Button, Dialog, H6, Label } from "@blueprintjs/core"
import { SelectOption, SelectSimple } from "components/form/Select"
import { PageHeader } from "components/layout/PageHeader"
import * as React from "react"

import { SearchByAddress } from "./components/SearchByAddress"
import { SearchByFullName } from "./components/SearchByFullName"
import { SearchByLsNumber } from "./components/SearchByLsNumber"
import { SearchByPhone } from "./components/SearchByPhone"
import { TenantsCreate } from "./TenantsCreate"
import {getRole} from "../../lib/api/api";

const SEARCH_TYPE_ADDRESS = 1
const SEARCH_TYPE_FULLNAME = 2
const SEARCH_TYPE_PHONE = 3
const SEARCH_TYPE_LS = 4

const SEARCH_TYPES: SelectOption[] = [
  { id: SEARCH_TYPE_ADDRESS, title: "по адресу" },
  { id: SEARCH_TYPE_FULLNAME, title: "по ФИО" },
  { id: SEARCH_TYPE_PHONE, title: "по номеру телефона" },
  { id: SEARCH_TYPE_LS, title: "по лицевому счету" },
]

const ACTIVE_SEARCH_TYPE__STORAGE_KEY = "Tenants.activeSearchType"

export const Tenants: React.FC = () => {
  const role = getRole();
  let initialSearchType = SEARCH_TYPES[0]
  try {
    const savedSearchType = JSON.parse(
      localStorage.getItem(ACTIVE_SEARCH_TYPE__STORAGE_KEY) ?? "{}",
    ) as SelectOption
    if (
      savedSearchType.id &&
      SEARCH_TYPES.findIndex((t) => t.id === savedSearchType.id) !== -1
    ) {
      initialSearchType = savedSearchType
    }
  } catch {
    //
  }

  const [activeSearchType, setActiveSearchType] = React.useState<
    SelectOption | undefined
  >(initialSearchType)

  const [hasResult, setResult] = React.useState(false)

  const [isShowCreateForm, setShowCreateForm] = React.useState<boolean>(false)
  const showCreateForm = () => setShowCreateForm(true)
  const hideCreateForm = () => setShowCreateForm(false)

  const onSearchTypeSelect = (item: SelectOption): void => {
    if (item.id !== activeSearchType?.id) {
      setResult(false)
    }

    localStorage.setItem(ACTIVE_SEARCH_TYPE__STORAGE_KEY, JSON.stringify(item))
    setActiveSearchType(SEARCH_TYPES.find((t) => t.id === item.id))
  }

  const defaultSearchLabel = SEARCH_TYPES.find(
    (t) => t.id === activeSearchType?.id,
  )?.title

  return (
    <div className="container">
      <PageHeader>Управление жителями</PageHeader>

      <div className="grid grid--col-12 grid--form">
        <div className="col-3">
          <Label>
            <H6>Выберите тип поиска:</H6>
            <SelectSimple
              activeItem={activeSearchType}
              defaultLabel={defaultSearchLabel}
              items={SEARCH_TYPES}
              onItemSelect={onSearchTypeSelect}
            />
          </Label>
        </div>
      </div>
      <div>
        <H6>Введите данные для поиска:</H6>
        {activeSearchType?.id === SEARCH_TYPE_ADDRESS && (
          <SearchByAddress onResult={setResult} />
        )}
        {activeSearchType?.id === SEARCH_TYPE_FULLNAME && (
          <SearchByFullName onResult={setResult} />
        )}
        {activeSearchType?.id === SEARCH_TYPE_PHONE && (
          <SearchByPhone onResult={setResult} />
        )}
        {activeSearchType?.id === SEARCH_TYPE_LS && (
          <SearchByLsNumber onResult={setResult} />
        )}
      </div>

      {!hasResult && role === "admin" && (
        <Button
          icon="plus"
          intent="success"
          text="Добавить жителя"
          type="button"
          onClick={showCreateForm}
        />
      )}

      {isShowCreateForm && (
        <Dialog isOpen={true} title="Добавить жителя" onClose={hideCreateForm}>
          <TenantsCreate onSubmit={hideCreateForm} />
        </Dialog>
      )}
    </div>
  )
}
