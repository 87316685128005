import { Button, HTMLTable } from "@blueprintjs/core"
import { AddressValue } from "components/form/SelectAddress"
import { PageHeader } from "components/layout/PageHeader"
import { format, parseISO } from "date-fns"
import { saveAs } from "file-saver"
import { api } from "lib/api/api"
import {
  useGetTickets,
  useGetTicketStatuses,
  useGetTicketTypes,
} from "lib/api/data"
import * as React from "react"

import { FilterAddress } from "./components/FilterAddress"
import { FilterDateRange } from "./components/FilterDate"
import { FilterInput } from "./components/FilterInput"
import { FilterSelect } from "./components/FilterSelect"
import { Pagination } from "./components/Pagination"

type Filter = {
  ticket_id: string
  created_at_from: Date | null
  created_at_till: Date | null
  finished_at_from: Date | null
  finished_at_till: Date | null
  ticket_type: string
  ticket_status: string
  contractor: string
  address?: AddressValue
  tenant: string
  ticket_text: string
}

type Sorter = {
  sort: "id_asc" | "id_desc" | "created_at_asc" | "created_at_desc"
}

const FILTER_DEFAULT: Filter = {
  ticket_id: "",
  created_at_from: null,
  created_at_till: null,
  finished_at_from: null,
  finished_at_till: null,
  ticket_type: "",
  ticket_status: "",
  contractor: "",
  address: undefined,
  tenant: "",
  ticket_text: "",
}

const SORTER_DEFAULT: Sorter = {
  sort: "created_at_desc",
}

const PAGE_SIZE = 20

// eslint-disable-next-line complexity
export const Tickets: React.FC = () => {
  const [filter, setFilter] = React.useState<Filter>(FILTER_DEFAULT)
  const [sorter, setSorter] = React.useState<Sorter>(SORTER_DEFAULT)
  const [page, setPage] = React.useState(0)

  const setFilterValue = <T extends keyof Filter>(
    key: T,
    value: Filter[T],
  ): void => {
    setFilter((prev) => ({ ...prev, [key]: value }))
    setPage(0)
  }

  const [isExport, setExport] = React.useState(false)

  const params = {
    take: 10_000,
    from: 0,
    id: filter.ticket_id,
    created_at_from: filter.created_at_from
      ? format(filter.created_at_from, "yyyy-MM-dd")
      : "",
    created_at_till: filter.created_at_till
      ? format(filter.created_at_till, "yyyy-MM-dd")
      : "",
    finished_at_from: filter.finished_at_from
      ? format(filter.finished_at_from, "yyyy-MM-dd")
      : "",
    finished_at_till: filter.finished_at_till
      ? format(filter.finished_at_till, "yyyy-MM-dd")
      : "",
    type: filter.ticket_type,
    status: filter.ticket_status,
    address_complex: filter.address?.complex?.id ?? "",
    address_house: filter.address?.house?.id ?? "",
    address_apartment: filter.address?.apartment?.id ?? "",
    contractor: filter.contractor,
    tenant: filter.tenant,
    text: filter.ticket_text,
    sort: sorter.sort,
  }

  const [loading, allTickets] = useGetTickets(params)
  const [, ticketTypes] = useGetTicketTypes()
  const [, ticketStatuses] = useGetTicketStatuses()

  const sortById = ["id_asc", "id_desc"].includes(sorter.sort)
  const sortByCreatedAt = ["created_at_asc", "created_at_desc"].includes(
    sorter.sort,
  )

  let sortIconById: "sort-asc" | "sort-desc" = "sort-asc"
  if (sorter.sort === "id_asc") {
    sortIconById = "sort-asc"
  }
  if (sorter.sort === "id_desc") {
    sortIconById = "sort-desc"
  }

  let sortIconByCreatedAt: "sort-asc" | "sort-desc" = "sort-desc"
  if (sorter.sort === "created_at_asc") {
    sortIconByCreatedAt = "sort-asc"
  }
  if (sorter.sort === "created_at_desc") {
    sortIconByCreatedAt = "sort-desc"
  }

  const sortBy = (by: "id" | "created_at"): void => {
    let sort: typeof sorter.sort = sorter.sort

    if (by === "id") {
      sort = sorter.sort === "id_asc" ? "id_desc" : "id_asc"
    }
    if (by === "created_at") {
      sort =
        sorter.sort === "created_at_desc" ? "created_at_asc" : "created_at_desc"
    }

    setSorter({ sort })
  }

  const isEmptyFilter =
    JSON.stringify(filter) === JSON.stringify(FILTER_DEFAULT)
  const hasTickets = allTickets && allTickets.length > 0

  const pagedTickets = allTickets?.slice(
    page * PAGE_SIZE,
    (page + 1) * PAGE_SIZE,
  )

  return (
    <div className="container-fluid">
      <PageHeader>Заявки</PageHeader>

      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
        }}
      >
        <Button
          disabled={loading || isEmptyFilter || !hasTickets || isExport}
          icon="export"
          intent="success"
          onClick={() => {
            setExport(true)
            const query = new URLSearchParams({
              ...params,
              export: 1,
            } as never)
            api
              .get(`/tickets?${query}`, { responseType: "blob" })
              .then(({ data }) => {
                saveAs(data, `${format(new Date(), "yyyy-MM-dd--HH-mm")}.xlsx`)
              })
              .finally(() => setExport(false))
          }}
        >
          Выгрузть заявки в xls
        </Button>
        <Button
          disabled={loading || isEmptyFilter}
          intent="warning"
          minimal={true}
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            setFilter(FILTER_DEFAULT)
            setPage(0)
          }}
        >
          Очистить поиск
        </Button>
      </div>

      <div
        style={{
          padding: 0,
          borderRadius: 3,
          boxShadow:
            "0 0 0 1px rgb(16 22 26 / 15%), 0 0 0 rgb(16 22 26 / 0%), 0 0 0 rgb(16 22 26 / 0%)",
        }}
      >
        <HTMLTable
          bordered={true}
          condensed={true}
          style={{ width: "100%", minWidth: "1600px" }}
        >
          <thead
            className="valign-middle"
            style={{
              backgroundColor: "white",
            }}
          >
            <tr>
              <th style={{ width: "100px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <small>
                    Номер
                    <br />
                    заявки
                  </small>
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      active={sortById}
                      disabled={loading || isEmptyFilter}
                      icon={sortIconById}
                      minimal={true}
                      small={true}
                      onClick={() => sortBy("id")}
                    />
                  </div>
                </div>
              </th>
              <th style={{ width: "150px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <small>
                    Дата создания
                    <br />
                    заявки
                  </small>
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      active={sortByCreatedAt}
                      disabled={loading || isEmptyFilter}
                      icon={sortIconByCreatedAt}
                      minimal={true}
                      small={true}
                      style={{ marginLeft: "auto" }}
                      onClick={() => sortBy("created_at")}
                    />
                  </div>
                </div>
              </th>
              <th style={{ width: "150px" }}>
                <small>
                  Дата присвоения
                  <br />
                  &quot;Завершена&quot;
                </small>
              </th>
              <th style={{ width: "175px" }}>
                <small>Вид</small>
              </th>
              <th style={{ width: "125px" }}>
                <small>Статус</small>
              </th>
              <th style={{ width: "175px" }}>
                <small>Исполнитель</small>
              </th>
              <th style={{ width: "225px" }}>
                <small>Адрес</small>
              </th>
              <th style={{ width: "175px" }}>
                <small>ФИО жителя</small>
              </th>
              <th style={{ width: "auto" }}>
                <small>Текст заявки</small>
              </th>
              <th style={{ width: "95px" }}>
                <small>&nbsp;</small>
              </th>
            </tr>
          </thead>
          <thead
            className="valign-middle"
            style={{
              backgroundColor: "white",
              boxShadow: "rgb(0 0 0 / 25%) 0 2px 4px 0",
            }}
          >
            <tr className="toolbar">
              <th>
                <FilterInput
                  disabled={loading}
                  placeholder="Номер"
                  value={filter.ticket_id}
                  onApply={(value) => {
                    setFilterValue("ticket_id", value)
                  }}
                />
              </th>
              <th>
                <FilterDateRange
                  disabled={loading}
                  placeholder="Выбрать период"
                  value={[filter.created_at_from, filter.created_at_till]}
                  onApply={([from, till]) => {
                    setFilterValue("created_at_from", from)
                    setFilterValue("created_at_till", till)
                    if (from === null && till !== null) {
                      setFilterValue("created_at_from", till)
                    }
                    if (till === null && from !== null) {
                      setFilterValue("created_at_till", from)
                    }
                  }}
                />
              </th>
              <th>&nbsp;</th>
              <th>
                <FilterSelect
                  disabled={loading}
                  placeholder={"Все"}
                  value={filter.ticket_type}
                  values={(ticketTypes ?? []).map((ticketType) => ({
                    value: `${ticketType.id}`,
                    label: ticketType.name,
                  }))}
                  onChange={(value) => {
                    setFilterValue("ticket_type", value)
                  }}
                />
              </th>
              <th>
                <FilterSelect
                  disabled={loading}
                  placeholder={"Все"}
                  value={filter.ticket_status}
                  values={(ticketStatuses ?? []).map((ticketType) => ({
                    value: ticketType.value,
                    label: ticketType.title,
                  }))}
                  onChange={(value) => {
                    setFilterValue("ticket_status", value)
                  }}
                />
              </th>
              <th>
                <FilterInput
                  disabled={loading}
                  placeholder="Искать по исполнителю"
                  value={filter.contractor}
                  onApply={(value) => {
                    setFilterValue("contractor", value)
                  }}
                />
              </th>
              <th>
                <FilterAddress
                  disabled={loading}
                  placeholder="Искать по адресу"
                  value={filter.address}
                  onApply={(value) => {
                    setFilterValue("address", value)
                  }}
                />
              </th>
              <th>
                <FilterInput
                  disabled={loading}
                  placeholder="Искать по ФИО"
                  value={filter.tenant}
                  onApply={(value) => {
                    setFilterValue("tenant", value)
                  }}
                />
              </th>
              <th>
                <FilterInput
                  disabled={loading}
                  placeholder="Искать по словам"
                  value={filter.ticket_text}
                  onApply={(value) => {
                    setFilterValue("ticket_text", value)
                  }}
                />
              </th>
              <th />
            </tr>
          </thead>
          {isEmptyFilter && (
            <tbody className="valign-middle" style={{ height: "240px" }}>
              <tr>
                <td colSpan={10} style={{ textAlign: "center", opacity: 0.5 }}>
                  Укажите параметры поиска
                </td>
              </tr>
            </tbody>
          )}
          {!isEmptyFilter && loading && (
            <tbody className="valign-middle" style={{ height: "240px" }}>
              <tr>
                <td colSpan={10} style={{ textAlign: "center", opacity: 0.5 }}>
                  Загрузка...
                </td>
              </tr>
            </tbody>
          )}
          {!isEmptyFilter && !loading && !hasTickets && (
            <tbody className="valign-middle" style={{ height: "240px" }}>
              <tr>
                <td colSpan={10} style={{ textAlign: "center", opacity: 0.5 }}>
                  Нет данных
                </td>
              </tr>
            </tbody>
          )}
          {!isEmptyFilter && (
            <tbody className="valign-middle" style={{ minHeight: "320px" }}>
              {pagedTickets?.map((ticket) => (
                <tr key={ticket.id}>
                  <td>{ticket.id}</td>
                  <td>{format(parseISO(ticket.created_at), "dd.MM.yyyy")}</td>
                  <td>
                    {ticket.finished_at
                      ? format(parseISO(ticket.finished_at), "dd.MM.yyyy")
                      : ""}
                  </td>
                  <td>{ticket.type}</td>
                  <td>{ticket.status}</td>
                  <td>{ticket.contractor}</td>
                  <td>{ticket.address}</td>
                  <td>{ticket.tenant}</td>
                  <td>{ticket.text}</td>
                  <td>
                    <a
                      href={`/tickets/${ticket.id}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Переписка
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </HTMLTable>
      </div>
      {!isEmptyFilter && hasTickets && (
        <Pagination
          current={page}
          pageSize={PAGE_SIZE}
          total={allTickets?.length ?? 0}
          onClick={setPage}
        />
      )}
    </div>
  )
}
