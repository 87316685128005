import { ProgressBar } from "@blueprintjs/core"
import { useFormikContext } from "formik"
import { useGetUsersByPhone } from "lib/api/data"
import * as React from "react"

type CheckPhoneRoleProps = {
  phone: string
  role: "tenant" | "employee"
  id?: number
}

export const CheckPhoneRole: React.FC<CheckPhoneRoleProps> = ({
  phone,
  id,
}) => {
  const phoneInputValid = phone.length === 11
  const [loading, user] = useGetUsersByPhone(phoneInputValid ? phone : null)

  const { setStatus } = useFormikContext()

  const noUser = !user?.id
  const isOwnPhone = id === user?.id

  const hasTenantRole = user?.roles.some((r) => {
    return r.name === "tenant"
  })
  const hasEmployeeRole = user?.roles.some((r) => {
    return r.name === "manager" || r.name === "contractor"
  })

  const isValid =
    noUser ||
    isOwnPhone ||
    (hasTenantRole === false && hasEmployeeRole === false)

  React.useEffect(() => {
    setStatus(undefined)
    if (phoneInputValid && isValid === false) {
      setStatus("Этот номер телефона уже используется")
    }
  }, [setStatus, phoneInputValid, isValid])

  if (!phoneInputValid) {
    return null
  }

  if (loading) {
    return (
      <div style={{ marginTop: "-10px" }}>
        <ProgressBar />
      </div>
    )
  }

  return null
}
